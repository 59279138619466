@charset "UTF-8";
.mdc-banner__graphic {
  color: #fff;
  /* @alternate */
  color: var(--mdc-theme-surface, #fff);
}

.mdc-banner__graphic {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #6200ee);
}

.mdc-banner__content,
.mdc-banner__fixed {
  min-width: 344px;
}

@media (max-width: 480px), (max-width: 344px) {
  .mdc-banner__content,
  .mdc-banner__fixed {
    min-width: 100%;
  }
}
.mdc-banner__content {
  max-width: 720px;
}

.mdc-banner {
  z-index: 1;
  border-bottom-style: solid;
  box-sizing: border-box;
  display: none;
  flex-shrink: 0;
  height: 0;
  position: relative;
  width: 100%;
}

@media (max-width: 480px) {
  .mdc-banner .mdc-banner__fixed {
    left: 0;
    right: 0;
  }
  .mdc-banner .mdc-banner__text {
    /* @noflip */ /*rtl:ignore*/
    margin-left: 16px;
    /* @noflip */ /*rtl:ignore*/
    margin-right: 36px;
  }
  [dir=rtl] .mdc-banner .mdc-banner__text, .mdc-banner .mdc-banner__text[dir=rtl] {
    /*rtl:begin:ignore*/
    /* @noflip */ /*rtl:ignore*/
    margin-left: 36px;
    /* @noflip */ /*rtl:ignore*/
    margin-right: 16px;
    /*rtl:end:ignore*/
  }
}
@media (max-width: 480px) {
  .mdc-banner.mdc-banner--mobile-stacked .mdc-banner__content {
    flex-wrap: wrap;
  }
  .mdc-banner.mdc-banner--mobile-stacked .mdc-banner__graphic {
    margin-bottom: 12px;
  }
  .mdc-banner.mdc-banner--mobile-stacked .mdc-banner__text {
    /* @noflip */ /*rtl:ignore*/
    margin-left: 16px;
    /* @noflip */ /*rtl:ignore*/
    margin-right: 8px;
    padding-bottom: 4px;
  }
  [dir=rtl] .mdc-banner.mdc-banner--mobile-stacked .mdc-banner__text, .mdc-banner.mdc-banner--mobile-stacked .mdc-banner__text[dir=rtl] {
    /*rtl:begin:ignore*/
    /* @noflip */ /*rtl:ignore*/
    margin-left: 8px;
    /* @noflip */ /*rtl:ignore*/
    margin-right: 16px;
    /*rtl:end:ignore*/
  }
  .mdc-banner.mdc-banner--mobile-stacked .mdc-banner__actions {
    margin-left: auto;
  }
}
.mdc-banner--opening,
.mdc-banner--open,
.mdc-banner--closing {
  display: flex;
}

.mdc-banner--open {
  transition: height 300ms ease;
}

.mdc-banner--open .mdc-banner__content {
  transition: transform 300ms ease;
  transform: translateY(0);
}

.mdc-banner--closing {
  transition: height 250ms ease;
}

.mdc-banner--closing .mdc-banner__content {
  transition: transform 250ms ease;
}

.mdc-banner--centered .mdc-banner__content {
  left: 0;
  margin-left: auto;
  margin-right: auto;
  right: 0;
}

.mdc-banner__fixed {
  border-bottom-style: solid;
  box-sizing: border-box;
  height: inherit;
  position: fixed;
  width: 100%;
}

.mdc-banner__content {
  display: flex;
  min-height: 52px;
  position: absolute;
  transform: translateY(-100%);
  width: 100%;
}

.mdc-banner__graphic-text-wrapper {
  display: flex;
  width: 100%;
}

.mdc-banner__graphic {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  flex-shrink: 0;
  margin-top: 16px;
  margin-bottom: 16px;
  text-align: center;
}

[dir=rtl] .mdc-banner__graphic, .mdc-banner__graphic[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-banner__icon {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.mdc-banner__text {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 24px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 90px;
  align-self: center;
  flex-grow: 1;
  padding-top: 16px;
  padding-bottom: 16px;
}

[dir=rtl] .mdc-banner__text, .mdc-banner__text[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 90px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 24px;
  /*rtl:end:ignore*/
}

.mdc-banner__actions {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 8px;
  align-self: flex-end;
  display: flex;
  flex-shrink: 0;
  padding-bottom: 8px;
  padding-top: 8px;
}

[dir=rtl] .mdc-banner__actions, .mdc-banner__actions[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-banner__secondary-action {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 8px;
}

[dir=rtl] .mdc-banner__secondary-action, .mdc-banner__secondary-action[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-banner {
  background-color: #fff;
  border-bottom-color: rgba(0, 0, 0, 0.12);
  border-bottom-width: 1px;
  border-radius: 0;
  /* @alternate */
}

.mdc-banner .mdc-banner__text {
  color: #000;
}

.mdc-banner .mdc-banner__text {
  letter-spacing: 0.0178571429em;
  font-size: 0.875rem;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  line-height: 1.25rem;
}

.mdc-banner .mdc-banner__graphic {
  border-radius: 50%;
}

.mdc-banner .mdc-banner__graphic {
  height: 40px;
  width: 40px;
}

.mdc-banner .mdc-banner__fixed {
  background-color: #fff;
}

.mdc-banner .mdc-banner__fixed {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mdc-banner .mdc-banner__fixed {
  border-bottom-width: 1px;
}

.mdc-banner .mdc-button:not(:disabled) {
  color: #6200ee;
  /* @alternate */
  color: var(--mdc-text-button-label-text-color, #6200ee);
}

.mdc-banner .mdc-button .mdc-button__ripple::before,
.mdc-banner .mdc-button .mdc-button__ripple::after {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-text-button-hover-state-layer-color, #6200ee);
}

.mdc-banner .mdc-button:hover .mdc-button__ripple::before, .mdc-banner .mdc-button.mdc-ripple-surface--hover .mdc-button__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-text-button-hover-state-layer-opacity, 0.04);
}

.mdc-banner .mdc-button.mdc-ripple-upgraded--background-focused .mdc-button__ripple::before, .mdc-banner .mdc-button:not(.mdc-ripple-upgraded):focus .mdc-button__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-text-button-focus-state-layer-opacity, 0.12);
}

.mdc-banner .mdc-button:not(.mdc-ripple-upgraded) .mdc-button__ripple::after {
  transition: opacity 150ms linear;
}

.mdc-banner .mdc-button:not(.mdc-ripple-upgraded):active .mdc-button__ripple::after {
  transition-duration: 75ms;
  opacity: 0.1;
  /* @alternate */
  opacity: var(--mdc-text-button-pressed-state-layer-opacity, 0.1);
}

.mdc-banner .mdc-button.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-text-button-pressed-state-layer-opacity, 0.1);
}

.mdc-banner__secondary-action {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 8px;
}

[dir=rtl] .mdc-banner__secondary-action, .mdc-banner__secondary-action[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-touch-target-wrapper {
  display: inline;
}

.mdc-elevation-overlay {
  position: absolute;
  border-radius: inherit;
  pointer-events: none;
  opacity: 0;
  /* @alternate */
  opacity: var(--mdc-elevation-overlay-opacity, 0);
  transition: opacity 280ms cubic-bezier(0.4, 0, 0.2, 1);
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-elevation-overlay-color, #fff);
}

.mdc-button {
  /* @alternate */
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  min-width: 64px;
  border: none;
  outline: none;
  /* @alternate */
  line-height: inherit;
  user-select: none;
  -webkit-appearance: none;
  overflow: visible;
  vertical-align: middle;
  background: transparent;
}

.mdc-button .mdc-elevation-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
}

.mdc-button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mdc-button:active {
  outline: none;
}

.mdc-button:hover {
  cursor: pointer;
}

.mdc-button:disabled {
  cursor: default;
  pointer-events: none;
}

.mdc-button .mdc-button__icon { /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 8px;
  display: inline-block;
  position: relative;
  vertical-align: top;
}

[dir=rtl] .mdc-button .mdc-button__icon, .mdc-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-button .mdc-button__label {
  position: relative;
}

.mdc-button .mdc-button__focus-ring {
  display: none;
}

@media screen and (forced-colors: active) {
  .mdc-button.mdc-ripple-upgraded--background-focused .mdc-button__focus-ring, .mdc-button:not(.mdc-ripple-upgraded):focus .mdc-button__focus-ring {
    pointer-events: none;
    border: 2px solid transparent;
    border-radius: 6px;
    box-sizing: content-box;
    position: absolute;
    top: 50%;
    /* @noflip */ /*rtl:ignore*/
    left: 50%;
    /* @noflip */ /*rtl:ignore*/
    transform: translate(-50%, -50%);
    height: calc(
      100% + 4px
    );
    width: calc(
      100% + 4px
    );
    display: block;
  }
}
@media screen and (forced-colors: active) and (forced-colors: active) {
  .mdc-button.mdc-ripple-upgraded--background-focused .mdc-button__focus-ring, .mdc-button:not(.mdc-ripple-upgraded):focus .mdc-button__focus-ring {
    border-color: CanvasText;
  }
}
@media screen and (forced-colors: active) {
  .mdc-button.mdc-ripple-upgraded--background-focused .mdc-button__focus-ring::after, .mdc-button:not(.mdc-ripple-upgraded):focus .mdc-button__focus-ring::after {
    content: "";
    border: 2px solid transparent;
    border-radius: 8px;
    display: block;
    position: absolute;
    top: 50%;
    /* @noflip */ /*rtl:ignore*/
    left: 50%;
    /* @noflip */ /*rtl:ignore*/
    transform: translate(-50%, -50%);
    height: calc(100% + 4px);
    width: calc(100% + 4px);
  }
}
@media screen and (forced-colors: active) and (forced-colors: active) {
  .mdc-button.mdc-ripple-upgraded--background-focused .mdc-button__focus-ring::after, .mdc-button:not(.mdc-ripple-upgraded):focus .mdc-button__focus-ring::after {
    border-color: CanvasText;
  }
}
.mdc-button .mdc-button__touch {
  position: absolute;
  top: 50%;
  height: 48px;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.mdc-button__label + .mdc-button__icon {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
}

[dir=rtl] .mdc-button__label + .mdc-button__icon, .mdc-button__label + .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 8px;
  /*rtl:end:ignore*/
}

svg.mdc-button__icon {
  fill: currentColor;
}

.mdc-button--touch {
  margin-top: 6px;
  margin-bottom: 6px;
}

.mdc-button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  text-decoration: none;
  /* @alternate */
  text-decoration: var(--mdc-typography-button-text-decoration, none);
}

.mdc-button {
  padding: 0 8px 0 8px;
}

.mdc-button--unelevated {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  padding: 0 16px 0 16px;
}

.mdc-button--unelevated.mdc-button--icon-trailing {
  padding: 0 12px 0 16px;
}

.mdc-button--unelevated.mdc-button--icon-leading {
  padding: 0 16px 0 12px;
}

.mdc-button--raised {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  padding: 0 16px 0 16px;
}

.mdc-button--raised.mdc-button--icon-trailing {
  padding: 0 12px 0 16px;
}

.mdc-button--raised.mdc-button--icon-leading {
  padding: 0 16px 0 12px;
}

.mdc-button--outlined {
  border-style: solid;
  transition: border 280ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-button--outlined .mdc-button__ripple {
  border-style: solid;
  border-color: transparent;
}

@keyframes mdc-ripple-fg-radius-in {
  from {
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1);
  }
  to {
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
  }
}
@keyframes mdc-ripple-fg-opacity-in {
  from {
    animation-timing-function: linear;
    opacity: 0;
  }
  to {
    opacity: var(--mdc-ripple-fg-opacity, 0);
  }
}
@keyframes mdc-ripple-fg-opacity-out {
  from {
    animation-timing-function: linear;
    opacity: var(--mdc-ripple-fg-opacity, 0);
  }
  to {
    opacity: 0;
  }
}
.mdc-button {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}

.mdc-button .mdc-button__ripple::before,
.mdc-button .mdc-button__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}

.mdc-button .mdc-button__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}

.mdc-button .mdc-button__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}

.mdc-button.mdc-ripple-upgraded .mdc-button__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-button.mdc-ripple-upgraded .mdc-button__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}

.mdc-button.mdc-ripple-upgraded--unbounded .mdc-button__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}

.mdc-button.mdc-ripple-upgraded--foreground-activation .mdc-button__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}

.mdc-button.mdc-ripple-upgraded--foreground-deactivation .mdc-button__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-button .mdc-button__ripple::before,
.mdc-button .mdc-button__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}

.mdc-button.mdc-ripple-upgraded .mdc-button__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-button__ripple {
  position: absolute;
  box-sizing: content-box;
  overflow: hidden;
  z-index: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.mdc-button {
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-text-button-label-text-font, var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, sans-serif)));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-text-button-label-text-size, var(--mdc-typography-button-font-size, 0.875rem));
  letter-spacing: 0.0892857143em;
  /* @alternate */
  letter-spacing: var(--mdc-text-button-label-text-tracking, var(--mdc-typography-button-letter-spacing, 0.0892857143em));
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-text-button-label-text-weight, var(--mdc-typography-button-font-weight, 500));
  text-transform: uppercase;
  /* @alternate */
  text-transform: var(--mdc-text-button-label-text-transform, var(--mdc-typography-button-text-transform, uppercase));
  height: 36px;
  /* @alternate */
  height: var(--mdc-text-button-container-height, 36px);
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-text-button-container-shape, var(--mdc-shape-small, 4px));
}

.mdc-button:not(:disabled) {
  color: #6200ee;
  /* @alternate */
  color: var(--mdc-text-button-label-text-color, var(--mdc-theme-primary, #6200ee));
}

.mdc-button:disabled {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-text-button-disabled-label-text-color, rgba(0, 0, 0, 0.38));
}

.mdc-button .mdc-button__icon {
  font-size: 1.125rem;
  /* @alternate */
  font-size: var(--mdc-text-button-with-icon-icon-size, 1.125rem);
  width: 1.125rem;
  /* @alternate */
  width: var(--mdc-text-button-with-icon-icon-size, 1.125rem);
  height: 1.125rem;
  /* @alternate */
  height: var(--mdc-text-button-with-icon-icon-size, 1.125rem);
}

.mdc-button .mdc-button__ripple::before,
.mdc-button .mdc-button__ripple::after {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-text-button-hover-state-layer-color, var(--mdc-theme-primary, #6200ee));
}

.mdc-button:hover .mdc-button__ripple::before, .mdc-button.mdc-ripple-surface--hover .mdc-button__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-text-button-hover-state-layer-opacity, 0.04);
}

.mdc-button.mdc-ripple-upgraded--background-focused .mdc-button__ripple::before, .mdc-button:not(.mdc-ripple-upgraded):focus .mdc-button__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-text-button-focus-state-layer-opacity, 0.12);
}

.mdc-button:not(.mdc-ripple-upgraded) .mdc-button__ripple::after {
  transition: opacity 150ms linear;
}

.mdc-button:not(.mdc-ripple-upgraded):active .mdc-button__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-text-button-pressed-state-layer-opacity, 0.12);
}

.mdc-button.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-text-button-pressed-state-layer-opacity, 0.12);
}

.mdc-button .mdc-button__ripple {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-text-button-container-shape, var(--mdc-shape-small, 4px));
}

.mdc-button--unelevated {
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-filled-button-label-text-font, var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, sans-serif)));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-filled-button-label-text-size, var(--mdc-typography-button-font-size, 0.875rem));
  letter-spacing: 0.0892857143em;
  /* @alternate */
  letter-spacing: var(--mdc-filled-button-label-text-tracking, var(--mdc-typography-button-letter-spacing, 0.0892857143em));
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-filled-button-label-text-weight, var(--mdc-typography-button-font-weight, 500));
  text-transform: uppercase;
  /* @alternate */
  text-transform: var(--mdc-filled-button-label-text-transform, var(--mdc-typography-button-text-transform, uppercase));
  height: 36px;
  /* @alternate */
  height: var(--mdc-filled-button-container-height, 36px);
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-filled-button-container-shape, var(--mdc-shape-small, 4px));
}

.mdc-button--unelevated:not(:disabled) {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-filled-button-container-color, var(--mdc-theme-primary, #6200ee));
}

.mdc-button--unelevated:disabled {
  background-color: rgba(0, 0, 0, 0.12);
  /* @alternate */
  background-color: var(--mdc-filled-button-disabled-container-color, rgba(0, 0, 0, 0.12));
}

.mdc-button--unelevated:not(:disabled) {
  color: #fff;
  /* @alternate */
  color: var(--mdc-filled-button-label-text-color, var(--mdc-theme-on-primary, #fff));
}

.mdc-button--unelevated:disabled {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-filled-button-disabled-label-text-color, rgba(0, 0, 0, 0.38));
}

.mdc-button--unelevated .mdc-button__icon {
  font-size: 1.125rem;
  /* @alternate */
  font-size: var(--mdc-filled-button-with-icon-icon-size, 1.125rem);
  width: 1.125rem;
  /* @alternate */
  width: var(--mdc-filled-button-with-icon-icon-size, 1.125rem);
  height: 1.125rem;
  /* @alternate */
  height: var(--mdc-filled-button-with-icon-icon-size, 1.125rem);
}

.mdc-button--unelevated .mdc-button__ripple::before,
.mdc-button--unelevated .mdc-button__ripple::after {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-filled-button-hover-state-layer-color, var(--mdc-theme-on-primary, #fff));
}

.mdc-button--unelevated:hover .mdc-button__ripple::before, .mdc-button--unelevated.mdc-ripple-surface--hover .mdc-button__ripple::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-filled-button-hover-state-layer-opacity, 0.08);
}

.mdc-button--unelevated.mdc-ripple-upgraded--background-focused .mdc-button__ripple::before, .mdc-button--unelevated:not(.mdc-ripple-upgraded):focus .mdc-button__ripple::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-filled-button-focus-state-layer-opacity, 0.24);
}

.mdc-button--unelevated:not(.mdc-ripple-upgraded) .mdc-button__ripple::after {
  transition: opacity 150ms linear;
}

.mdc-button--unelevated:not(.mdc-ripple-upgraded):active .mdc-button__ripple::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-filled-button-pressed-state-layer-opacity, 0.24);
}

.mdc-button--unelevated.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-filled-button-pressed-state-layer-opacity, 0.24);
}

.mdc-button--unelevated .mdc-button__ripple {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-filled-button-container-shape, var(--mdc-shape-small, 4px));
}

.mdc-button--raised {
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-protected-button-label-text-font, var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, sans-serif)));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-protected-button-label-text-size, var(--mdc-typography-button-font-size, 0.875rem));
  letter-spacing: 0.0892857143em;
  /* @alternate */
  letter-spacing: var(--mdc-protected-button-label-text-tracking, var(--mdc-typography-button-letter-spacing, 0.0892857143em));
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-protected-button-label-text-weight, var(--mdc-typography-button-font-weight, 500));
  text-transform: uppercase;
  /* @alternate */
  text-transform: var(--mdc-protected-button-label-text-transform, var(--mdc-typography-button-text-transform, uppercase));
  height: 36px;
  /* @alternate */
  height: var(--mdc-protected-button-container-height, 36px);
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-protected-button-container-shape, var(--mdc-shape-small, 4px));
  --mdc-elevation-box-shadow-for-gss:0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  /* @alternate */
  box-shadow: var(--mdc-protected-button-container-elevation, var(--mdc-elevation-box-shadow-for-gss));
}

.mdc-button--raised:not(:disabled) {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-protected-button-container-color, var(--mdc-theme-primary, #6200ee));
}

.mdc-button--raised:disabled {
  background-color: rgba(0, 0, 0, 0.12);
  /* @alternate */
  background-color: var(--mdc-protected-button-disabled-container-color, rgba(0, 0, 0, 0.12));
}

.mdc-button--raised:not(:disabled) {
  color: #fff;
  /* @alternate */
  color: var(--mdc-protected-button-label-text-color, var(--mdc-theme-on-primary, #fff));
}

.mdc-button--raised:disabled {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-protected-button-disabled-label-text-color, rgba(0, 0, 0, 0.38));
}

.mdc-button--raised .mdc-button__icon {
  font-size: 1.125rem;
  /* @alternate */
  font-size: var(--mdc-protected-button-with-icon-icon-size, 1.125rem);
  width: 1.125rem;
  /* @alternate */
  width: var(--mdc-protected-button-with-icon-icon-size, 1.125rem);
  height: 1.125rem;
  /* @alternate */
  height: var(--mdc-protected-button-with-icon-icon-size, 1.125rem);
}

.mdc-button--raised .mdc-button__ripple::before,
.mdc-button--raised .mdc-button__ripple::after {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-protected-button-hover-state-layer-color, var(--mdc-theme-on-primary, #fff));
}

.mdc-button--raised:hover .mdc-button__ripple::before, .mdc-button--raised.mdc-ripple-surface--hover .mdc-button__ripple::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-protected-button-hover-state-layer-opacity, 0.08);
}

.mdc-button--raised.mdc-ripple-upgraded--background-focused .mdc-button__ripple::before, .mdc-button--raised:not(.mdc-ripple-upgraded):focus .mdc-button__ripple::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-protected-button-focus-state-layer-opacity, 0.24);
}

.mdc-button--raised:not(.mdc-ripple-upgraded) .mdc-button__ripple::after {
  transition: opacity 150ms linear;
}

.mdc-button--raised:not(.mdc-ripple-upgraded):active .mdc-button__ripple::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-protected-button-pressed-state-layer-opacity, 0.24);
}

.mdc-button--raised.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-protected-button-pressed-state-layer-opacity, 0.24);
}

.mdc-button--raised .mdc-button__ripple {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-protected-button-container-shape, var(--mdc-shape-small, 4px));
}

.mdc-button--raised.mdc-ripple-upgraded--background-focused, .mdc-button--raised:not(.mdc-ripple-upgraded):focus {
  --mdc-elevation-box-shadow-for-gss:0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  /* @alternate */
  box-shadow: var(--mdc-protected-button-focus-container-elevation, var(--mdc-elevation-box-shadow-for-gss));
}

.mdc-button--raised:hover {
  --mdc-elevation-box-shadow-for-gss:0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  /* @alternate */
  box-shadow: var(--mdc-protected-button-hover-container-elevation, var(--mdc-elevation-box-shadow-for-gss));
}

.mdc-button--raised:not(:disabled):active {
  --mdc-elevation-box-shadow-for-gss:0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  /* @alternate */
  box-shadow: var(--mdc-protected-button-pressed-container-elevation, var(--mdc-elevation-box-shadow-for-gss));
}

.mdc-button--raised:disabled {
  --mdc-elevation-box-shadow-for-gss:0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  /* @alternate */
  box-shadow: var(--mdc-protected-button-disabled-container-elevation, var(--mdc-elevation-box-shadow-for-gss));
}

.mdc-button--outlined {
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-outlined-button-label-text-font, var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, sans-serif)));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-outlined-button-label-text-size, var(--mdc-typography-button-font-size, 0.875rem));
  letter-spacing: 0.0892857143em;
  /* @alternate */
  letter-spacing: var(--mdc-outlined-button-label-text-tracking, var(--mdc-typography-button-letter-spacing, 0.0892857143em));
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-outlined-button-label-text-weight, var(--mdc-typography-button-font-weight, 500));
  text-transform: uppercase;
  /* @alternate */
  text-transform: var(--mdc-outlined-button-label-text-transform, var(--mdc-typography-button-text-transform, uppercase));
  height: 36px;
  /* @alternate */
  height: var(--mdc-outlined-button-container-height, 36px);
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-outlined-button-container-shape, var(--mdc-shape-small, 4px));
  padding: 0 15px 0 15px;
  border-width: 1px;
  /* @alternate */
  border-width: var(--mdc-outlined-button-outline-width, 1px);
}

.mdc-button--outlined:not(:disabled) {
  color: #6200ee;
  /* @alternate */
  color: var(--mdc-outlined-button-label-text-color, var(--mdc-theme-primary, #6200ee));
}

.mdc-button--outlined:disabled {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-outlined-button-disabled-label-text-color, rgba(0, 0, 0, 0.38));
}

.mdc-button--outlined .mdc-button__icon {
  font-size: 1.125rem;
  /* @alternate */
  font-size: var(--mdc-outlined-button-with-icon-icon-size, 1.125rem);
  width: 1.125rem;
  /* @alternate */
  width: var(--mdc-outlined-button-with-icon-icon-size, 1.125rem);
  height: 1.125rem;
  /* @alternate */
  height: var(--mdc-outlined-button-with-icon-icon-size, 1.125rem);
}

.mdc-button--outlined .mdc-button__ripple::before,
.mdc-button--outlined .mdc-button__ripple::after {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-outlined-button-hover-state-layer-color, var(--mdc-theme-primary, #6200ee));
}

.mdc-button--outlined:hover .mdc-button__ripple::before, .mdc-button--outlined.mdc-ripple-surface--hover .mdc-button__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-outlined-button-hover-state-layer-opacity, 0.04);
}

.mdc-button--outlined.mdc-ripple-upgraded--background-focused .mdc-button__ripple::before, .mdc-button--outlined:not(.mdc-ripple-upgraded):focus .mdc-button__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-outlined-button-focus-state-layer-opacity, 0.12);
}

.mdc-button--outlined:not(.mdc-ripple-upgraded) .mdc-button__ripple::after {
  transition: opacity 150ms linear;
}

.mdc-button--outlined:not(.mdc-ripple-upgraded):active .mdc-button__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-outlined-button-pressed-state-layer-opacity, 0.12);
}

.mdc-button--outlined.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-outlined-button-pressed-state-layer-opacity, 0.12);
}

.mdc-button--outlined .mdc-button__ripple {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-outlined-button-container-shape, var(--mdc-shape-small, 4px));
}

.mdc-button--outlined:not(:disabled) {
  border-color: rgba(0, 0, 0, 0.12);
  /* @alternate */
  border-color: var(--mdc-outlined-button-outline-color, rgba(0, 0, 0, 0.12));
}

.mdc-button--outlined:disabled {
  border-color: rgba(0, 0, 0, 0.12);
  /* @alternate */
  border-color: var(--mdc-outlined-button-disabled-outline-color, rgba(0, 0, 0, 0.12));
}

.mdc-button--outlined.mdc-button--icon-trailing {
  padding: 0 11px 0 15px;
}

.mdc-button--outlined.mdc-button--icon-leading {
  padding: 0 15px 0 11px;
}

.mdc-button--outlined .mdc-button__ripple {
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  border-width: 1px;
  /* @alternate */
  border-width: var(--mdc-outlined-button-outline-width, 1px);
}

.mdc-button--outlined .mdc-button__touch {
  left: calc(-1 * 1px);
  /* @alternate */
  left: calc(-1 * var(--mdc-outlined-button-outline-width, 1px));
  width: calc(100% + 2 * 1px);
  /* @alternate */
  width: calc(100% + 2 * var(--mdc-outlined-button-outline-width, 1px));
}

.mdc-button--raised .mdc-button__icon,
.mdc-button--unelevated .mdc-button__icon,
.mdc-button--outlined .mdc-button__icon {
  /* @noflip */ /*rtl:ignore*/
  margin-left: -4px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 8px;
}

[dir=rtl] .mdc-button--raised .mdc-button__icon, [dir=rtl] .mdc-button--unelevated .mdc-button__icon, [dir=rtl] .mdc-button--outlined .mdc-button__icon, .mdc-button--raised .mdc-button__icon[dir=rtl], .mdc-button--unelevated .mdc-button__icon[dir=rtl], .mdc-button--outlined .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: -4px;
  /*rtl:end:ignore*/
}

.mdc-button--raised .mdc-button__label + .mdc-button__icon,
.mdc-button--unelevated .mdc-button__label + .mdc-button__icon,
.mdc-button--outlined .mdc-button__label + .mdc-button__icon {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: -4px;
}

[dir=rtl] .mdc-button--raised .mdc-button__label + .mdc-button__icon, [dir=rtl] .mdc-button--unelevated .mdc-button__label + .mdc-button__icon, [dir=rtl] .mdc-button--outlined .mdc-button__label + .mdc-button__icon, .mdc-button--raised .mdc-button__label + .mdc-button__icon[dir=rtl], .mdc-button--unelevated .mdc-button__label + .mdc-button__icon[dir=rtl], .mdc-button--outlined .mdc-button__label + .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: -4px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 8px;
  /*rtl:end:ignore*/
}

.mdc-card {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-medium, 4px);
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
  /* @alternate */
  position: relative;
  /* @alternate */
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.mdc-card .mdc-elevation-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
}

.mdc-card::after {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-medium, 4px);
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 1px solid transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
  pointer-events: none;
}

@media screen and (forced-colors: active) {
  .mdc-card::after {
    border-color: CanvasText;
  }
}
.mdc-card--outlined {
  /* @alternate */
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  border-width: 1px;
  border-style: solid;
  border-color: #e0e0e0;
}

.mdc-card--outlined::after {
  border: none;
}

.mdc-card__content {
  border-radius: inherit;
  height: 100%;
}

.mdc-card__media {
  position: relative;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.mdc-card__media::before {
  display: block;
  content: "";
}

.mdc-card__media:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.mdc-card__media:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.mdc-card__media--square::before {
  margin-top: 100%;
}

.mdc-card__media--16-9::before {
  margin-top: 56.25%;
}

.mdc-card__media-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
}

.mdc-card__primary-action {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  outline: none;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  overflow: hidden;
}

.mdc-card__primary-action:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.mdc-card__primary-action:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.mdc-card__actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  min-height: 52px;
  padding: 8px;
}

.mdc-card__actions--full-bleed {
  padding: 0;
}

.mdc-card__action-buttons,
.mdc-card__action-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
}

.mdc-card__action-icons {
  color: rgba(0, 0, 0, 0.6);
  flex-grow: 1;
  justify-content: flex-end;
}

.mdc-card__action-buttons + .mdc-card__action-icons {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
}

[dir=rtl] .mdc-card__action-buttons + .mdc-card__action-icons, .mdc-card__action-buttons + .mdc-card__action-icons[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-card__action {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  cursor: pointer;
  user-select: none;
}

.mdc-card__action:focus {
  outline: none;
}

.mdc-card__action--button {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 8px;
  padding: 0 8px;
}

[dir=rtl] .mdc-card__action--button, .mdc-card__action--button[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-card__action--button:last-child {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
}

[dir=rtl] .mdc-card__action--button:last-child, .mdc-card__action--button:last-child[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-card__actions--full-bleed .mdc-card__action--button {
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-height: none;
  margin: 0;
  padding: 8px 16px;
  /* @noflip */ /*rtl:ignore*/
  text-align: left;
}

[dir=rtl] .mdc-card__actions--full-bleed .mdc-card__action--button, .mdc-card__actions--full-bleed .mdc-card__action--button[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  text-align: right;
  /*rtl:end:ignore*/
}

.mdc-card__action--icon {
  margin: -6px 0;
  padding: 12px;
}

.mdc-card__action--icon:not(:disabled) {
  color: rgba(0, 0, 0, 0.6);
}

.mdc-card__primary-action {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}

.mdc-card__primary-action .mdc-card__ripple::before,
.mdc-card__primary-action .mdc-card__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}

.mdc-card__primary-action .mdc-card__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}

.mdc-card__primary-action .mdc-card__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}

.mdc-card__primary-action.mdc-ripple-upgraded .mdc-card__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-card__primary-action.mdc-ripple-upgraded .mdc-card__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}

.mdc-card__primary-action.mdc-ripple-upgraded--unbounded .mdc-card__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}

.mdc-card__primary-action.mdc-ripple-upgraded--foreground-activation .mdc-card__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}

.mdc-card__primary-action.mdc-ripple-upgraded--foreground-deactivation .mdc-card__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-card__primary-action .mdc-card__ripple::before,
.mdc-card__primary-action .mdc-card__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}

.mdc-card__primary-action.mdc-ripple-upgraded .mdc-card__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-card__primary-action .mdc-card__ripple::before, .mdc-card__primary-action .mdc-card__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}

.mdc-card__primary-action:hover .mdc-card__ripple::before, .mdc-card__primary-action.mdc-ripple-surface--hover .mdc-card__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}

.mdc-card__primary-action.mdc-ripple-upgraded--background-focused .mdc-card__ripple::before, .mdc-card__primary-action:not(.mdc-ripple-upgraded):focus .mdc-card__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}

.mdc-card__primary-action:not(.mdc-ripple-upgraded) .mdc-card__ripple::after {
  transition: opacity 150ms linear;
}

.mdc-card__primary-action:not(.mdc-ripple-upgraded):active .mdc-card__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-card__primary-action.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-card__primary-action .mdc-card__ripple {
  box-sizing: content-box;
  height: 100%;
  overflow: hidden;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
}

.mdc-card__primary-action.mdc-ripple-upgraded--background-focused::after, .mdc-card__primary-action:not(.mdc-ripple-upgraded):focus::after {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 5px double transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}

@media screen and (forced-colors: active) {
  .mdc-card__primary-action.mdc-ripple-upgraded--background-focused::after, .mdc-card__primary-action:not(.mdc-ripple-upgraded):focus::after {
    border-color: CanvasText;
  }
}
.mdc-checkbox {
  padding: calc((40px - 18px) / 2);
  /* @alternate */
  padding: calc((var(--mdc-checkbox-ripple-size, 40px) - 18px) / 2);
  margin: calc((40px - 40px) / 2);
  /* @alternate */
  margin: calc((var(--mdc-checkbox-touch-target-size, 40px) - 40px) / 2);
}

.mdc-checkbox .mdc-checkbox__ripple::before, .mdc-checkbox .mdc-checkbox__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}

.mdc-checkbox:hover .mdc-checkbox__ripple::before, .mdc-checkbox.mdc-ripple-surface--hover .mdc-checkbox__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}

.mdc-checkbox.mdc-ripple-upgraded--background-focused .mdc-checkbox__ripple::before, .mdc-checkbox:not(.mdc-ripple-upgraded):focus .mdc-checkbox__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}

.mdc-checkbox:not(.mdc-ripple-upgraded) .mdc-checkbox__ripple::after {
  transition: opacity 150ms linear;
}

.mdc-checkbox:not(.mdc-ripple-upgraded):active .mdc-checkbox__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-checkbox.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-checkbox.mdc-checkbox--selected .mdc-checkbox__ripple::before, .mdc-checkbox.mdc-checkbox--selected .mdc-checkbox__ripple::after {
  background-color: #018786;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-secondary, #018786));
}

.mdc-checkbox.mdc-checkbox--selected:hover .mdc-checkbox__ripple::before, .mdc-checkbox.mdc-checkbox--selected.mdc-ripple-surface--hover .mdc-checkbox__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}

.mdc-checkbox.mdc-checkbox--selected.mdc-ripple-upgraded--background-focused .mdc-checkbox__ripple::before, .mdc-checkbox.mdc-checkbox--selected:not(.mdc-ripple-upgraded):focus .mdc-checkbox__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}

.mdc-checkbox.mdc-checkbox--selected:not(.mdc-ripple-upgraded) .mdc-checkbox__ripple::after {
  transition: opacity 150ms linear;
}

.mdc-checkbox.mdc-checkbox--selected:not(.mdc-ripple-upgraded):active .mdc-checkbox__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-checkbox.mdc-checkbox--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-checkbox.mdc-ripple-upgraded--background-focused.mdc-checkbox--selected .mdc-checkbox__ripple::before,
.mdc-checkbox.mdc-ripple-upgraded--background-focused.mdc-checkbox--selected .mdc-checkbox__ripple::after {
  background-color: #018786;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-secondary, #018786));
}

.mdc-checkbox .mdc-checkbox__background {
  top: calc((40px - 18px) / 2);
  /* @alternate */
  top: calc((var(--mdc-checkbox-ripple-size, 40px) - 18px) / 2);
  left: calc((40px - 18px) / 2);
  /* @alternate */
  left: calc((var(--mdc-checkbox-ripple-size, 40px) - 18px) / 2);
}

.mdc-checkbox .mdc-checkbox__native-control {
  top: calc((40px - 40px) / 2);
  /* @alternate */
  top: calc((40px - var(--mdc-checkbox-touch-target-size, 40px)) / 2);
  right: calc((40px - 40px) / 2);
  /* @alternate */
  right: calc((40px - var(--mdc-checkbox-touch-target-size, 40px)) / 2);
  left: calc((40px - 40px) / 2);
  /* @alternate */
  left: calc((40px - var(--mdc-checkbox-touch-target-size, 40px)) / 2);
  width: 40px;
  /* @alternate */
  width: var(--mdc-checkbox-touch-target-size, 40px);
  height: 40px;
  /* @alternate */
  height: var(--mdc-checkbox-touch-target-size, 40px);
}

.mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
  border-color: rgba(0, 0, 0, 0.54);
  /* @alternate */
  border-color: var(--mdc-checkbox-unchecked-color, rgba(0, 0, 0, 0.54));
  background-color: transparent;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled ~ .mdc-checkbox__background {
  border-color: #018786;
  /* @alternate */
  border-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #018786));
  background-color: #018786;
  /* @alternate */
  background-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #018786));
}

@keyframes mdc-checkbox-fade-in-background-8A000000FF01878600000000FF018786 {
  0% {
    border-color: rgba(0, 0, 0, 0.54);
    /* @alternate */
    border-color: var(--mdc-checkbox-unchecked-color, rgba(0, 0, 0, 0.54));
    background-color: transparent;
  }
  50% {
    border-color: #018786;
    /* @alternate */
    border-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #018786));
    background-color: #018786;
    /* @alternate */
    background-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #018786));
  }
}
@keyframes mdc-checkbox-fade-out-background-8A000000FF01878600000000FF018786 {
  0%, 80% {
    border-color: #018786;
    /* @alternate */
    border-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #018786));
    background-color: #018786;
    /* @alternate */
    background-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #018786));
  }
  100% {
    border-color: rgba(0, 0, 0, 0.54);
    /* @alternate */
    border-color: var(--mdc-checkbox-unchecked-color, rgba(0, 0, 0, 0.54));
    background-color: transparent;
  }
}
.mdc-checkbox.mdc-checkbox--anim-unchecked-checked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background, .mdc-checkbox.mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background {
  animation-name: mdc-checkbox-fade-in-background-8A000000FF01878600000000FF018786;
}

.mdc-checkbox.mdc-checkbox--anim-checked-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background, .mdc-checkbox.mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background {
  animation-name: mdc-checkbox-fade-out-background-8A000000FF01878600000000FF018786;
}

.mdc-checkbox .mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
  border-color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  border-color: var(--mdc-checkbox-disabled-color, rgba(0, 0, 0, 0.38));
  background-color: transparent;
}

.mdc-checkbox .mdc-checkbox__native-control[disabled]:checked ~ .mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control[disabled]:indeterminate ~ .mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true][disabled] ~ .mdc-checkbox__background {
  border-color: transparent;
  background-color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  background-color: var(--mdc-checkbox-disabled-color, rgba(0, 0, 0, 0.38));
}

.mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  color: #fff;
  /* @alternate */
  color: var(--mdc-checkbox-ink-color, #fff);
}

.mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  border-color: #fff;
  /* @alternate */
  border-color: var(--mdc-checkbox-ink-color, #fff);
}

.mdc-checkbox .mdc-checkbox__native-control:disabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  color: #fff;
  /* @alternate */
  color: var(--mdc-checkbox-ink-color, #fff);
}

.mdc-checkbox .mdc-checkbox__native-control:disabled ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  border-color: #fff;
  /* @alternate */
  border-color: var(--mdc-checkbox-ink-color, #fff);
}

@keyframes mdc-checkbox-unchecked-checked-checkmark-path {
  0%, 50% {
    stroke-dashoffset: 29.7833385;
  }
  50% {
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes mdc-checkbox-unchecked-indeterminate-mixedmark {
  0%, 68.2% {
    transform: scaleX(0);
  }
  68.2% {
    animation-timing-function: cubic-bezier(0, 0, 0, 1);
  }
  100% {
    transform: scaleX(1);
  }
}
@keyframes mdc-checkbox-checked-unchecked-checkmark-path {
  from {
    animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
    opacity: 1;
    stroke-dashoffset: 0;
  }
  to {
    opacity: 0;
    stroke-dashoffset: -29.7833385;
  }
}
@keyframes mdc-checkbox-checked-indeterminate-checkmark {
  from {
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    transform: rotate(0deg);
    opacity: 1;
  }
  to {
    transform: rotate(45deg);
    opacity: 0;
  }
}
@keyframes mdc-checkbox-indeterminate-checked-checkmark {
  from {
    animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
    transform: rotate(45deg);
    opacity: 0;
  }
  to {
    transform: rotate(360deg);
    opacity: 1;
  }
}
@keyframes mdc-checkbox-checked-indeterminate-mixedmark {
  from {
    animation-timing-function: mdc-animation-deceleration-curve-timing-function;
    transform: rotate(-45deg);
    opacity: 0;
  }
  to {
    transform: rotate(0deg);
    opacity: 1;
  }
}
@keyframes mdc-checkbox-indeterminate-checked-mixedmark {
  from {
    animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
    transform: rotate(0deg);
    opacity: 1;
  }
  to {
    transform: rotate(315deg);
    opacity: 0;
  }
}
@keyframes mdc-checkbox-indeterminate-unchecked-mixedmark {
  0% {
    animation-timing-function: linear;
    transform: scaleX(1);
    opacity: 1;
  }
  32.8%, 100% {
    transform: scaleX(0);
    opacity: 0;
  }
}
.mdc-checkbox {
  display: inline-block;
  position: relative;
  flex: 0 0 18px;
  box-sizing: content-box;
  width: 18px;
  height: 18px;
  line-height: 0;
  white-space: nowrap;
  cursor: pointer;
  vertical-align: bottom;
}

.mdc-checkbox.mdc-ripple-upgraded--background-focused .mdc-checkbox__focus-ring, .mdc-checkbox:not(.mdc-ripple-upgraded):focus .mdc-checkbox__focus-ring {
  pointer-events: none;
  border: 2px solid transparent;
  border-radius: 6px;
  box-sizing: content-box;
  position: absolute;
  top: 50%;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  /* @noflip */ /*rtl:ignore*/
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
}

@media screen and (forced-colors: active) {
  .mdc-checkbox.mdc-ripple-upgraded--background-focused .mdc-checkbox__focus-ring, .mdc-checkbox:not(.mdc-ripple-upgraded):focus .mdc-checkbox__focus-ring {
    border-color: CanvasText;
  }
}
.mdc-checkbox.mdc-ripple-upgraded--background-focused .mdc-checkbox__focus-ring::after, .mdc-checkbox:not(.mdc-ripple-upgraded):focus .mdc-checkbox__focus-ring::after {
  content: "";
  border: 2px solid transparent;
  border-radius: 8px;
  display: block;
  position: absolute;
  top: 50%;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  /* @noflip */ /*rtl:ignore*/
  transform: translate(-50%, -50%);
  height: calc(100% + 4px);
  width: calc(100% + 4px);
}

@media screen and (forced-colors: active) {
  .mdc-checkbox.mdc-ripple-upgraded--background-focused .mdc-checkbox__focus-ring::after, .mdc-checkbox:not(.mdc-ripple-upgraded):focus .mdc-checkbox__focus-ring::after {
    border-color: CanvasText;
  }
}
@media all and (-ms-high-contrast: none) {
  .mdc-checkbox .mdc-checkbox__focus-ring {
    display: none;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-checkbox__mixedmark {
    margin: 0 1px;
  }
}
.mdc-checkbox--disabled {
  cursor: default;
  pointer-events: none;
}

.mdc-checkbox__background {
  display: inline-flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  border: 2px solid currentColor;
  border-radius: 2px;
  background-color: transparent;
  pointer-events: none;
  will-change: background-color, border-color;
  transition: background-color 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), border-color 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}

.mdc-checkbox__checkmark {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  transition: opacity 180ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}

.mdc-checkbox--upgraded .mdc-checkbox__checkmark {
  opacity: 1;
}

.mdc-checkbox__checkmark-path {
  transition: stroke-dashoffset 180ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  stroke: currentColor;
  stroke-width: 3.12px;
  stroke-dashoffset: 29.7833385;
  stroke-dasharray: 29.7833385;
}

.mdc-checkbox__mixedmark {
  width: 100%;
  height: 0;
  transform: scaleX(0) rotate(0deg);
  border-width: 1px;
  border-style: solid;
  opacity: 0;
  transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}

.mdc-checkbox--anim-unchecked-checked .mdc-checkbox__background, .mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__background, .mdc-checkbox--anim-checked-unchecked .mdc-checkbox__background, .mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__background {
  animation-duration: 180ms;
  animation-timing-function: linear;
}

.mdc-checkbox--anim-unchecked-checked .mdc-checkbox__checkmark-path {
  animation: mdc-checkbox-unchecked-checked-checkmark-path 180ms linear 0s;
  transition: none;
}

.mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__mixedmark {
  animation: mdc-checkbox-unchecked-indeterminate-mixedmark 90ms linear 0s;
  transition: none;
}

.mdc-checkbox--anim-checked-unchecked .mdc-checkbox__checkmark-path {
  animation: mdc-checkbox-checked-unchecked-checkmark-path 90ms linear 0s;
  transition: none;
}

.mdc-checkbox--anim-checked-indeterminate .mdc-checkbox__checkmark {
  animation: mdc-checkbox-checked-indeterminate-checkmark 90ms linear 0s;
  transition: none;
}

.mdc-checkbox--anim-checked-indeterminate .mdc-checkbox__mixedmark {
  animation: mdc-checkbox-checked-indeterminate-mixedmark 90ms linear 0s;
  transition: none;
}

.mdc-checkbox--anim-indeterminate-checked .mdc-checkbox__checkmark {
  animation: mdc-checkbox-indeterminate-checked-checkmark 500ms linear 0s;
  transition: none;
}

.mdc-checkbox--anim-indeterminate-checked .mdc-checkbox__mixedmark {
  animation: mdc-checkbox-indeterminate-checked-mixedmark 500ms linear 0s;
  transition: none;
}

.mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__mixedmark {
  animation: mdc-checkbox-indeterminate-unchecked-mixedmark 300ms linear 0s;
  transition: none;
}

.mdc-checkbox__native-control:checked ~ .mdc-checkbox__background,
.mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background,
.mdc-checkbox__native-control[data-indeterminate=true] ~ .mdc-checkbox__background {
  transition: border-color 90ms 0ms cubic-bezier(0, 0, 0.2, 1), background-color 90ms 0ms cubic-bezier(0, 0, 0.2, 1);
}

.mdc-checkbox__native-control:checked ~ .mdc-checkbox__background .mdc-checkbox__checkmark-path,
.mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__checkmark-path,
.mdc-checkbox__native-control[data-indeterminate=true] ~ .mdc-checkbox__background .mdc-checkbox__checkmark-path {
  stroke-dashoffset: 0;
}

.mdc-checkbox__native-control {
  position: absolute;
  margin: 0;
  padding: 0;
  opacity: 0;
  cursor: inherit;
}

.mdc-checkbox__native-control:disabled {
  cursor: default;
  pointer-events: none;
}

.mdc-checkbox--touch {
  margin: calc((48px - 40px) / 2);
  /* @alternate */
  margin: calc((var(--mdc-checkbox-state-layer-size, 48px) - var(--mdc-checkbox-state-layer-size, 40px)) / 2);
}

.mdc-checkbox--touch .mdc-checkbox__native-control {
  top: calc((40px - 48px) / 2);
  /* @alternate */
  top: calc((var(--mdc-checkbox-state-layer-size, 40px) - var(--mdc-checkbox-state-layer-size, 48px)) / 2);
  right: calc((40px - 48px) / 2);
  /* @alternate */
  right: calc((var(--mdc-checkbox-state-layer-size, 40px) - var(--mdc-checkbox-state-layer-size, 48px)) / 2);
  left: calc((40px - 48px) / 2);
  /* @alternate */
  left: calc((var(--mdc-checkbox-state-layer-size, 40px) - var(--mdc-checkbox-state-layer-size, 48px)) / 2);
  width: 48px;
  /* @alternate */
  width: var(--mdc-checkbox-state-layer-size, 48px);
  height: 48px;
  /* @alternate */
  height: var(--mdc-checkbox-state-layer-size, 48px);
}

.mdc-checkbox__native-control:checked ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  transition: opacity 180ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 180ms 0ms cubic-bezier(0, 0, 0.2, 1);
  opacity: 1;
}

.mdc-checkbox__native-control:checked ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  transform: scaleX(1) rotate(-45deg);
}

.mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__checkmark,
.mdc-checkbox__native-control[data-indeterminate=true] ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  transform: rotate(45deg);
  opacity: 0;
  transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}

.mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__mixedmark,
.mdc-checkbox__native-control[data-indeterminate=true] ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  transform: scaleX(1) rotate(0deg);
  opacity: 1;
}

.mdc-checkbox.mdc-checkbox--upgraded .mdc-checkbox__background,
.mdc-checkbox.mdc-checkbox--upgraded .mdc-checkbox__checkmark,
.mdc-checkbox.mdc-checkbox--upgraded .mdc-checkbox__checkmark-path,
.mdc-checkbox.mdc-checkbox--upgraded .mdc-checkbox__mixedmark {
  transition: none;
}

.mdc-checkbox {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}

.mdc-checkbox .mdc-checkbox__ripple::before,
.mdc-checkbox .mdc-checkbox__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}

.mdc-checkbox .mdc-checkbox__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}

.mdc-checkbox .mdc-checkbox__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}

.mdc-checkbox.mdc-ripple-upgraded .mdc-checkbox__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-checkbox.mdc-ripple-upgraded .mdc-checkbox__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}

.mdc-checkbox.mdc-ripple-upgraded--unbounded .mdc-checkbox__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}

.mdc-checkbox.mdc-ripple-upgraded--foreground-activation .mdc-checkbox__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}

.mdc-checkbox.mdc-ripple-upgraded--foreground-deactivation .mdc-checkbox__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-checkbox .mdc-checkbox__ripple::before,
.mdc-checkbox .mdc-checkbox__ripple::after {
  top: calc(50% - 50%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 50%);
  width: 100%;
  height: 100%;
}

.mdc-checkbox.mdc-ripple-upgraded .mdc-checkbox__ripple::before,
.mdc-checkbox.mdc-ripple-upgraded .mdc-checkbox__ripple::after {
  top: var(--mdc-ripple-top, calc(50% - 50%));
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, calc(50% - 50%));
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-checkbox.mdc-ripple-upgraded .mdc-checkbox__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-checkbox {
  z-index: 0;
}

.mdc-checkbox .mdc-checkbox__ripple::before,
.mdc-checkbox .mdc-checkbox__ripple::after {
  z-index: -1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, -1);
}

.mdc-checkbox__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-checkbox .mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
    border-color: GrayText;
    /* @alternate */
    border-color: var(--mdc-checkbox-disabled-unselected-icon-color, GrayText);
    background-color: transparent;
  }
  .mdc-checkbox .mdc-checkbox__native-control[disabled]:checked ~ .mdc-checkbox__background,
  .mdc-checkbox .mdc-checkbox__native-control[disabled]:indeterminate ~ .mdc-checkbox__background,
  .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true][disabled] ~ .mdc-checkbox__background {
    border-color: GrayText;
    background-color: GrayText;
    /* @alternate */
    background-color: var(--mdc-checkbox-disabled-selected-icon-color, GrayText);
  }
  .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
    color: ButtonText;
    /* @alternate */
    color: var(--mdc-checkbox-selected-checkmark-color, ButtonText);
  }
  .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
    border-color: ButtonText;
    /* @alternate */
    border-color: var(--mdc-checkbox-selected-checkmark-color, ButtonText);
  }
  .mdc-checkbox .mdc-checkbox__native-control:disabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
    color: ButtonFace;
    /* @alternate */
    color: var(--mdc-checkbox-disabled-selected-checkmark-color, ButtonFace);
  }
  .mdc-checkbox .mdc-checkbox__native-control:disabled ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
    border-color: ButtonFace;
    /* @alternate */
    border-color: var(--mdc-checkbox-disabled-selected-checkmark-color, ButtonFace);
  }
}
.mdc-deprecated-chip-trailing-action__touch {
  position: absolute;
  top: 50%;
  height: 48px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 48px;
  transform: translate(-50%, -50%);
}

.mdc-deprecated-chip-trailing-action {
  border: none;
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
  background: none;
}

.mdc-deprecated-chip-trailing-action .mdc-deprecated-chip-trailing-action__icon {
  height: 18px;
  width: 18px;
  font-size: 18px;
}

.mdc-deprecated-chip-trailing-action .mdc-deprecated-chip-trailing-action {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-deprecated-chip-trailing-action .mdc-deprecated-chip-trailing-action__touch {
  width: 26px;
}

.mdc-deprecated-chip-trailing-action .mdc-deprecated-chip-trailing-action__icon {
  fill: currentColor;
  color: inherit;
}

.mdc-deprecated-chip-trailing-action {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}

.mdc-deprecated-chip-trailing-action .mdc-deprecated-chip-trailing-action__ripple::before,
.mdc-deprecated-chip-trailing-action .mdc-deprecated-chip-trailing-action__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}

.mdc-deprecated-chip-trailing-action .mdc-deprecated-chip-trailing-action__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}

.mdc-deprecated-chip-trailing-action .mdc-deprecated-chip-trailing-action__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}

.mdc-deprecated-chip-trailing-action.mdc-ripple-upgraded .mdc-deprecated-chip-trailing-action__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-deprecated-chip-trailing-action.mdc-ripple-upgraded .mdc-deprecated-chip-trailing-action__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}

.mdc-deprecated-chip-trailing-action.mdc-ripple-upgraded--unbounded .mdc-deprecated-chip-trailing-action__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}

.mdc-deprecated-chip-trailing-action.mdc-ripple-upgraded--foreground-activation .mdc-deprecated-chip-trailing-action__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}

.mdc-deprecated-chip-trailing-action.mdc-ripple-upgraded--foreground-deactivation .mdc-deprecated-chip-trailing-action__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-deprecated-chip-trailing-action .mdc-deprecated-chip-trailing-action__ripple::before,
.mdc-deprecated-chip-trailing-action .mdc-deprecated-chip-trailing-action__ripple::after {
  top: calc(50% - 50%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 50%);
  width: 100%;
  height: 100%;
}

.mdc-deprecated-chip-trailing-action.mdc-ripple-upgraded .mdc-deprecated-chip-trailing-action__ripple::before,
.mdc-deprecated-chip-trailing-action.mdc-ripple-upgraded .mdc-deprecated-chip-trailing-action__ripple::after {
  top: var(--mdc-ripple-top, calc(50% - 50%));
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, calc(50% - 50%));
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-deprecated-chip-trailing-action.mdc-ripple-upgraded .mdc-deprecated-chip-trailing-action__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-deprecated-chip-trailing-action .mdc-deprecated-chip-trailing-action__ripple::before, .mdc-deprecated-chip-trailing-action .mdc-deprecated-chip-trailing-action__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-on-surface, #000));
}

.mdc-deprecated-chip-trailing-action:hover .mdc-deprecated-chip-trailing-action__ripple::before, .mdc-deprecated-chip-trailing-action.mdc-ripple-surface--hover .mdc-deprecated-chip-trailing-action__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}

.mdc-deprecated-chip-trailing-action.mdc-ripple-upgraded--background-focused .mdc-deprecated-chip-trailing-action__ripple::before, .mdc-deprecated-chip-trailing-action:not(.mdc-ripple-upgraded):focus .mdc-deprecated-chip-trailing-action__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}

.mdc-deprecated-chip-trailing-action:not(.mdc-ripple-upgraded) .mdc-deprecated-chip-trailing-action__ripple::after {
  transition: opacity 150ms linear;
}

.mdc-deprecated-chip-trailing-action:not(.mdc-ripple-upgraded):active .mdc-deprecated-chip-trailing-action__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-deprecated-chip-trailing-action.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-deprecated-chip-trailing-action .mdc-deprecated-chip-trailing-action__ripple {
  position: absolute;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.mdc-chip__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}

.mdc-deprecated-chip-trailing-action {
  color: #000;
}

.mdc-chip__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}

.mdc-chip__icon--trailing:hover {
  color: rgba(0, 0, 0, 0.62);
}

.mdc-chip__icon--trailing:focus {
  color: rgba(0, 0, 0, 0.87);
}

.mdc-chip__icon.mdc-chip__icon--leading:not(.mdc-chip__icon--leading-hidden) {
  width: 20px;
  height: 20px;
  font-size: 20px;
}

.mdc-deprecated-chip-trailing-action__icon {
  height: 18px;
  width: 18px;
  font-size: 18px;
}

.mdc-chip__icon.mdc-chip__icon--trailing {
  width: 18px;
  height: 18px;
  font-size: 18px;
}

.mdc-deprecated-chip-trailing-action {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 4px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: -4px;
}

[dir=rtl] .mdc-deprecated-chip-trailing-action, .mdc-deprecated-chip-trailing-action[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: -4px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 4px;
  /*rtl:end:ignore*/
}

.mdc-chip__icon--trailing {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 4px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: -4px;
}

[dir=rtl] .mdc-chip__icon--trailing, .mdc-chip__icon--trailing[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: -4px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 4px;
  /*rtl:end:ignore*/
}

.mdc-chip {
  border-radius: 16px;
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  height: 32px;
  /* @alternate */
  position: relative;
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 12px;
  border-width: 0;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
}

.mdc-chip .mdc-chip__ripple {
  border-radius: 16px;
}

.mdc-chip:hover {
  color: rgba(0, 0, 0, 0.87);
}

.mdc-chip.mdc-chip--selected .mdc-chip__checkmark,
.mdc-chip .mdc-chip__icon--leading:not(.mdc-chip__icon--leading-hidden) {
  /* @noflip */ /*rtl:ignore*/
  margin-left: -4px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 4px;
}

[dir=rtl] .mdc-chip.mdc-chip--selected .mdc-chip__checkmark, [dir=rtl] .mdc-chip .mdc-chip__icon--leading:not(.mdc-chip__icon--leading-hidden), .mdc-chip.mdc-chip--selected .mdc-chip__checkmark[dir=rtl], .mdc-chip .mdc-chip__icon--leading:not(.mdc-chip__icon--leading-hidden)[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 4px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: -4px;
  /*rtl:end:ignore*/
}

.mdc-chip .mdc-elevation-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
}

.mdc-chip::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mdc-chip:hover {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-chip .mdc-chip__touch {
  position: absolute;
  top: 50%;
  height: 48px;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.mdc-chip--exit {
  transition: opacity 75ms cubic-bezier(0.4, 0, 0.2, 1), width 150ms cubic-bezier(0, 0, 0.2, 1), padding 100ms linear, margin 100ms linear;
  opacity: 0;
}

.mdc-chip__overflow {
  text-overflow: ellipsis;
  overflow: hidden;
}

.mdc-chip__text {
  white-space: nowrap;
}

.mdc-chip__icon {
  border-radius: 50%;
  outline: none;
  vertical-align: middle;
}

.mdc-chip__checkmark {
  height: 20px;
}

.mdc-chip__checkmark-path {
  transition: stroke-dashoffset 150ms 50ms cubic-bezier(0.4, 0, 0.6, 1);
  stroke-width: 2px;
  stroke-dashoffset: 29.7833385;
  stroke-dasharray: 29.7833385;
}

.mdc-chip__primary-action:focus {
  outline: none;
}

.mdc-chip--selected .mdc-chip__checkmark-path {
  stroke-dashoffset: 0;
}

.mdc-chip__icon--leading,
.mdc-chip__icon--trailing {
  position: relative;
}

.mdc-chip-set--choice .mdc-chip.mdc-chip--selected {
  color: #6200ee;
  /* @alternate */
  color: var(--mdc-theme-primary, #6200ee);
}

.mdc-chip-set--choice .mdc-chip.mdc-chip--selected .mdc-chip__icon--leading {
  color: rgba(98, 0, 238, 0.54);
}

.mdc-chip-set--choice .mdc-chip.mdc-chip--selected:hover {
  color: #6200ee;
  /* @alternate */
  color: var(--mdc-theme-primary, #6200ee);
}

.mdc-chip-set--choice .mdc-chip .mdc-chip__checkmark-path {
  stroke: #6200ee;
  /* @alternate */
  stroke: var(--mdc-theme-primary, #6200ee);
}

.mdc-chip-set--choice .mdc-chip--selected {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
}

.mdc-chip__checkmark-svg {
  width: 0;
  height: 20px;
  transition: width 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-chip--selected .mdc-chip__checkmark-svg {
  width: 20px;
}

.mdc-chip-set--filter .mdc-chip__icon--leading {
  transition: opacity 75ms linear;
  transition-delay: -50ms;
  opacity: 1;
}

.mdc-chip-set--filter .mdc-chip__icon--leading + .mdc-chip__checkmark {
  transition: opacity 75ms linear;
  transition-delay: 80ms;
  opacity: 0;
}

.mdc-chip-set--filter .mdc-chip__icon--leading + .mdc-chip__checkmark .mdc-chip__checkmark-svg {
  transition: width 0ms;
}

.mdc-chip-set--filter .mdc-chip--selected .mdc-chip__icon--leading {
  opacity: 0;
}

.mdc-chip-set--filter .mdc-chip--selected .mdc-chip__icon--leading + .mdc-chip__checkmark {
  width: 0;
  opacity: 1;
}

.mdc-chip-set--filter .mdc-chip__icon--leading-hidden.mdc-chip__icon--leading {
  width: 0;
  opacity: 0;
}

.mdc-chip-set--filter .mdc-chip__icon--leading-hidden.mdc-chip__icon--leading + .mdc-chip__checkmark {
  width: 20px;
}

.mdc-chip {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}

.mdc-chip .mdc-chip__ripple::before,
.mdc-chip .mdc-chip__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}

.mdc-chip .mdc-chip__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}

.mdc-chip .mdc-chip__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}

.mdc-chip.mdc-ripple-upgraded .mdc-chip__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-chip.mdc-ripple-upgraded .mdc-chip__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}

.mdc-chip.mdc-ripple-upgraded--unbounded .mdc-chip__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}

.mdc-chip.mdc-ripple-upgraded--foreground-activation .mdc-chip__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}

.mdc-chip.mdc-ripple-upgraded--foreground-deactivation .mdc-chip__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-chip .mdc-chip__ripple::before,
.mdc-chip .mdc-chip__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}

.mdc-chip.mdc-ripple-upgraded .mdc-chip__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-chip .mdc-chip__ripple::before, .mdc-chip .mdc-chip__ripple::after {
  background-color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.87));
}

.mdc-chip:hover .mdc-chip__ripple::before, .mdc-chip.mdc-ripple-surface--hover .mdc-chip__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}

.mdc-chip.mdc-ripple-upgraded--background-focused .mdc-chip__ripple::before, .mdc-chip.mdc-ripple-upgraded:focus-within .mdc-chip__ripple::before, .mdc-chip:not(.mdc-ripple-upgraded):focus .mdc-chip__ripple::before, .mdc-chip:not(.mdc-ripple-upgraded):focus-within .mdc-chip__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}

.mdc-chip:not(.mdc-ripple-upgraded) .mdc-chip__ripple::after {
  transition: opacity 150ms linear;
}

.mdc-chip:not(.mdc-ripple-upgraded):active .mdc-chip__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-chip.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-chip .mdc-chip__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
}

.mdc-chip-set--choice .mdc-chip.mdc-chip--selected .mdc-chip__ripple::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-ripple-selected-opacity, 0.08);
}

.mdc-chip-set--choice .mdc-chip.mdc-chip--selected .mdc-chip__ripple::before, .mdc-chip-set--choice .mdc-chip.mdc-chip--selected .mdc-chip__ripple::after {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #6200ee));
}

.mdc-chip-set--choice .mdc-chip.mdc-chip--selected:hover .mdc-chip__ripple::before, .mdc-chip-set--choice .mdc-chip.mdc-chip--selected.mdc-ripple-surface--hover .mdc-chip__ripple::before {
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.12);
}

.mdc-chip-set--choice .mdc-chip.mdc-chip--selected.mdc-ripple-upgraded--background-focused .mdc-chip__ripple::before, .mdc-chip-set--choice .mdc-chip.mdc-chip--selected.mdc-ripple-upgraded:focus-within .mdc-chip__ripple::before, .mdc-chip-set--choice .mdc-chip.mdc-chip--selected:not(.mdc-ripple-upgraded):focus .mdc-chip__ripple::before, .mdc-chip-set--choice .mdc-chip.mdc-chip--selected:not(.mdc-ripple-upgraded):focus-within .mdc-chip__ripple::before {
  transition-duration: 75ms;
  opacity: 0.2;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.2);
}

.mdc-chip-set--choice .mdc-chip.mdc-chip--selected:not(.mdc-ripple-upgraded) .mdc-chip__ripple::after {
  transition: opacity 150ms linear;
}

.mdc-chip-set--choice .mdc-chip.mdc-chip--selected:not(.mdc-ripple-upgraded):active .mdc-chip__ripple::after {
  transition-duration: 75ms;
  opacity: 0.2;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.2);
}

.mdc-chip-set--choice .mdc-chip.mdc-chip--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.2);
}

@keyframes mdc-chip-entry {
  from {
    transform: scale(0.8);
    opacity: 0.4;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
.mdc-chip-set {
  padding: 4px;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.mdc-chip-set .mdc-chip {
  margin: 4px;
}

.mdc-chip-set .mdc-chip--touch {
  margin-top: 8px;
  margin-bottom: 8px;
}

.mdc-chip-set--input .mdc-chip {
  animation: mdc-chip-entry 100ms cubic-bezier(0, 0, 0.2, 1);
}

.mdc-circular-progress__determinate-circle,
.mdc-circular-progress__indeterminate-circle-graphic {
  stroke: #6200ee;
  /* @alternate */
  stroke: var(--mdc-theme-primary, #6200ee);
}

.mdc-circular-progress__determinate-track {
  stroke: transparent;
}

@keyframes mdc-circular-progress-container-rotate {
  to {
    transform: rotate(360deg);
  }
}
@keyframes mdc-circular-progress-spinner-layer-rotate {
  12.5% {
    transform: rotate(135deg);
  }
  25% {
    transform: rotate(270deg);
  }
  37.5% {
    transform: rotate(405deg);
  }
  50% {
    transform: rotate(540deg);
  }
  62.5% {
    transform: rotate(675deg);
  }
  75% {
    transform: rotate(810deg);
  }
  87.5% {
    transform: rotate(945deg);
  }
  100% {
    transform: rotate(1080deg);
  }
}
@keyframes mdc-circular-progress-color-1-fade-in-out {
  from {
    opacity: 0.99;
  }
  25% {
    opacity: 0.99;
  }
  26% {
    opacity: 0;
  }
  89% {
    opacity: 0;
  }
  90% {
    opacity: 0.99;
  }
  to {
    opacity: 0.99;
  }
}
@keyframes mdc-circular-progress-color-2-fade-in-out {
  from {
    opacity: 0;
  }
  15% {
    opacity: 0;
  }
  25% {
    opacity: 0.99;
  }
  50% {
    opacity: 0.99;
  }
  51% {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}
@keyframes mdc-circular-progress-color-3-fade-in-out {
  from {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  50% {
    opacity: 0.99;
  }
  75% {
    opacity: 0.99;
  }
  76% {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}
@keyframes mdc-circular-progress-color-4-fade-in-out {
  from {
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  75% {
    opacity: 0.99;
  }
  90% {
    opacity: 0.99;
  }
  to {
    opacity: 0;
  }
}
@keyframes mdc-circular-progress-left-spin {
  from {
    transform: rotate(265deg);
  }
  50% {
    transform: rotate(130deg);
  }
  to {
    transform: rotate(265deg);
  }
}
@keyframes mdc-circular-progress-right-spin {
  from {
    transform: rotate(-265deg);
  }
  50% {
    transform: rotate(-130deg);
  }
  to {
    transform: rotate(-265deg);
  }
}
.mdc-circular-progress {
  display: inline-flex;
  position: relative;
  /* @noflip */ /*rtl:ignore*/
  direction: ltr;
  line-height: 0;
  transition: opacity 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}

.mdc-circular-progress__determinate-container,
.mdc-circular-progress__indeterminate-circle-graphic,
.mdc-circular-progress__indeterminate-container,
.mdc-circular-progress__spinner-layer {
  position: absolute;
  width: 100%;
  height: 100%;
}

.mdc-circular-progress__determinate-container {
  transform: rotate(-90deg);
}

.mdc-circular-progress__indeterminate-container {
  font-size: 0;
  letter-spacing: 0;
  white-space: nowrap;
  opacity: 0;
}

.mdc-circular-progress__determinate-circle-graphic,
.mdc-circular-progress__indeterminate-circle-graphic {
  fill: transparent;
}

.mdc-circular-progress__determinate-circle {
  transition: stroke-dashoffset 500ms 0ms cubic-bezier(0, 0, 0.2, 1);
}

.mdc-circular-progress__gap-patch {
  position: absolute;
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 47.5%;
  box-sizing: border-box;
  width: 5%;
  height: 100%;
  overflow: hidden;
}

.mdc-circular-progress__gap-patch .mdc-circular-progress__indeterminate-circle-graphic {
  /* @noflip */ /*rtl:ignore*/
  left: -900%;
  width: 2000%;
  transform: rotate(180deg);
}

.mdc-circular-progress__circle-clipper {
  display: inline-flex;
  position: relative;
  width: 50%;
  height: 100%;
  overflow: hidden;
}

.mdc-circular-progress__circle-clipper .mdc-circular-progress__indeterminate-circle-graphic {
  width: 200%;
}

.mdc-circular-progress__circle-right .mdc-circular-progress__indeterminate-circle-graphic {
  /* @noflip */ /*rtl:ignore*/
  left: -100%;
}

.mdc-circular-progress--indeterminate .mdc-circular-progress__determinate-container {
  opacity: 0;
}

.mdc-circular-progress--indeterminate .mdc-circular-progress__indeterminate-container {
  opacity: 1;
}

.mdc-circular-progress--indeterminate .mdc-circular-progress__indeterminate-container {
  animation: mdc-circular-progress-container-rotate 1568.2352941176ms linear infinite;
}

.mdc-circular-progress--indeterminate .mdc-circular-progress__spinner-layer {
  animation: mdc-circular-progress-spinner-layer-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.mdc-circular-progress--indeterminate .mdc-circular-progress__color-1 {
  animation: mdc-circular-progress-spinner-layer-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, mdc-circular-progress-color-1-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.mdc-circular-progress--indeterminate .mdc-circular-progress__color-2 {
  animation: mdc-circular-progress-spinner-layer-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, mdc-circular-progress-color-2-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.mdc-circular-progress--indeterminate .mdc-circular-progress__color-3 {
  animation: mdc-circular-progress-spinner-layer-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, mdc-circular-progress-color-3-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.mdc-circular-progress--indeterminate .mdc-circular-progress__color-4 {
  animation: mdc-circular-progress-spinner-layer-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, mdc-circular-progress-color-4-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.mdc-circular-progress--indeterminate .mdc-circular-progress__circle-left .mdc-circular-progress__indeterminate-circle-graphic {
  /* @noflip */ /*rtl:ignore*/
  animation: mdc-circular-progress-left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.mdc-circular-progress--indeterminate .mdc-circular-progress__circle-right .mdc-circular-progress__indeterminate-circle-graphic {
  /* @noflip */ /*rtl:ignore*/
  animation: mdc-circular-progress-right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.mdc-circular-progress--closed {
  opacity: 0;
}

.mdc-floating-label {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  position: absolute;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  /* @noflip */ /*rtl:ignore*/
  -webkit-transform-origin: left top;
  /* @noflip */ /*rtl:ignore*/
  transform-origin: left top;
  line-height: 1.15rem;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: text;
  overflow: hidden;
  /* @alternate */
  will-change: transform;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

[dir=rtl] .mdc-floating-label, .mdc-floating-label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  right: 0;
  /* @noflip */ /*rtl:ignore*/
  left: auto;
  /* @noflip */ /*rtl:ignore*/
  -webkit-transform-origin: right top;
  /* @noflip */ /*rtl:ignore*/
  transform-origin: right top;
  /* @noflip */ /*rtl:ignore*/
  text-align: right;
  /*rtl:end:ignore*/
}

.mdc-floating-label--float-above {
  cursor: auto;
}

.mdc-floating-label--required::after {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 1px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0px;
  content: "*";
}

[dir=rtl] .mdc-floating-label--required, .mdc-floating-label--required[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-floating-label--required::after, .mdc-floating-label--required[dir=rtl]::after {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 1px;
}

.mdc-floating-label--float-above {
  transform: translateY(-106%) scale(0.75);
}

.mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-standard 250ms 1;
}

@keyframes mdc-floating-label-shake-float-above-standard {
  0% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - 0%)) translateY(-106%) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(4% - 0%)) translateY(-106%) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(-4% - 0%)) translateY(-106%) scale(0.75);
  }
  100% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - 0%)) translateY(-106%) scale(0.75);
  }
}
.mdc-line-ripple::before, .mdc-line-ripple::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom-style: solid;
  content: "";
}

.mdc-line-ripple::before {
  border-bottom-width: 1px;
}

.mdc-line-ripple::before {
  z-index: 1;
}

.mdc-line-ripple::after {
  transform: scaleX(0);
  border-bottom-width: 2px;
  opacity: 0;
  z-index: 2;
}

.mdc-line-ripple::after {
  transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1), opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-line-ripple--active::after {
  transform: scaleX(1);
  opacity: 1;
}

.mdc-line-ripple--deactivating::after {
  opacity: 0;
}

.mdc-notched-outline {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  height: 100%;
  /* @noflip */ /*rtl:ignore*/
  text-align: left;
  pointer-events: none;
}

[dir=rtl] .mdc-notched-outline, .mdc-notched-outline[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  text-align: right;
  /*rtl:end:ignore*/
}

.mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing {
  box-sizing: border-box;
  height: 100%;
  border-top: 1px solid;
  border-bottom: 1px solid;
  pointer-events: none;
}

.mdc-notched-outline__leading {
  /* @noflip */ /*rtl:ignore*/
  border-left: 1px solid;
  /* @noflip */ /*rtl:ignore*/
  border-right: none;
  width: 12px;
}

[dir=rtl] .mdc-notched-outline__leading, .mdc-notched-outline__leading[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  border-left: none;
  /* @noflip */ /*rtl:ignore*/
  border-right: 1px solid;
  /*rtl:end:ignore*/
}

.mdc-notched-outline__trailing {
  /* @noflip */ /*rtl:ignore*/
  border-left: none;
  /* @noflip */ /*rtl:ignore*/
  border-right: 1px solid;
  flex-grow: 1;
}

[dir=rtl] .mdc-notched-outline__trailing, .mdc-notched-outline__trailing[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  border-left: 1px solid;
  /* @noflip */ /*rtl:ignore*/
  border-right: none;
  /*rtl:end:ignore*/
}

.mdc-notched-outline__notch {
  flex: 0 0 auto;
  width: auto;
  max-width: calc(100% - 12px * 2);
}

.mdc-notched-outline .mdc-floating-label {
  display: inline-block;
  position: relative;
  max-width: 100%;
}

.mdc-notched-outline .mdc-floating-label--float-above {
  text-overflow: clip;
}

.mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  max-width: 133.3333333333%;
}

.mdc-notched-outline--notched .mdc-notched-outline__notch {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 8px;
  border-top: none;
}

[dir=rtl] .mdc-notched-outline--notched .mdc-notched-outline__notch, .mdc-notched-outline--notched .mdc-notched-outline__notch[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-notched-outline--no-label .mdc-notched-outline__notch {
  display: none;
}

.mdc-select {
  display: inline-flex;
  position: relative;
}

.mdc-select:not(.mdc-select--disabled) .mdc-select__selected-text {
  color: rgba(0, 0, 0, 0.87);
}

.mdc-select.mdc-select--disabled .mdc-select__selected-text {
  color: rgba(0, 0, 0, 0.38);
}

.mdc-select:not(.mdc-select--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}

.mdc-select:not(.mdc-select--disabled).mdc-select--focused .mdc-floating-label {
  color: rgba(98, 0, 238, 0.87);
}

.mdc-select.mdc-select--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.38);
}

.mdc-select:not(.mdc-select--disabled) .mdc-select__dropdown-icon {
  fill: rgba(0, 0, 0, 0.54);
}

.mdc-select:not(.mdc-select--disabled).mdc-select--focused .mdc-select__dropdown-icon {
  fill: #6200ee;
  /* @alternate */
  fill: var(--mdc-theme-primary, #6200ee);
}

.mdc-select.mdc-select--disabled .mdc-select__dropdown-icon {
  fill: rgba(0, 0, 0, 0.38);
}

.mdc-select:not(.mdc-select--disabled) + .mdc-select-helper-text {
  color: rgba(0, 0, 0, 0.6);
}

.mdc-select.mdc-select--disabled + .mdc-select-helper-text {
  color: rgba(0, 0, 0, 0.38);
}

.mdc-select:not(.mdc-select--disabled) .mdc-select__icon {
  color: rgba(0, 0, 0, 0.54);
}

.mdc-select.mdc-select--disabled .mdc-select__icon {
  color: rgba(0, 0, 0, 0.38);
}

@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-select.mdc-select--disabled .mdc-select__selected-text {
    color: GrayText;
  }
  .mdc-select.mdc-select--disabled .mdc-select__dropdown-icon {
    fill: red;
  }
  .mdc-select.mdc-select--disabled .mdc-floating-label {
    color: GrayText;
  }
  .mdc-select.mdc-select--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
  .mdc-select.mdc-select--disabled .mdc-notched-outline__leading,
  .mdc-select.mdc-select--disabled .mdc-notched-outline__notch,
  .mdc-select.mdc-select--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
  .mdc-select.mdc-select--disabled .mdc-select__icon {
    color: GrayText;
  }
  .mdc-select.mdc-select--disabled + .mdc-select-helper-text {
    color: GrayText;
  }
}
.mdc-select .mdc-floating-label {
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.mdc-select .mdc-select__anchor {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}

[dir=rtl] .mdc-select .mdc-select__anchor, .mdc-select .mdc-select__anchor[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-select.mdc-select--with-leading-icon .mdc-select__anchor {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}

[dir=rtl] .mdc-select.mdc-select--with-leading-icon .mdc-select__anchor, .mdc-select.mdc-select--with-leading-icon .mdc-select__anchor[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-select .mdc-select__icon {
  width: 24px;
  height: 24px;
  font-size: 24px;
}

.mdc-select .mdc-select__dropdown-icon {
  width: 24px;
  height: 24px;
}

.mdc-select .mdc-select__menu .mdc-deprecated-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
}

[dir=rtl] .mdc-select .mdc-select__menu .mdc-deprecated-list-item, .mdc-select .mdc-select__menu .mdc-deprecated-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-select .mdc-select__menu .mdc-deprecated-list-item__graphic {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 12px;
}

[dir=rtl] .mdc-select .mdc-select__menu .mdc-deprecated-list-item__graphic, .mdc-select .mdc-select__menu .mdc-deprecated-list-item__graphic[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-select__dropdown-icon {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 12px;
  display: inline-flex;
  position: relative;
  align-self: center;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  pointer-events: none;
}

.mdc-select__dropdown-icon .mdc-select__dropdown-icon-active,
.mdc-select__dropdown-icon .mdc-select__dropdown-icon-inactive {
  position: absolute;
  top: 0;
  left: 0;
}

.mdc-select__dropdown-icon .mdc-select__dropdown-icon-graphic {
  width: 41.6666666667%;
  height: 20.8333333333%;
}

.mdc-select__dropdown-icon .mdc-select__dropdown-icon-inactive {
  opacity: 1;
  transition: opacity 75ms linear 75ms;
}

.mdc-select__dropdown-icon .mdc-select__dropdown-icon-active {
  opacity: 0;
  transition: opacity 75ms linear;
}

[dir=rtl] .mdc-select__dropdown-icon, .mdc-select__dropdown-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 12px;
  /*rtl:end:ignore*/
}

.mdc-select--activated .mdc-select__dropdown-icon .mdc-select__dropdown-icon-inactive {
  opacity: 0;
  transition: opacity 49.5ms linear;
}

.mdc-select--activated .mdc-select__dropdown-icon .mdc-select__dropdown-icon-active {
  opacity: 1;
  transition: opacity 100.5ms linear 49.5ms;
}

.mdc-select__anchor {
  width: 200px;
  min-width: 0;
  flex: 1 1 auto;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  outline: none;
  cursor: pointer;
}

.mdc-select__anchor .mdc-floating-label--float-above {
  transform: translateY(-106%) scale(0.75);
}

.mdc-select__selected-text-container {
  display: flex;
  appearance: none;
  pointer-events: none;
  box-sizing: border-box;
  width: auto;
  min-width: 0;
  flex-grow: 1;
  height: 28px;
  border: none;
  outline: none;
  padding: 0;
  background-color: transparent;
  color: inherit;
}

.mdc-select__selected-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  width: 100%;
  /* @noflip */ /*rtl:ignore*/
  text-align: left;
}

[dir=rtl] .mdc-select__selected-text, .mdc-select__selected-text[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  text-align: right;
  /*rtl:end:ignore*/
}

.mdc-select--invalid:not(.mdc-select--disabled) .mdc-floating-label {
  color: #b00020;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020);
}

.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--focused .mdc-floating-label {
  color: #b00020;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020);
}

.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--invalid + .mdc-select-helper-text--validation-msg {
  color: #b00020;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020);
}

.mdc-select--invalid:not(.mdc-select--disabled) .mdc-select__dropdown-icon {
  fill: #b00020;
  /* @alternate */
  fill: var(--mdc-theme-error, #b00020);
}

.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--focused .mdc-select__dropdown-icon {
  fill: #b00020;
  /* @alternate */
  fill: var(--mdc-theme-error, #b00020);
}

.mdc-select--disabled {
  cursor: default;
  pointer-events: none;
}

.mdc-select--with-leading-icon .mdc-select__menu .mdc-deprecated-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 12px;
}

[dir=rtl] .mdc-select--with-leading-icon .mdc-select__menu .mdc-deprecated-list-item, .mdc-select--with-leading-icon .mdc-select__menu .mdc-deprecated-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 12px;
  /*rtl:end:ignore*/
}

@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-select__menu::before {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: 1px solid transparent;
    border-radius: inherit;
    content: "";
    pointer-events: none;
  }
}
@media screen and (forced-colors: active) and (forced-colors: active), screen and (-ms-high-contrast: active) and (forced-colors: active) {
  .mdc-select__menu::before {
    border-color: CanvasText;
  }
}
.mdc-select__menu .mdc-deprecated-list .mdc-select__icon,
.mdc-select__menu .mdc-list .mdc-select__icon {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
}

[dir=rtl] .mdc-select__menu .mdc-deprecated-list .mdc-select__icon, [dir=rtl] .mdc-select__menu .mdc-list .mdc-select__icon, .mdc-select__menu .mdc-deprecated-list .mdc-select__icon[dir=rtl], .mdc-select__menu .mdc-list .mdc-select__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected,
.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--activated,
.mdc-select__menu .mdc-list .mdc-deprecated-list-item--selected,
.mdc-select__menu .mdc-list .mdc-deprecated-list-item--activated {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected .mdc-deprecated-list-item__graphic,
.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--activated .mdc-deprecated-list-item__graphic,
.mdc-select__menu .mdc-list .mdc-deprecated-list-item--selected .mdc-deprecated-list-item__graphic,
.mdc-select__menu .mdc-list .mdc-deprecated-list-item--activated .mdc-deprecated-list-item__graphic {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-select__menu .mdc-list-item__start {
  display: inline-flex;
  align-items: center;
}

.mdc-select__option {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
}

[dir=rtl] .mdc-select__option, .mdc-select__option[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-select__one-line-option.mdc-list-item--with-one-line {
  height: 48px;
}

.mdc-select__two-line-option.mdc-list-item--with-two-lines {
  height: 64px;
}

.mdc-select__two-line-option.mdc-list-item--with-two-lines .mdc-list-item__start {
  margin-top: 20px;
}

.mdc-select__two-line-option.mdc-list-item--with-two-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}

.mdc-select__two-line-option.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}

.mdc-select__two-line-option.mdc-list-item--with-two-lines .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-select__two-line-option.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}

.mdc-select__two-line-option.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 36px;
  content: "";
  vertical-align: 0;
}

.mdc-select__option-with-leading-content {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 12px;
}

.mdc-select__option-with-leading-content.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
}

[dir=rtl] .mdc-select__option-with-leading-content.mdc-list-item, .mdc-select__option-with-leading-content.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-select__option-with-leading-content .mdc-list-item__start {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
}

[dir=rtl] .mdc-select__option-with-leading-content .mdc-list-item__start, .mdc-select__option-with-leading-content .mdc-list-item__start[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 12px;
  /*rtl:end:ignore*/
}

.mdc-select__option-with-leading-content .mdc-list-item__start {
  width: 36px;
  height: 24px;
}

[dir=rtl] .mdc-select__option-with-leading-content, .mdc-select__option-with-leading-content[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-select__option-with-meta.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}

[dir=rtl] .mdc-select__option-with-meta.mdc-list-item, .mdc-select__option-with-meta.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
  /*rtl:end:ignore*/
}

.mdc-select__option-with-meta .mdc-list-item__end {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 12px;
}

[dir=rtl] .mdc-select__option-with-meta .mdc-list-item__end, .mdc-select__option-with-meta .mdc-list-item__end[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 12px;
  /*rtl:end:ignore*/
}

.mdc-select--filled .mdc-select__anchor {
  height: 56px;
  display: flex;
  align-items: baseline;
}

.mdc-select--filled .mdc-select__anchor::before {
  display: inline-block;
  width: 0;
  height: 40px;
  content: "";
  vertical-align: 0;
}

.mdc-select--filled.mdc-select--no-label .mdc-select__anchor .mdc-select__selected-text::before {
  content: "​";
}

.mdc-select--filled.mdc-select--no-label .mdc-select__anchor .mdc-select__selected-text-container {
  height: 100%;
  display: inline-flex;
  align-items: center;
}

.mdc-select--filled.mdc-select--no-label .mdc-select__anchor::before {
  display: none;
}

.mdc-select--filled .mdc-select__anchor {
  border-top-left-radius: 4px;
  /* @alternate */
  border-top-left-radius: var(--mdc-shape-small, 4px);
  border-top-right-radius: 4px;
  /* @alternate */
  border-top-right-radius: var(--mdc-shape-small, 4px);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.mdc-select--filled:not(.mdc-select--disabled) .mdc-select__anchor {
  background-color: whitesmoke;
}

.mdc-select--filled.mdc-select--disabled .mdc-select__anchor {
  background-color: #fafafa;
}

.mdc-select--filled:not(.mdc-select--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}

.mdc-select--filled:not(.mdc-select--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}

.mdc-select--filled:not(.mdc-select--disabled) .mdc-line-ripple::after {
  border-bottom-color: #6200ee;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-primary, #6200ee);
}

.mdc-select--filled.mdc-select--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.06);
}

.mdc-select--filled .mdc-floating-label {
  max-width: calc(100% - 64px);
}

.mdc-select--filled .mdc-floating-label--float-above {
  max-width: calc(100% / 0.75 - 64px / 0.75);
}

.mdc-select--filled .mdc-menu-surface--is-open-below {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.mdc-select--filled.mdc-select--focused.mdc-line-ripple::after {
  transform: scale(1, 2);
  opacity: 1;
}

.mdc-select--filled .mdc-floating-label {
  /* @noflip */ /*rtl:ignore*/
  left: 16px;
  /* @noflip */ /*rtl:ignore*/
  right: initial;
}

[dir=rtl] .mdc-select--filled .mdc-floating-label, .mdc-select--filled .mdc-floating-label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  left: initial;
  /* @noflip */ /*rtl:ignore*/
  right: 16px;
  /*rtl:end:ignore*/
}

.mdc-select--filled.mdc-select--with-leading-icon .mdc-floating-label {
  /* @noflip */ /*rtl:ignore*/
  left: 48px;
  /* @noflip */ /*rtl:ignore*/
  right: initial;
}

[dir=rtl] .mdc-select--filled.mdc-select--with-leading-icon .mdc-floating-label, .mdc-select--filled.mdc-select--with-leading-icon .mdc-floating-label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  left: initial;
  /* @noflip */ /*rtl:ignore*/
  right: 48px;
  /*rtl:end:ignore*/
}

.mdc-select--filled.mdc-select--with-leading-icon .mdc-floating-label {
  max-width: calc(100% - 96px);
}

.mdc-select--filled.mdc-select--with-leading-icon .mdc-floating-label--float-above {
  max-width: calc(100% / 0.75 - 96px / 0.75);
}

.mdc-select--invalid:not(.mdc-select--disabled) .mdc-line-ripple::before {
  border-bottom-color: #b00020;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #b00020);
}

.mdc-select--invalid:not(.mdc-select--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: #b00020;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #b00020);
}

.mdc-select--invalid:not(.mdc-select--disabled) .mdc-line-ripple::after {
  border-bottom-color: #b00020;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #b00020);
}

.mdc-select--outlined {
  border: none;
}

.mdc-select--outlined .mdc-select__anchor {
  height: 56px;
}

.mdc-select--outlined .mdc-select__anchor .mdc-floating-label--float-above {
  transform: translateY(-37.25px) scale(1);
}

.mdc-select--outlined .mdc-select__anchor .mdc-floating-label--float-above {
  font-size: 0.75rem;
}

.mdc-select--outlined .mdc-select__anchor.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-select--outlined .mdc-select__anchor .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-34.75px) scale(0.75);
}

.mdc-select--outlined .mdc-select__anchor.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-select--outlined .mdc-select__anchor .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}

.mdc-select--outlined .mdc-select__anchor .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-select-outlined-56px 250ms 1;
}

@keyframes mdc-floating-label-shake-float-above-select-outlined-56px {
  0% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - 0%)) translateY(-34.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(4% - 0%)) translateY(-34.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(-4% - 0%)) translateY(-34.75px) scale(0.75);
  }
  100% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - 0%)) translateY(-34.75px) scale(0.75);
  }
}
.mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__leading {
  /* @noflip */ /*rtl:ignore*/
  border-top-left-radius: 4px;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-top-left-radius: var(--mdc-shape-small, 4px);
  /* @noflip */ /*rtl:ignore*/
  border-top-right-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-bottom-right-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-bottom-left-radius: 4px;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-bottom-left-radius: var(--mdc-shape-small, 4px);
}

[dir=rtl] .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__leading, .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__leading[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  border-top-left-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-top-right-radius: 4px;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-top-right-radius: var(--mdc-shape-small, 4px);
  /* @noflip */ /*rtl:ignore*/
  border-bottom-right-radius: 4px;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-bottom-right-radius: var(--mdc-shape-small, 4px);
  /* @noflip */ /*rtl:ignore*/
  border-bottom-left-radius: 0;
  /*rtl:end:ignore*/
}

@supports (top: max(0%)) {
  .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__leading {
    width: max(12px, var(--mdc-shape-small, 4px));
  }
}
@supports (top: max(0%)) {
  .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__notch {
    max-width: calc(100% - max(12px, var(--mdc-shape-small, 4px)) * 2);
  }
}
.mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
  /* @noflip */ /*rtl:ignore*/
  border-top-left-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-top-right-radius: 4px;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-top-right-radius: var(--mdc-shape-small, 4px);
  /* @noflip */ /*rtl:ignore*/
  border-bottom-right-radius: 4px;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-bottom-right-radius: var(--mdc-shape-small, 4px);
  /* @noflip */ /*rtl:ignore*/
  border-bottom-left-radius: 0;
}

[dir=rtl] .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__trailing, .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__trailing[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  border-top-left-radius: 4px;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-top-left-radius: var(--mdc-shape-small, 4px);
  /* @noflip */ /*rtl:ignore*/
  border-top-right-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-bottom-right-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-bottom-left-radius: 4px;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-bottom-left-radius: var(--mdc-shape-small, 4px);
  /*rtl:end:ignore*/
}

@supports (top: max(0%)) {
  .mdc-select--outlined .mdc-select__anchor {
    /* @noflip */ /*rtl:ignore*/
    padding-left: max(16px, calc(var(--mdc-shape-small, 4px) + 4px));
  }
}
[dir=rtl] .mdc-select--outlined .mdc-select__anchor, .mdc-select--outlined .mdc-select__anchor[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /*rtl:end:ignore*/
}

@supports (top: max(0%)) {
  [dir=rtl] .mdc-select--outlined .mdc-select__anchor, .mdc-select--outlined .mdc-select__anchor[dir=rtl] {
    /* @noflip */ /*rtl:ignore*/
    padding-right: max(16px, calc(var(--mdc-shape-small, 4px) + 4px));
  }
}
@supports (top: max(0%)) {
  .mdc-select--outlined + .mdc-select-helper-text {
    /* @noflip */ /*rtl:ignore*/
    margin-left: max(16px, calc(var(--mdc-shape-small, 4px) + 4px));
  }
}
[dir=rtl] .mdc-select--outlined + .mdc-select-helper-text, .mdc-select--outlined + .mdc-select-helper-text[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /*rtl:end:ignore*/
}

@supports (top: max(0%)) {
  [dir=rtl] .mdc-select--outlined + .mdc-select-helper-text, .mdc-select--outlined + .mdc-select-helper-text[dir=rtl] {
    /* @noflip */ /*rtl:ignore*/
    margin-right: max(16px, calc(var(--mdc-shape-small, 4px) + 4px));
  }
}
.mdc-select--outlined:not(.mdc-select--disabled) .mdc-select__anchor {
  background-color: transparent;
}

.mdc-select--outlined.mdc-select--disabled .mdc-select__anchor {
  background-color: transparent;
}

.mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.38);
}

.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__anchor:hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__anchor:hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__anchor:hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.87);
}

.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__trailing {
  border-width: 2px;
}

.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: #6200ee;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #6200ee);
}

.mdc-select--outlined.mdc-select--disabled .mdc-notched-outline__leading,
.mdc-select--outlined.mdc-select--disabled .mdc-notched-outline__notch,
.mdc-select--outlined.mdc-select--disabled .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.06);
}

.mdc-select--outlined .mdc-select__anchor :not(.mdc-notched-outline--notched) .mdc-notched-outline__notch {
  max-width: calc(100% - 60px);
}

.mdc-select--outlined .mdc-select__anchor {
  display: flex;
  align-items: baseline;
  overflow: visible;
}

.mdc-select--outlined .mdc-select__anchor .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-select-outlined 250ms 1;
}

.mdc-select--outlined .mdc-select__anchor .mdc-floating-label--float-above {
  transform: translateY(-37.25px) scale(1);
}

.mdc-select--outlined .mdc-select__anchor .mdc-floating-label--float-above {
  font-size: 0.75rem;
}

.mdc-select--outlined .mdc-select__anchor.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-select--outlined .mdc-select__anchor .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-34.75px) scale(0.75);
}

.mdc-select--outlined .mdc-select__anchor.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-select--outlined .mdc-select__anchor .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}

.mdc-select--outlined .mdc-select__anchor .mdc-notched-outline--notched .mdc-notched-outline__notch {
  padding-top: 1px;
}

.mdc-select--outlined .mdc-select__anchor .mdc-select__selected-text::before {
  content: "​";
}

.mdc-select--outlined .mdc-select__anchor .mdc-select__selected-text-container {
  height: 100%;
  display: inline-flex;
  align-items: center;
}

.mdc-select--outlined .mdc-select__anchor::before {
  display: none;
}

.mdc-select--outlined .mdc-select__selected-text-container {
  display: flex;
  border: none;
  z-index: 1;
  background-color: transparent;
}

.mdc-select--outlined .mdc-select__icon {
  z-index: 2;
}

.mdc-select--outlined .mdc-floating-label {
  line-height: 1.15rem;
  /* @noflip */ /*rtl:ignore*/
  left: 4px;
  /* @noflip */ /*rtl:ignore*/
  right: initial;
}

[dir=rtl] .mdc-select--outlined .mdc-floating-label, .mdc-select--outlined .mdc-floating-label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  left: initial;
  /* @noflip */ /*rtl:ignore*/
  right: 4px;
  /*rtl:end:ignore*/
}

.mdc-select--outlined.mdc-select--focused .mdc-notched-outline--notched .mdc-notched-outline__notch {
  padding-top: 2px;
}

.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020);
}

.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__anchor:hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__anchor:hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__anchor:hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020);
}

.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__trailing {
  border-width: 2px;
}

.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020);
}

.mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label {
  /* @noflip */ /*rtl:ignore*/
  left: 36px;
  /* @noflip */ /*rtl:ignore*/
  right: initial;
}

[dir=rtl] .mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label, .mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  left: initial;
  /* @noflip */ /*rtl:ignore*/
  right: 36px;
  /*rtl:end:ignore*/
}

.mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label--float-above {
  /* @noflip */ /*rtl:ignore*/
  transform: translateY(-37.25px) translateX(-32px) scale(1);
}

[dir=rtl] .mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label--float-above, .mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label--float-above[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  transform: translateY(-37.25px) translateX(32px) scale(1);
  /*rtl:end:ignore*/
}

.mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label--float-above {
  font-size: 0.75rem;
}

.mdc-select--outlined.mdc-select--with-leading-icon.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-select--outlined.mdc-select--with-leading-icon .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  /* @noflip */ /*rtl:ignore*/
  transform: translateY(-34.75px) translateX(-32px) scale(0.75);
}

[dir=rtl] .mdc-select--outlined.mdc-select--with-leading-icon.mdc-notched-outline--upgraded .mdc-floating-label--float-above, [dir=rtl] .mdc-select--outlined.mdc-select--with-leading-icon .mdc-notched-outline--upgraded .mdc-floating-label--float-above, .mdc-select--outlined.mdc-select--with-leading-icon.mdc-notched-outline--upgraded .mdc-floating-label--float-above[dir=rtl], .mdc-select--outlined.mdc-select--with-leading-icon .mdc-notched-outline--upgraded .mdc-floating-label--float-above[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  transform: translateY(-34.75px) translateX(32px) scale(0.75);
  /*rtl:end:ignore*/
}

.mdc-select--outlined.mdc-select--with-leading-icon.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-select--outlined.mdc-select--with-leading-icon .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}

.mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-select-outlined-leading-icon-56px 250ms 1;
}

@keyframes mdc-floating-label-shake-float-above-select-outlined-leading-icon-56px {
  0% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - 32px)) translateY(-34.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(4% - 32px)) translateY(-34.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(-4% - 32px)) translateY(-34.75px) scale(0.75);
  }
  100% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - 32px)) translateY(-34.75px) scale(0.75);
  }
}
[dir=rtl] .mdc-select--outlined.mdc-select--with-leading-icon, .mdc-select--outlined.mdc-select--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label--shake, .mdc-select--outlined.mdc-select--with-leading-icon[dir=rtl] .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-select-outlined-leading-icon-56px 250ms 1;
}

@keyframes mdc-floating-label-shake-float-above-select-outlined-leading-icon-56px-rtl {
  0% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - -32px)) translateY(-34.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(4% - -32px)) translateY(-34.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(-4% - -32px)) translateY(-34.75px) scale(0.75);
  }
  100% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - -32px)) translateY(-34.75px) scale(0.75);
  }
}
.mdc-select--outlined.mdc-select--with-leading-icon .mdc-select__anchor :not(.mdc-notched-outline--notched) .mdc-notched-outline__notch {
  max-width: calc(100% - 96px);
}

.mdc-select--outlined .mdc-menu-surface {
  margin-bottom: 8px;
}

.mdc-select--outlined.mdc-select--no-label .mdc-menu-surface,
.mdc-select--outlined .mdc-menu-surface--is-open-below {
  margin-bottom: 0;
}

.mdc-select__anchor {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}

.mdc-select__anchor .mdc-select__ripple::before,
.mdc-select__anchor .mdc-select__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}

.mdc-select__anchor .mdc-select__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}

.mdc-select__anchor .mdc-select__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}

.mdc-select__anchor.mdc-ripple-upgraded .mdc-select__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-select__anchor.mdc-ripple-upgraded .mdc-select__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}

.mdc-select__anchor.mdc-ripple-upgraded--unbounded .mdc-select__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}

.mdc-select__anchor.mdc-ripple-upgraded--foreground-activation .mdc-select__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}

.mdc-select__anchor.mdc-ripple-upgraded--foreground-deactivation .mdc-select__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-select__anchor .mdc-select__ripple::before,
.mdc-select__anchor .mdc-select__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}

.mdc-select__anchor.mdc-ripple-upgraded .mdc-select__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-select__anchor .mdc-select__ripple::before,
.mdc-select__anchor .mdc-select__ripple::after {
  background-color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.87));
}

.mdc-select__anchor:hover .mdc-select__ripple::before, .mdc-select__anchor.mdc-ripple-surface--hover .mdc-select__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}

.mdc-select__anchor.mdc-ripple-upgraded--background-focused .mdc-select__ripple::before, .mdc-select__anchor:not(.mdc-ripple-upgraded):focus .mdc-select__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}

.mdc-select__anchor .mdc-select__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected .mdc-deprecated-list-item__ripple::before, .mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected .mdc-deprecated-list-item__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-on-surface, #000));
}

.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected:hover .mdc-deprecated-list-item__ripple::before, .mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected.mdc-ripple-surface--hover .mdc-deprecated-list-item__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}

.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected.mdc-ripple-upgraded--background-focused .mdc-deprecated-list-item__ripple::before, .mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected:not(.mdc-ripple-upgraded):focus .mdc-deprecated-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}

.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected:not(.mdc-ripple-upgraded) .mdc-deprecated-list-item__ripple::after {
  transition: opacity 150ms linear;
}

.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected:not(.mdc-ripple-upgraded):active .mdc-deprecated-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected .mdc-list-item__ripple::before, .mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected .mdc-list-item__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-on-surface, #000));
}

.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected:hover .mdc-list-item__ripple::before, .mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected.mdc-ripple-surface--hover .mdc-list-item__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}

.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, .mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}

.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected:not(.mdc-ripple-upgraded) .mdc-list-item__ripple::after {
  transition: opacity 150ms linear;
}

.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected:not(.mdc-ripple-upgraded):active .mdc-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-select-helper-text {
  margin: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 0.75rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: 0.0333333333em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}

[dir=rtl] .mdc-select-helper-text, .mdc-select-helper-text[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-select-helper-text::before {
  display: inline-block;
  width: 0;
  height: 16px;
  content: "";
  vertical-align: 0;
}

.mdc-select-helper-text--validation-msg {
  opacity: 0;
  transition: opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-select--invalid + .mdc-select-helper-text--validation-msg,
.mdc-select-helper-text--validation-msg-persistent {
  opacity: 1;
}

.mdc-select--with-leading-icon .mdc-select__icon {
  display: inline-block;
  box-sizing: border-box;
  border: none;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  flex-shrink: 0;
  align-self: center;
  background-color: transparent;
  fill: currentColor;
}

.mdc-select--with-leading-icon .mdc-select__icon {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 12px;
}

[dir=rtl] .mdc-select--with-leading-icon .mdc-select__icon, .mdc-select--with-leading-icon .mdc-select__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 12px;
  /*rtl:end:ignore*/
}

.mdc-select__icon:not([tabindex]),
.mdc-select__icon[tabindex="-1"] {
  cursor: default;
  pointer-events: none;
}

.mdc-data-table__content {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
}

.mdc-data-table {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-medium, 4px);
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  -webkit-overflow-scrolling: touch;
  display: inline-flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
}

.mdc-data-table .mdc-data-table__header-cell:first-child {
  border-top-left-radius: 4px;
  /* @alternate */
  border-top-left-radius: var(--mdc-shape-medium, 4px);
}

[dir=rtl] .mdc-data-table .mdc-data-table__header-cell:first-child, .mdc-data-table .mdc-data-table__header-cell:first-child[dir=rtl] {
  /*rtl:begin:ignore*/
  border-top-right-radius: 4px;
  /* @alternate */
  border-top-right-radius: var(--mdc-shape-medium, 4px);
  border-top-left-radius: 0;
  /*rtl:end:ignore*/
}

.mdc-data-table .mdc-data-table__header-cell:last-child {
  border-top-right-radius: 4px;
  /* @alternate */
  border-top-right-radius: var(--mdc-shape-medium, 4px);
}

[dir=rtl] .mdc-data-table .mdc-data-table__header-cell:last-child, .mdc-data-table .mdc-data-table__header-cell:last-child[dir=rtl] {
  /*rtl:begin:ignore*/
  border-top-left-radius: 4px;
  /* @alternate */
  border-top-left-radius: var(--mdc-shape-medium, 4px);
  border-top-right-radius: 0;
  /*rtl:end:ignore*/
}

.mdc-data-table.mdc-data-table--without-footer .mdc-data-table__row:last-child .mdc-data-table__cell:first-child {
  border-bottom-left-radius: 4px;
  /* @alternate */
  border-bottom-left-radius: var(--mdc-shape-medium, 4px);
}

[dir=rtl] .mdc-data-table.mdc-data-table--without-footer .mdc-data-table__row:last-child .mdc-data-table__cell:first-child, .mdc-data-table.mdc-data-table--without-footer .mdc-data-table__row:last-child .mdc-data-table__cell:first-child[dir=rtl] {
  /*rtl:begin:ignore*/
  border-bottom-right-radius: 4px;
  /* @alternate */
  border-bottom-right-radius: var(--mdc-shape-medium, 4px);
  border-bottom-left-radius: 0;
  /*rtl:end:ignore*/
}

.mdc-data-table.mdc-data-table--without-footer .mdc-data-table__row:last-child .mdc-data-table__cell:last-child {
  border-bottom-right-radius: 4px;
  /* @alternate */
  border-bottom-right-radius: var(--mdc-shape-medium, 4px);
}

[dir=rtl] .mdc-data-table.mdc-data-table--without-footer .mdc-data-table__row:last-child .mdc-data-table__cell:last-child, .mdc-data-table.mdc-data-table--without-footer .mdc-data-table__row:last-child .mdc-data-table__cell:last-child[dir=rtl] {
  /*rtl:begin:ignore*/
  border-bottom-left-radius: 4px;
  /* @alternate */
  border-bottom-left-radius: var(--mdc-shape-medium, 4px);
  border-bottom-right-radius: 0;
  /*rtl:end:ignore*/
}

.mdc-data-table__row {
  background-color: inherit;
}

.mdc-data-table__header-cell {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
}

.mdc-data-table__row--selected {
  background-color: rgba(98, 0, 238, 0.04);
}

.mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.12);
}

.mdc-data-table__cell,
.mdc-data-table__header-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mdc-data-table__pagination {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mdc-data-table__cell,
.mdc-data-table__header-cell {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.mdc-data-table__pagination {
  border-top-width: 1px;
  border-top-style: solid;
}

.mdc-data-table__row:last-child .mdc-data-table__cell {
  border-bottom: none;
}

.mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.mdc-data-table__header-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mdc-data-table__pagination-total,
.mdc-data-table__pagination-rows-per-page-label,
.mdc-data-table__cell {
  color: rgba(0, 0, 0, 0.87);
}

.mdc-data-table__row {
  height: 52px;
}

.mdc-data-table__pagination {
  min-height: 52px;
}

.mdc-data-table__header-row {
  height: 56px;
}

.mdc-data-table__cell,
.mdc-data-table__header-cell {
  padding: 0 16px 0 16px;
}

.mdc-data-table__header-cell--checkbox,
.mdc-data-table__cell--checkbox {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 4px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}

[dir=rtl] .mdc-data-table__header-cell--checkbox, [dir=rtl] .mdc-data-table__cell--checkbox, .mdc-data-table__header-cell--checkbox[dir=rtl], .mdc-data-table__cell--checkbox[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 4px;
  /*rtl:end:ignore*/
}

.mdc-data-table__table-container {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  width: 100%;
}

.mdc-data-table__table {
  min-width: 100%;
  border: 0;
  white-space: nowrap;
  border-spacing: 0;
  /**
   * With table-layout:fixed, table and column widths are defined by the width
   * of the first row of cells. Cells in subsequent rows do not affect column
   * widths. This results in a predictable table layout and may also speed up
   * rendering.
   */
  table-layout: fixed;
}

.mdc-data-table__cell {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  box-sizing: border-box;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
}

[dir=rtl] .mdc-data-table__cell, .mdc-data-table__cell[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  text-align: right;
  /*rtl:end:ignore*/
}

.mdc-data-table__cell--numeric {
  text-align: right;
}

[dir=rtl] .mdc-data-table__cell--numeric, .mdc-data-table__cell--numeric[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  text-align: left;
  /*rtl:end:ignore*/
}

.mdc-data-table__cell--checkbox {
  width: 1px;
}

.mdc-data-table__header-cell {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle2-font-size, 0.875rem);
  line-height: 1.375rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle2-line-height, 1.375rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle2-font-weight, 500);
  letter-spacing: 0.0071428571em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle2-letter-spacing, 0.0071428571em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle2-text-transform, inherit);
  box-sizing: border-box;
  text-overflow: ellipsis;
  overflow: hidden;
  outline: none;
  /* @noflip */ /*rtl:ignore*/
  text-align: left;
}

[dir=rtl] .mdc-data-table__header-cell, .mdc-data-table__header-cell[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  text-align: right;
  /*rtl:end:ignore*/
}

.mdc-data-table__header-cell--checkbox {
  width: 1px;
}

.mdc-data-table__header-cell--numeric {
  text-align: right;
}

[dir=rtl] .mdc-data-table__header-cell--numeric, .mdc-data-table__header-cell--numeric[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  text-align: left;
  /*rtl:end:ignore*/
}

.mdc-data-table__sort-icon-button {
  width: 28px;
  height: 28px;
  padding: 2px;
  transform: rotate(0.0001deg);
  /* @noflip */ /*rtl:ignore*/
  margin-left: 4px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  transition: transform 150ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
}

.mdc-data-table__sort-icon-button .mdc-icon-button__focus-ring {
  display: none;
}

.mdc-data-table__sort-icon-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring, .mdc-data-table__sort-icon-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring {
  display: block;
  max-height: 28px;
  max-width: 28px;
}

@media screen and (forced-colors: active) {
  .mdc-data-table__sort-icon-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring, .mdc-data-table__sort-icon-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring {
    pointer-events: none;
    border: 2px solid transparent;
    border-radius: 6px;
    box-sizing: content-box;
    position: absolute;
    top: 50%;
    /* @noflip */ /*rtl:ignore*/
    left: 50%;
    /* @noflip */ /*rtl:ignore*/
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
  }
}
@media screen and (forced-colors: active) and (forced-colors: active) {
  .mdc-data-table__sort-icon-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring, .mdc-data-table__sort-icon-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring {
    border-color: CanvasText;
  }
}
@media screen and (forced-colors: active) {
  .mdc-data-table__sort-icon-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring::after, .mdc-data-table__sort-icon-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring::after {
    content: "";
    border: 2px solid transparent;
    border-radius: 8px;
    display: block;
    position: absolute;
    top: 50%;
    /* @noflip */ /*rtl:ignore*/
    left: 50%;
    /* @noflip */ /*rtl:ignore*/
    transform: translate(-50%, -50%);
    height: calc(100% + 4px);
    width: calc(100% + 4px);
  }
}
@media screen and (forced-colors: active) and (forced-colors: active) {
  .mdc-data-table__sort-icon-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring::after, .mdc-data-table__sort-icon-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring::after {
    border-color: CanvasText;
  }
}
.mdc-data-table__sort-icon-button.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 28px;
  height: 28px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  margin-left: 0px;
}

.mdc-data-table__sort-icon-button.mdc-icon-button--reduced-size.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring, .mdc-data-table__sort-icon-button.mdc-icon-button--reduced-size:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring {
  max-height: 28px;
  max-width: 28px;
}

.mdc-data-table__sort-icon-button .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 28px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 28px;
  transform: translate(-50%, -50%);
}

[dir=rtl] .mdc-data-table__sort-icon-button, .mdc-data-table__sort-icon-button[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 4px;
  /*rtl:end:ignore*/
}

.mdc-data-table__header-cell--numeric .mdc-data-table__sort-icon-button {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 4px;
}

[dir=rtl] .mdc-data-table__header-cell--numeric .mdc-data-table__sort-icon-button, .mdc-data-table__header-cell--numeric .mdc-data-table__sort-icon-button[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 4px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-data-table__header-cell--sorted-descending .mdc-data-table__sort-icon-button {
  transform: rotate(-180deg);
}

.mdc-data-table__sort-icon-button:focus, .mdc-data-table__header-cell:hover .mdc-data-table__sort-icon-button, .mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button {
  opacity: 1;
}

.mdc-data-table__header-cell-wrapper {
  align-items: center;
  display: inline-flex;
  vertical-align: middle;
}

.mdc-data-table__header-cell--with-sort {
  cursor: pointer;
}

.mdc-data-table__sort-status-label {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap; /* added line */
  width: 1px;
}

.mdc-data-table--sticky-header .mdc-data-table__header-cell {
  position: sticky;
  top: 0;
  z-index: 1;
}

.mdc-data-table__sort-icon-button {
  color: rgba(0, 0, 0, 0.6);
}

.mdc-data-table__sort-icon-button .mdc-icon-button__ripple::before, .mdc-data-table__sort-icon-button .mdc-icon-button__ripple::after {
  background-color: rgba(0, 0, 0, 0.6);
  /* @alternate */
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.6));
}

.mdc-data-table__sort-icon-button:hover .mdc-icon-button__ripple::before, .mdc-data-table__sort-icon-button.mdc-ripple-surface--hover .mdc-icon-button__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}

.mdc-data-table__sort-icon-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__ripple::before, .mdc-data-table__sort-icon-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}

.mdc-data-table__sort-icon-button:not(.mdc-ripple-upgraded) .mdc-icon-button__ripple::after {
  transition: opacity 150ms linear;
}

.mdc-data-table__sort-icon-button:not(.mdc-ripple-upgraded):active .mdc-icon-button__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-data-table__sort-icon-button.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button {
  color: rgba(0, 0, 0, 0.87);
}

.mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button .mdc-icon-button__ripple::before, .mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button .mdc-icon-button__ripple::after {
  background-color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.87));
}

.mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button:hover .mdc-icon-button__ripple::before, .mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button.mdc-ripple-surface--hover .mdc-icon-button__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}

.mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__ripple::before, .mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}

.mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button:not(.mdc-ripple-upgraded) .mdc-icon-button__ripple::after {
  transition: opacity 150ms linear;
}

.mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button:not(.mdc-ripple-upgraded):active .mdc-icon-button__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-data-table__progress-indicator {
  display: none;
  position: absolute;
  width: 100%;
}

.mdc-data-table--in-progress .mdc-data-table__progress-indicator {
  display: block;
}

.mdc-data-table__scrim {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
  height: 100%;
  opacity: 0.32;
  position: absolute;
  top: 0;
  width: 100%;
}

.mdc-data-table__pagination {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
}

.mdc-data-table__pagination-trailing {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 4px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

[dir=rtl] .mdc-data-table__pagination-trailing, .mdc-data-table__pagination-trailing[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 4px;
  /*rtl:end:ignore*/
}

.mdc-data-table__pagination-navigation {
  align-items: center;
  display: flex;
}

.mdc-data-table__pagination-button {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 4px;
}

[dir=rtl] .mdc-data-table__pagination-button .mdc-button__icon, .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  transform: rotate(180deg);
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-data-table__pagination-button, .mdc-data-table__pagination-button[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 4px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-data-table__pagination-total {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 14px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 36px;
  white-space: nowrap;
}

[dir=rtl] .mdc-data-table__pagination-total, .mdc-data-table__pagination-total[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 36px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 14px;
  /*rtl:end:ignore*/
}

.mdc-data-table__pagination-rows-per-page {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 22px;
  align-items: center;
  display: inline-flex;
}

[dir=rtl] .mdc-data-table__pagination-rows-per-page, .mdc-data-table__pagination-rows-per-page[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 22px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-data-table__pagination-rows-per-page-label {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 12px;
  white-space: nowrap;
}

[dir=rtl] .mdc-data-table__pagination-rows-per-page-label, .mdc-data-table__pagination-rows-per-page-label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-data-table__pagination-rows-per-page-select {
  min-width: 80px;
  /* @alternate */
  min-width: var(--mdc-menu-min-width, 80px);
  margin: 8px 0;
}

.mdc-data-table__pagination-rows-per-page-select .mdc-select__anchor {
  width: 100%;
  min-width: 80px;
}

.mdc-data-table__pagination-rows-per-page-select .mdc-select__anchor {
  height: 36px;
}

.mdc-data-table__pagination-rows-per-page-select .mdc-select__anchor .mdc-floating-label--float-above {
  transform: translateY(-27.25px) scale(1);
}

.mdc-data-table__pagination-rows-per-page-select .mdc-select__anchor .mdc-floating-label--float-above {
  font-size: 0.75rem;
}

.mdc-data-table__pagination-rows-per-page-select .mdc-select__anchor.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-data-table__pagination-rows-per-page-select .mdc-select__anchor .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-24.75px) scale(0.75);
}

.mdc-data-table__pagination-rows-per-page-select .mdc-select__anchor.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-data-table__pagination-rows-per-page-select .mdc-select__anchor .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}

.mdc-data-table__pagination-rows-per-page-select .mdc-select__anchor .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-select-outlined-36px 250ms 1;
}

@keyframes mdc-floating-label-shake-float-above-select-outlined-36px {
  0% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - 0%)) translateY(-24.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(4% - 0%)) translateY(-24.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(-4% - 0%)) translateY(-24.75px) scale(0.75);
  }
  100% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - 0%)) translateY(-24.75px) scale(0.75);
  }
}
.mdc-data-table__pagination-rows-per-page-select .mdc-select__dropdown-icon {
  width: 20px;
  height: 20px;
}

.mdc-data-table__pagination-rows-per-page-select.mdc-select--outlined .mdc-select__anchor :not(.mdc-notched-outline--notched) .mdc-notched-outline__notch {
  max-width: calc(100% - 56px);
}

.mdc-data-table__pagination-rows-per-page-select .mdc-list-item.mdc-list-item--with-one-line {
  height: 36px;
}

.mdc-data-table__header-row-checkbox.mdc-checkbox--selected .mdc-checkbox__ripple::before, .mdc-data-table__header-row-checkbox.mdc-checkbox--selected .mdc-checkbox__ripple::after,
.mdc-data-table__row-checkbox.mdc-checkbox--selected .mdc-checkbox__ripple::before,
.mdc-data-table__row-checkbox.mdc-checkbox--selected .mdc-checkbox__ripple::after {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #6200ee));
}

.mdc-data-table__header-row-checkbox.mdc-checkbox--selected:hover .mdc-checkbox__ripple::before, .mdc-data-table__header-row-checkbox.mdc-checkbox--selected.mdc-ripple-surface--hover .mdc-checkbox__ripple::before,
.mdc-data-table__row-checkbox.mdc-checkbox--selected:hover .mdc-checkbox__ripple::before,
.mdc-data-table__row-checkbox.mdc-checkbox--selected.mdc-ripple-surface--hover .mdc-checkbox__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}

.mdc-data-table__header-row-checkbox.mdc-checkbox--selected.mdc-ripple-upgraded--background-focused .mdc-checkbox__ripple::before, .mdc-data-table__header-row-checkbox.mdc-checkbox--selected:not(.mdc-ripple-upgraded):focus .mdc-checkbox__ripple::before,
.mdc-data-table__row-checkbox.mdc-checkbox--selected.mdc-ripple-upgraded--background-focused .mdc-checkbox__ripple::before,
.mdc-data-table__row-checkbox.mdc-checkbox--selected:not(.mdc-ripple-upgraded):focus .mdc-checkbox__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}

.mdc-data-table__header-row-checkbox.mdc-checkbox--selected:not(.mdc-ripple-upgraded) .mdc-checkbox__ripple::after,
.mdc-data-table__row-checkbox.mdc-checkbox--selected:not(.mdc-ripple-upgraded) .mdc-checkbox__ripple::after {
  transition: opacity 150ms linear;
}

.mdc-data-table__header-row-checkbox.mdc-checkbox--selected:not(.mdc-ripple-upgraded):active .mdc-checkbox__ripple::after,
.mdc-data-table__row-checkbox.mdc-checkbox--selected:not(.mdc-ripple-upgraded):active .mdc-checkbox__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-data-table__header-row-checkbox.mdc-checkbox--selected.mdc-ripple-upgraded,
.mdc-data-table__row-checkbox.mdc-checkbox--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-data-table__header-row-checkbox.mdc-ripple-upgraded--background-focused.mdc-checkbox--selected .mdc-checkbox__ripple::before,
.mdc-data-table__header-row-checkbox.mdc-ripple-upgraded--background-focused.mdc-checkbox--selected .mdc-checkbox__ripple::after,
.mdc-data-table__row-checkbox.mdc-ripple-upgraded--background-focused.mdc-checkbox--selected .mdc-checkbox__ripple::before,
.mdc-data-table__row-checkbox.mdc-ripple-upgraded--background-focused.mdc-checkbox--selected .mdc-checkbox__ripple::after {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #6200ee));
}

.mdc-data-table__header-row-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background,
.mdc-data-table__row-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
  border-color: rgba(0, 0, 0, 0.54);
  /* @alternate */
  border-color: var(--mdc-checkbox-unchecked-color, rgba(0, 0, 0, 0.54));
  background-color: transparent;
}

.mdc-data-table__header-row-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.mdc-data-table__header-row-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
.mdc-data-table__header-row-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled ~ .mdc-checkbox__background,
.mdc-data-table__row-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.mdc-data-table__row-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
.mdc-data-table__row-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled ~ .mdc-checkbox__background {
  border-color: #6200ee;
  /* @alternate */
  border-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #6200ee));
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #6200ee));
}

@keyframes mdc-checkbox-fade-in-background-8A000000FF6200EE00000000FF6200EE {
  0% {
    border-color: rgba(0, 0, 0, 0.54);
    /* @alternate */
    border-color: var(--mdc-checkbox-unchecked-color, rgba(0, 0, 0, 0.54));
    background-color: transparent;
  }
  50% {
    border-color: #6200ee;
    /* @alternate */
    border-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #6200ee));
    background-color: #6200ee;
    /* @alternate */
    background-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #6200ee));
  }
}
@keyframes mdc-checkbox-fade-out-background-8A000000FF6200EE00000000FF6200EE {
  0%, 80% {
    border-color: #6200ee;
    /* @alternate */
    border-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #6200ee));
    background-color: #6200ee;
    /* @alternate */
    background-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #6200ee));
  }
  100% {
    border-color: rgba(0, 0, 0, 0.54);
    /* @alternate */
    border-color: var(--mdc-checkbox-unchecked-color, rgba(0, 0, 0, 0.54));
    background-color: transparent;
  }
}
.mdc-data-table__header-row-checkbox.mdc-checkbox--anim-unchecked-checked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background, .mdc-data-table__header-row-checkbox.mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background,
.mdc-data-table__row-checkbox.mdc-checkbox--anim-unchecked-checked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background,
.mdc-data-table__row-checkbox.mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background {
  animation-name: mdc-checkbox-fade-in-background-8A000000FF6200EE00000000FF6200EE;
}

.mdc-data-table__header-row-checkbox.mdc-checkbox--anim-checked-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background, .mdc-data-table__header-row-checkbox.mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background,
.mdc-data-table__row-checkbox.mdc-checkbox--anim-checked-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background,
.mdc-data-table__row-checkbox.mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background {
  animation-name: mdc-checkbox-fade-out-background-8A000000FF6200EE00000000FF6200EE;
}

.mdc-dialog .mdc-dialog__surface {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
}

.mdc-dialog .mdc-dialog__scrim {
  background-color: rgba(0, 0, 0, 0.32);
}

.mdc-dialog .mdc-dialog__surface-scrim {
  background-color: rgba(0, 0, 0, 0.32);
}

.mdc-dialog .mdc-dialog__title {
  color: rgba(0, 0, 0, 0.87);
}

.mdc-dialog .mdc-dialog__content {
  color: rgba(0, 0, 0, 0.6);
}

.mdc-dialog .mdc-dialog__close {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-dialog .mdc-dialog__close .mdc-icon-button__ripple::before, .mdc-dialog .mdc-dialog__close .mdc-icon-button__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-on-surface, #000));
}

.mdc-dialog .mdc-dialog__close:hover .mdc-icon-button__ripple::before, .mdc-dialog .mdc-dialog__close.mdc-ripple-surface--hover .mdc-icon-button__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}

.mdc-dialog .mdc-dialog__close.mdc-ripple-upgraded--background-focused .mdc-icon-button__ripple::before, .mdc-dialog .mdc-dialog__close:not(.mdc-ripple-upgraded):focus .mdc-icon-button__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}

.mdc-dialog .mdc-dialog__close:not(.mdc-ripple-upgraded) .mdc-icon-button__ripple::after {
  transition: opacity 150ms linear;
}

.mdc-dialog .mdc-dialog__close:not(.mdc-ripple-upgraded):active .mdc-icon-button__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-dialog .mdc-dialog__close.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-dialog.mdc-dialog--scrollable .mdc-dialog__title, .mdc-dialog.mdc-dialog--scrollable .mdc-dialog__actions, .mdc-dialog.mdc-dialog--scrollable.mdc-dialog-scroll-divider-footer .mdc-dialog__actions {
  border-color: rgba(0, 0, 0, 0.12);
}

.mdc-dialog.mdc-dialog--scrollable .mdc-dialog__title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 0;
}

.mdc-dialog.mdc-dialog-scroll-divider-header.mdc-dialog--fullscreen .mdc-dialog__header {
  /* @alternate */
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mdc-dialog .mdc-dialog__surface {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-medium, 4px);
}

.mdc-dialog__surface {
  /* @alternate */
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mdc-dialog__title {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1.25rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline6-font-size, 1.25rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline6-line-height, 2rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-headline6-font-weight, 500);
  letter-spacing: 0.0125em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline6-letter-spacing, 0.0125em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline6-text-transform, inherit);
}

.mdc-dialog__content {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-body1-font-size, 1rem);
  line-height: 1.5rem;
  /* @alternate */
  line-height: var(--mdc-typography-body1-line-height, 1.5rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: 0.03125em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body1-letter-spacing, 0.03125em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body1-text-transform, inherit);
}

.mdc-dialog__title-icon {
  /** Hook for theming API. */
}

.mdc-dialog,
.mdc-dialog__scrim {
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.mdc-dialog {
  display: none;
  z-index: 7;
  /* @alternate */
  z-index: var(--mdc-dialog-z-index, 7);
}

.mdc-dialog .mdc-dialog__content {
  padding: 20px 24px 20px 24px;
}

.mdc-dialog .mdc-dialog__surface {
  min-width: 280px;
}

@media (max-width: 592px) {
  .mdc-dialog .mdc-dialog__surface {
    max-width: calc(100vw - 32px);
  }
}
@media (min-width: 592px) {
  .mdc-dialog .mdc-dialog__surface {
    max-width: 560px;
  }
}
.mdc-dialog .mdc-dialog__surface {
  max-height: calc(100% - 32px);
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mdc-dialog .mdc-dialog__container {
    /* stylelint-disable */
    /* stylelint-enable*/
  }
}
.mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
  max-width: none;
}

@media (max-width: 960px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    max-height: 560px;
    width: 560px;
  }
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__close {
    right: -12px;
  }
}
@media (max-width: 720px) and (max-width: 672px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    width: calc(100vw - 112px);
  }
}
@media (max-width: 720px) and (min-width: 672px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    width: 560px;
  }
}
@media (max-width: 720px) and (max-height: 720px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    max-height: calc(100vh - 160px);
  }
}
@media (max-width: 720px) and (min-height: 720px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    max-height: 560px;
  }
}
@media (max-width: 720px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__close {
    right: -12px;
  }
}
@media (max-width: 720px) and (max-height: 400px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    height: 100%;
    max-height: 100vh;
    max-width: 100vw;
    width: 100vw;
    border-radius: 0;
  }
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__close {
    order: -1;
    left: -12px;
  }
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__header {
    padding: 0 16px 9px;
    justify-content: flex-start;
  }
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__title {
    margin-left: calc(16px - 2 * 12px);
  }
}
@media (max-width: 600px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    height: 100%;
    max-height: 100vh;
    max-width: 100vw;
    width: 100vw;
    border-radius: 0;
  }
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__close {
    order: -1;
    left: -12px;
  }
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__header {
    padding: 0 16px 9px;
    justify-content: flex-start;
  }
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__title {
    margin-left: calc(16px - 2 * 12px);
  }
}
@media (min-width: 960px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    width: calc(100vw - 400px);
  }
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__close {
    right: -12px;
  }
}
.mdc-dialog.mdc-dialog__scrim--hidden .mdc-dialog__scrim {
  opacity: 0;
}

.mdc-dialog__scrim {
  opacity: 0;
  z-index: -1;
}

.mdc-dialog__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  height: 100%;
  transform: scale(0.8);
  opacity: 0;
  pointer-events: none;
}

.mdc-dialog__surface {
  /* @alternate */
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  max-width: 100%;
  max-height: 100%;
  pointer-events: auto;
  overflow-y: auto;
}

.mdc-dialog__surface .mdc-elevation-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
}

[dir=rtl] .mdc-dialog__surface, .mdc-dialog__surface[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  text-align: right;
  /*rtl:end:ignore*/
}

@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-dialog__surface {
    outline: 2px solid windowText;
  }
}
.mdc-dialog__surface::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 2px solid transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}

@media screen and (forced-colors: active) {
  .mdc-dialog__surface::before {
    border-color: CanvasText;
  }
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .mdc-dialog__surface::before {
    content: none;
  }
}
.mdc-dialog__title {
  display: block;
  margin-top: 0;
  /* @alternate */
  position: relative;
  flex-shrink: 0;
  box-sizing: border-box;
  margin: 0 0 1px;
  padding: 0 24px 9px;
}

.mdc-dialog__title::before {
  display: inline-block;
  width: 0;
  height: 40px;
  content: "";
  vertical-align: 0;
}

[dir=rtl] .mdc-dialog__title, .mdc-dialog__title[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  text-align: right;
  /*rtl:end:ignore*/
}

.mdc-dialog--scrollable .mdc-dialog__title {
  margin-bottom: 1px;
  padding-bottom: 15px;
}

.mdc-dialog--fullscreen .mdc-dialog__header {
  align-items: baseline;
  border-bottom: 1px solid transparent;
  display: inline-flex;
  justify-content: space-between;
  padding: 0 24px 9px;
  z-index: 1;
}

@media screen and (forced-colors: active) {
  .mdc-dialog--fullscreen .mdc-dialog__header {
    border-bottom-color: CanvasText;
  }
}
.mdc-dialog--fullscreen .mdc-dialog__header .mdc-dialog__close {
  right: -12px;
}

.mdc-dialog--fullscreen .mdc-dialog__title {
  margin-bottom: 0;
  padding: 0;
  border-bottom: 0;
}

.mdc-dialog--fullscreen.mdc-dialog--scrollable .mdc-dialog__title {
  border-bottom: 0;
  margin-bottom: 0;
}

.mdc-dialog--fullscreen .mdc-dialog__close {
  top: 5px;
}

.mdc-dialog--fullscreen.mdc-dialog--scrollable .mdc-dialog__actions {
  border-top: 1px solid transparent;
}

@media screen and (forced-colors: active) {
  .mdc-dialog--fullscreen.mdc-dialog--scrollable .mdc-dialog__actions {
    border-top-color: CanvasText;
  }
}
.mdc-dialog__content {
  flex-grow: 1;
  box-sizing: border-box;
  margin: 0;
  overflow: auto;
}

.mdc-dialog__content > :first-child {
  margin-top: 0;
}

.mdc-dialog__content > :last-child {
  margin-bottom: 0;
}

.mdc-dialog__title + .mdc-dialog__content,
.mdc-dialog__header + .mdc-dialog__content {
  padding-top: 0;
}

.mdc-dialog--scrollable .mdc-dialog__title + .mdc-dialog__content {
  padding-top: 8px;
  padding-bottom: 8px;
}

.mdc-dialog__content .mdc-deprecated-list:first-child:last-child {
  padding: 6px 0 0;
}

.mdc-dialog--scrollable .mdc-dialog__content .mdc-deprecated-list:first-child:last-child {
  padding: 0;
}

.mdc-dialog__actions {
  display: flex;
  position: relative;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  min-height: 52px;
  margin: 0;
  padding: 8px;
  border-top: 1px solid transparent;
}

@media screen and (forced-colors: active) {
  .mdc-dialog__actions {
    border-top-color: CanvasText;
  }
}
.mdc-dialog--stacked .mdc-dialog__actions {
  flex-direction: column;
  align-items: flex-end;
}

.mdc-dialog__button {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  max-width: 100%;
  /* @noflip */ /*rtl:ignore*/
  text-align: right;
}

[dir=rtl] .mdc-dialog__button, .mdc-dialog__button[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 8px;
  /*rtl:end:ignore*/
}

.mdc-dialog__button:first-child {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
}

[dir=rtl] .mdc-dialog__button:first-child, .mdc-dialog__button:first-child[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-dialog__button, .mdc-dialog__button[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  text-align: left;
  /*rtl:end:ignore*/
}

.mdc-dialog--stacked .mdc-dialog__button:not(:first-child) {
  margin-top: 12px;
}

.mdc-dialog--open,
.mdc-dialog--opening,
.mdc-dialog--closing {
  display: flex;
}

.mdc-dialog--opening .mdc-dialog__scrim {
  transition: opacity 150ms linear;
}

.mdc-dialog--opening .mdc-dialog__container {
  transition: opacity 75ms linear, transform 150ms 0ms cubic-bezier(0, 0, 0.2, 1);
}

.mdc-dialog--closing .mdc-dialog__scrim,
.mdc-dialog--closing .mdc-dialog__container {
  transition: opacity 75ms linear;
}

.mdc-dialog--closing .mdc-dialog__container {
  transform: none;
}

.mdc-dialog--open .mdc-dialog__scrim {
  opacity: 1;
}

.mdc-dialog--open .mdc-dialog__container {
  transform: none;
  opacity: 1;
}

.mdc-dialog--open.mdc-dialog__surface-scrim--shown .mdc-dialog__surface-scrim {
  opacity: 1;
  z-index: 1;
}

.mdc-dialog--open.mdc-dialog__surface-scrim--hiding .mdc-dialog__surface-scrim {
  transition: opacity 75ms linear;
}

.mdc-dialog--open.mdc-dialog__surface-scrim--showing .mdc-dialog__surface-scrim {
  transition: opacity 150ms linear;
}

.mdc-dialog__surface-scrim {
  display: none;
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
}

.mdc-dialog__surface-scrim--shown .mdc-dialog__surface-scrim, .mdc-dialog__surface-scrim--showing .mdc-dialog__surface-scrim, .mdc-dialog__surface-scrim--hiding .mdc-dialog__surface-scrim {
  display: block;
}

.mdc-dialog-scroll-lock {
  overflow: hidden;
}

.mdc-dialog--no-content-padding .mdc-dialog__content {
  padding: 0;
}

.mdc-dialog--sheet .mdc-dialog__close {
  right: 12px;
  top: 9px;
  position: absolute;
  z-index: 1;
}

.mdc-drawer {
  border-color: rgba(0, 0, 0, 0.12);
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
  /* @noflip */ /*rtl:ignore*/
  border-top-left-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-top-right-radius: 0;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-top-right-radius: var(--mdc-shape-large, 0);
  /* @noflip */ /*rtl:ignore*/
  border-bottom-right-radius: 0;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-bottom-right-radius: var(--mdc-shape-large, 0);
  /* @noflip */ /*rtl:ignore*/
  border-bottom-left-radius: 0;
  z-index: 6;
  width: 256px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  box-sizing: border-box;
  height: 100%;
  /* @noflip */ /*rtl:ignore*/
  border-right-width: 1px;
  /* @noflip */ /*rtl:ignore*/
  border-right-style: solid;
  overflow: hidden;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-drawer .mdc-drawer__title {
  color: rgba(0, 0, 0, 0.87);
}

.mdc-drawer .mdc-deprecated-list-group__subheader {
  color: rgba(0, 0, 0, 0.6);
}

.mdc-drawer .mdc-drawer__subtitle {
  color: rgba(0, 0, 0, 0.6);
}

.mdc-drawer .mdc-deprecated-list-item__graphic {
  color: rgba(0, 0, 0, 0.6);
}

.mdc-drawer .mdc-deprecated-list-item {
  color: rgba(0, 0, 0, 0.87);
}

.mdc-drawer .mdc-deprecated-list-item--activated .mdc-deprecated-list-item__graphic {
  color: #6200ee;
}

.mdc-drawer .mdc-deprecated-list-item--activated {
  color: rgba(98, 0, 238, 0.87);
}

[dir=rtl] .mdc-drawer, .mdc-drawer[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  border-top-left-radius: 0;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-top-left-radius: var(--mdc-shape-large, 0);
  /* @noflip */ /*rtl:ignore*/
  border-top-right-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-bottom-right-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-bottom-left-radius: 0;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-bottom-left-radius: var(--mdc-shape-large, 0);
  /*rtl:end:ignore*/
}

.mdc-drawer .mdc-deprecated-list-item {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-small, 4px);
}

.mdc-drawer.mdc-drawer--open:not(.mdc-drawer--closing) + .mdc-drawer-app-content {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 256px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
}

[dir=rtl] .mdc-drawer.mdc-drawer--open:not(.mdc-drawer--closing) + .mdc-drawer-app-content, .mdc-drawer.mdc-drawer--open:not(.mdc-drawer--closing) + .mdc-drawer-app-content[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 256px;
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-drawer, .mdc-drawer[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  border-right-width: 0;
  /* @noflip */ /*rtl:ignore*/
  border-left-width: 1px;
  /* @noflip */ /*rtl:ignore*/
  border-right-style: none;
  /* @noflip */ /*rtl:ignore*/
  border-left-style: solid;
  /*rtl:end:ignore*/
}

.mdc-drawer .mdc-deprecated-list-item {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle2-font-size, 0.875rem);
  line-height: 1.375rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle2-line-height, 1.375rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle2-font-weight, 500);
  letter-spacing: 0.0071428571em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle2-letter-spacing, 0.0071428571em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle2-text-transform, inherit);
  height: calc(48px - 2 * 4px);
  margin: 8px 8px;
  padding: 0 8px;
}

.mdc-drawer .mdc-deprecated-list-item:nth-child(1) {
  margin-top: 2px;
}

.mdc-drawer .mdc-deprecated-list-item:nth-last-child(1) {
  margin-bottom: 0;
}

.mdc-drawer .mdc-deprecated-list-group__subheader {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin: 0;
  padding: 0 16px;
}

.mdc-drawer .mdc-deprecated-list-group__subheader::before {
  display: inline-block;
  width: 0;
  height: 24px;
  content: "";
  vertical-align: 0;
}

.mdc-drawer .mdc-deprecated-list-divider {
  margin: 3px 0 4px;
}

.mdc-drawer .mdc-deprecated-list-item__text,
.mdc-drawer .mdc-deprecated-list-item__graphic {
  pointer-events: none;
}

.mdc-drawer--animate {
  transform: translateX(-100%);
}

[dir=rtl] .mdc-drawer--animate, .mdc-drawer--animate[dir=rtl] {
  /*rtl:begin:ignore*/
  transform: translateX(100%);
  /*rtl:end:ignore*/
}

.mdc-drawer--opening {
  transform: translateX(0);
  transition-duration: 250ms;
}

[dir=rtl] .mdc-drawer--opening, .mdc-drawer--opening[dir=rtl] {
  /*rtl:begin:ignore*/
  transform: translateX(0);
  /*rtl:end:ignore*/
}

.mdc-drawer--closing {
  transform: translateX(-100%);
  transition-duration: 200ms;
}

[dir=rtl] .mdc-drawer--closing, .mdc-drawer--closing[dir=rtl] {
  /*rtl:begin:ignore*/
  transform: translateX(100%);
  /*rtl:end:ignore*/
}

.mdc-drawer__header {
  flex-shrink: 0;
  box-sizing: border-box;
  min-height: 64px;
  padding: 0 16px 4px;
}

.mdc-drawer__title {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1.25rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline6-font-size, 1.25rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline6-line-height, 2rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-headline6-font-weight, 500);
  letter-spacing: 0.0125em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline6-letter-spacing, 0.0125em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline6-text-transform, inherit);
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}

.mdc-drawer__title::before {
  display: inline-block;
  width: 0;
  height: 36px;
  content: "";
  vertical-align: 0;
}

.mdc-drawer__title::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-drawer__subtitle {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: 0;
}

.mdc-drawer__subtitle::before {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: 0;
}

.mdc-drawer__content {
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.mdc-drawer--dismissible {
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  /* @noflip */ /*rtl:ignore*/
  right: initial;
  display: none;
  position: absolute;
}

[dir=rtl] .mdc-drawer--dismissible, .mdc-drawer--dismissible[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  left: initial;
  /* @noflip */ /*rtl:ignore*/
  right: 0;
  /*rtl:end:ignore*/
}

.mdc-drawer--dismissible.mdc-drawer--open {
  display: flex;
}

.mdc-drawer-app-content {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  position: relative;
}

[dir=rtl] .mdc-drawer-app-content, .mdc-drawer-app-content[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-drawer--modal {
  /* @alternate */
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  /* @noflip */ /*rtl:ignore*/
  right: initial;
  display: none;
  position: fixed;
}

.mdc-drawer--modal + .mdc-drawer-scrim {
  background-color: rgba(0, 0, 0, 0.32);
}

[dir=rtl] .mdc-drawer--modal, .mdc-drawer--modal[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  left: initial;
  /* @noflip */ /*rtl:ignore*/
  right: 0;
  /*rtl:end:ignore*/
}

.mdc-drawer--modal.mdc-drawer--open {
  display: flex;
}

.mdc-drawer-scrim {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-drawer--open + .mdc-drawer-scrim {
  display: block;
}

.mdc-drawer--animate + .mdc-drawer-scrim {
  opacity: 0;
}

.mdc-drawer--opening + .mdc-drawer-scrim {
  transition-duration: 250ms;
  opacity: 1;
}

.mdc-drawer--closing + .mdc-drawer-scrim {
  transition-duration: 200ms;
  opacity: 0;
}

.mdc-elevation--z0 {
  /* @alternate */
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z1 {
  /* @alternate */
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z2 {
  /* @alternate */
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z3 {
  /* @alternate */
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z4 {
  /* @alternate */
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z5 {
  /* @alternate */
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z6 {
  /* @alternate */
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z7 {
  /* @alternate */
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z8 {
  /* @alternate */
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z9 {
  /* @alternate */
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z10 {
  /* @alternate */
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z11 {
  /* @alternate */
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z12 {
  /* @alternate */
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z13 {
  /* @alternate */
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z14 {
  /* @alternate */
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z15 {
  /* @alternate */
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z16 {
  /* @alternate */
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z17 {
  /* @alternate */
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z18 {
  /* @alternate */
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z19 {
  /* @alternate */
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z20 {
  /* @alternate */
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z21 {
  /* @alternate */
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z22 {
  /* @alternate */
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z23 {
  /* @alternate */
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z24 {
  /* @alternate */
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mdc-elevation-transition {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  will-change: box-shadow;
}

.mdc-fab {
  /* @alternate */
  position: relative;
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 56px;
  height: 56px;
  padding: 0;
  border: none;
  fill: currentColor;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  overflow: visible;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), opacity 15ms linear 30ms, transform 270ms 0ms cubic-bezier(0, 0, 0.2, 1);
}

.mdc-fab .mdc-elevation-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
}

.mdc-fab::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mdc-fab:hover {
  /* @alternate */
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mdc-fab.mdc-ripple-upgraded--background-focused, .mdc-fab:not(.mdc-ripple-upgraded):focus {
  /* @alternate */
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mdc-fab .mdc-fab__focus-ring {
  position: absolute;
}

.mdc-fab.mdc-ripple-upgraded--background-focused .mdc-fab__focus-ring, .mdc-fab:not(.mdc-ripple-upgraded):focus .mdc-fab__focus-ring {
  pointer-events: none;
  border: 2px solid transparent;
  border-radius: 6px;
  box-sizing: content-box;
  position: absolute;
  top: 50%;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  /* @noflip */ /*rtl:ignore*/
  transform: translate(-50%, -50%);
  height: calc(
      100% + 4px
    );
  width: calc(
      100% + 4px
    );
}

@media screen and (forced-colors: active) {
  .mdc-fab.mdc-ripple-upgraded--background-focused .mdc-fab__focus-ring, .mdc-fab:not(.mdc-ripple-upgraded):focus .mdc-fab__focus-ring {
    border-color: CanvasText;
  }
}
.mdc-fab.mdc-ripple-upgraded--background-focused .mdc-fab__focus-ring::after, .mdc-fab:not(.mdc-ripple-upgraded):focus .mdc-fab__focus-ring::after {
  content: "";
  border: 2px solid transparent;
  border-radius: 8px;
  display: block;
  position: absolute;
  top: 50%;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  /* @noflip */ /*rtl:ignore*/
  transform: translate(-50%, -50%);
  height: calc(100% + 4px);
  width: calc(100% + 4px);
}

@media screen and (forced-colors: active) {
  .mdc-fab.mdc-ripple-upgraded--background-focused .mdc-fab__focus-ring::after, .mdc-fab:not(.mdc-ripple-upgraded):focus .mdc-fab__focus-ring::after {
    border-color: CanvasText;
  }
}
.mdc-fab:active, .mdc-fab:focus:active {
  /* @alternate */
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mdc-fab:active, .mdc-fab:focus {
  outline: none;
}

.mdc-fab:hover {
  cursor: pointer;
}

.mdc-fab > svg {
  width: 100%;
}

.mdc-fab--mini {
  width: 40px;
  height: 40px;
}

.mdc-fab--extended {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-button-font-size, 0.875rem);
  line-height: 2.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-button-line-height, 2.25rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: 0.0892857143em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-button-letter-spacing, 0.0892857143em);
  text-decoration: none;
  /* @alternate */
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: uppercase;
  /* @alternate */
  text-transform: var(--mdc-typography-button-text-transform, uppercase);
  border-radius: 24px;
  /* @noflip */ /*rtl:ignore*/
  padding-left: 20px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 20px;
  width: auto;
  max-width: 100%;
  height: 48px;
  /* @alternate */
  line-height: normal;
}

.mdc-fab--extended .mdc-fab__ripple {
  border-radius: 24px;
}

.mdc-fab--extended .mdc-fab__icon {
  /* @noflip */ /*rtl:ignore*/
  margin-left: calc(12px - 20px);
  /* @noflip */ /*rtl:ignore*/
  margin-right: 12px;
}

[dir=rtl] .mdc-fab--extended .mdc-fab__icon, .mdc-fab--extended .mdc-fab__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: calc(12px - 20px);
  /*rtl:end:ignore*/
}

.mdc-fab--extended .mdc-fab__label + .mdc-fab__icon {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: calc(12px - 20px);
}

[dir=rtl] .mdc-fab--extended .mdc-fab__label + .mdc-fab__icon, .mdc-fab--extended .mdc-fab__label + .mdc-fab__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: calc(12px - 20px);
  /* @noflip */ /*rtl:ignore*/
  margin-right: 12px;
  /*rtl:end:ignore*/
}

.mdc-fab--touch {
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 4px;
  margin-left: 4px;
}

.mdc-fab--touch .mdc-fab__touch {
  position: absolute;
  top: 50%;
  height: 48px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 48px;
  transform: translate(-50%, -50%);
}

.mdc-fab::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 1px solid transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}

@media screen and (forced-colors: active) {
  .mdc-fab::before {
    border-color: CanvasText;
  }
}
.mdc-fab__label {
  justify-content: flex-start;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: visible;
}

.mdc-fab__icon {
  transition: transform 180ms 90ms cubic-bezier(0, 0, 0.2, 1);
  fill: currentColor;
  will-change: transform;
}

.mdc-fab .mdc-fab__icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.mdc-fab--exited {
  transform: scale(0);
  opacity: 0;
  transition: opacity 15ms linear 150ms, transform 180ms 0ms cubic-bezier(0.4, 0, 1, 1);
}

.mdc-fab--exited .mdc-fab__icon {
  transform: scale(0);
  transition: transform 135ms 0ms cubic-bezier(0.4, 0, 1, 1);
}

.mdc-fab {
  background-color: #018786;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #018786);
  /* @alternate */
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mdc-fab .mdc-fab__icon {
  width: 24px;
  height: 24px;
  font-size: 24px;
}

.mdc-fab, .mdc-fab:not(:disabled) .mdc-fab__icon, .mdc-fab:not(:disabled) .mdc-fab__label, .mdc-fab:disabled .mdc-fab__icon, .mdc-fab:disabled .mdc-fab__label {
  color: #fff;
  /* @alternate */
  color: var(--mdc-theme-on-secondary, #fff);
}

.mdc-fab:not(.mdc-fab--extended) {
  border-radius: 50%;
}

.mdc-fab:not(.mdc-fab--extended) .mdc-fab__ripple {
  border-radius: 50%;
}

.mdc-fab {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}

.mdc-fab .mdc-fab__ripple::before,
.mdc-fab .mdc-fab__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}

.mdc-fab .mdc-fab__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}

.mdc-fab .mdc-fab__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}

.mdc-fab.mdc-ripple-upgraded .mdc-fab__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-fab.mdc-ripple-upgraded .mdc-fab__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}

.mdc-fab.mdc-ripple-upgraded--unbounded .mdc-fab__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}

.mdc-fab.mdc-ripple-upgraded--foreground-activation .mdc-fab__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}

.mdc-fab.mdc-ripple-upgraded--foreground-deactivation .mdc-fab__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-fab .mdc-fab__ripple::before,
.mdc-fab .mdc-fab__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}

.mdc-fab.mdc-ripple-upgraded .mdc-fab__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-fab .mdc-fab__ripple::before, .mdc-fab .mdc-fab__ripple::after {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-on-secondary, #fff));
}

.mdc-fab:hover .mdc-fab__ripple::before, .mdc-fab.mdc-ripple-surface--hover .mdc-fab__ripple::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.08);
}

.mdc-fab.mdc-ripple-upgraded--background-focused .mdc-fab__ripple::before, .mdc-fab:not(.mdc-ripple-upgraded):focus .mdc-fab__ripple::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}

.mdc-fab:not(.mdc-ripple-upgraded) .mdc-fab__ripple::after {
  transition: opacity 150ms linear;
}

.mdc-fab:not(.mdc-ripple-upgraded):active .mdc-fab__ripple::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}

.mdc-fab.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.24);
}

.mdc-fab .mdc-fab__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
}

.mdc-fab {
  z-index: 0;
}

.mdc-fab .mdc-fab__ripple::before,
.mdc-fab .mdc-fab__ripple::after {
  z-index: -1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, -1);
}

.mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
}

.mdc-form-field > label {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-left: 4px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  order: 0;
}

[dir=rtl] .mdc-form-field > label, .mdc-form-field > label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: auto;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-form-field > label, .mdc-form-field > label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 4px;
  /*rtl:end:ignore*/
}

.mdc-form-field--nowrap > label {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.mdc-form-field--align-end > label {
  /* @noflip */ /*rtl:ignore*/
  margin-left: auto;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 4px;
  order: -1;
}

[dir=rtl] .mdc-form-field--align-end > label, .mdc-form-field--align-end > label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: auto;
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-form-field--align-end > label, .mdc-form-field--align-end > label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 4px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-form-field--space-between {
  justify-content: space-between;
}

.mdc-form-field--space-between > label {
  margin: 0;
}

[dir=rtl] .mdc-form-field--space-between > label, .mdc-form-field--space-between > label[dir=rtl] {
  /*rtl:begin:ignore*/
  margin: 0;
  /*rtl:end:ignore*/
}

.mdc-icon-button {
  font-size: 24px;
  width: 48px;
  height: 48px;
  padding: 12px;
}

.mdc-icon-button .mdc-icon-button__focus-ring {
  display: none;
}

.mdc-icon-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring, .mdc-icon-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring {
  display: block;
  max-height: 48px;
  max-width: 48px;
}

@media screen and (forced-colors: active) {
  .mdc-icon-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring, .mdc-icon-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring {
    pointer-events: none;
    border: 2px solid transparent;
    border-radius: 6px;
    box-sizing: content-box;
    position: absolute;
    top: 50%;
    /* @noflip */ /*rtl:ignore*/
    left: 50%;
    /* @noflip */ /*rtl:ignore*/
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
  }
}
@media screen and (forced-colors: active) and (forced-colors: active) {
  .mdc-icon-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring, .mdc-icon-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring {
    border-color: CanvasText;
  }
}
@media screen and (forced-colors: active) {
  .mdc-icon-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring::after, .mdc-icon-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring::after {
    content: "";
    border: 2px solid transparent;
    border-radius: 8px;
    display: block;
    position: absolute;
    top: 50%;
    /* @noflip */ /*rtl:ignore*/
    left: 50%;
    /* @noflip */ /*rtl:ignore*/
    transform: translate(-50%, -50%);
    height: calc(100% + 4px);
    width: calc(100% + 4px);
  }
}
@media screen and (forced-colors: active) and (forced-colors: active) {
  .mdc-icon-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring::after, .mdc-icon-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring::after {
    border-color: CanvasText;
  }
}
.mdc-icon-button.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 4px;
  margin-left: 4px;
}

.mdc-icon-button.mdc-icon-button--reduced-size.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring, .mdc-icon-button.mdc-icon-button--reduced-size:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}

.mdc-icon-button .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 48px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 48px;
  transform: translate(-50%, -50%);
}

.mdc-icon-button:disabled {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-disabled-on-light, rgba(0, 0, 0, 0.38));
}

.mdc-icon-button svg,
.mdc-icon-button img {
  width: 24px;
  height: 24px;
}

.mdc-icon-button {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  border: none;
  outline: none;
  background-color: transparent;
  fill: currentColor;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  z-index: 0;
  overflow: visible;
}

.mdc-icon-button .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 48px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 48px;
  transform: translate(-50%, -50%);
}

.mdc-icon-button:disabled {
  cursor: default;
  pointer-events: none;
}

.mdc-icon-button--display-flex {
  align-items: center;
  display: inline-flex;
  justify-content: center;
}

.mdc-icon-button__icon {
  display: inline-block;
}

.mdc-icon-button__icon.mdc-icon-button__icon--on {
  display: none;
}

.mdc-icon-button--on .mdc-icon-button__icon {
  display: none;
}

.mdc-icon-button--on .mdc-icon-button__icon.mdc-icon-button__icon--on {
  display: inline-block;
}

.mdc-icon-button__link {
  height: 100%;
  left: 0;
  outline: none;
  position: absolute;
  top: 0;
  width: 100%;
}

.mdc-icon-button {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}

.mdc-icon-button .mdc-icon-button__ripple::before,
.mdc-icon-button .mdc-icon-button__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}

.mdc-icon-button .mdc-icon-button__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}

.mdc-icon-button .mdc-icon-button__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}

.mdc-icon-button.mdc-ripple-upgraded .mdc-icon-button__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-icon-button.mdc-ripple-upgraded .mdc-icon-button__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}

.mdc-icon-button.mdc-ripple-upgraded--unbounded .mdc-icon-button__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}

.mdc-icon-button.mdc-ripple-upgraded--foreground-activation .mdc-icon-button__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}

.mdc-icon-button.mdc-ripple-upgraded--foreground-deactivation .mdc-icon-button__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-icon-button .mdc-icon-button__ripple::before,
.mdc-icon-button .mdc-icon-button__ripple::after {
  top: calc(50% - 50%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 50%);
  width: 100%;
  height: 100%;
}

.mdc-icon-button.mdc-ripple-upgraded .mdc-icon-button__ripple::before,
.mdc-icon-button.mdc-ripple-upgraded .mdc-icon-button__ripple::after {
  top: var(--mdc-ripple-top, calc(50% - 50%));
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, calc(50% - 50%));
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-icon-button.mdc-ripple-upgraded .mdc-icon-button__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-icon-button .mdc-icon-button__ripple::before, .mdc-icon-button .mdc-icon-button__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}

.mdc-icon-button:hover .mdc-icon-button__ripple::before, .mdc-icon-button.mdc-ripple-surface--hover .mdc-icon-button__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}

.mdc-icon-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__ripple::before, .mdc-icon-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}

.mdc-icon-button:not(.mdc-ripple-upgraded) .mdc-icon-button__ripple::after {
  transition: opacity 150ms linear;
}

.mdc-icon-button:not(.mdc-ripple-upgraded):active .mdc-icon-button__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-icon-button.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-icon-button .mdc-icon-button__ripple {
  height: 100%;
  left: 0px;
  pointer-events: none;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: -1;
}

.mdc-image-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 0;
}

.mdc-image-list__item,
.mdc-image-list__image-aspect-container {
  position: relative;
  box-sizing: border-box;
}

.mdc-image-list__item {
  list-style-type: none;
}

.mdc-image-list__image {
  width: 100%;
}

.mdc-image-list__image-aspect-container .mdc-image-list__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.mdc-image-list__image-aspect-container {
  padding-bottom: calc(100% / 1);
}

.mdc-image-list__image {
  border-radius: 0;
}

.mdc-image-list--with-text-protection .mdc-image-list__supporting {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.mdc-image-list__supporting {
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 8px 0;
  line-height: 24px;
}

.mdc-image-list__label {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.mdc-image-list--with-text-protection .mdc-image-list__supporting {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 48px;
  padding: 0 16px;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
}

.mdc-image-list--masonry {
  display: block;
}

.mdc-image-list--masonry .mdc-image-list__item {
  break-inside: avoid-column;
}

.mdc-image-list--masonry .mdc-image-list__image {
  display: block;
  height: auto;
}

:root {
  --mdc-layout-grid-margin-desktop: 24px;
  --mdc-layout-grid-gutter-desktop: 24px;
  --mdc-layout-grid-column-width-desktop: 72px;
  --mdc-layout-grid-margin-tablet: 16px;
  --mdc-layout-grid-gutter-tablet: 16px;
  --mdc-layout-grid-column-width-tablet: 72px;
  --mdc-layout-grid-margin-phone: 16px;
  --mdc-layout-grid-gutter-phone: 16px;
  --mdc-layout-grid-column-width-phone: 72px;
}

@media (min-width: 840px) {
  .mdc-layout-grid {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 24px;
    padding: var(--mdc-layout-grid-margin-desktop, 24px);
  }
}
@media (min-width: 600px) and (max-width: 839px) {
  .mdc-layout-grid {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 16px;
    padding: var(--mdc-layout-grid-margin-tablet, 16px);
  }
}
@media (max-width: 599px) {
  .mdc-layout-grid {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 16px;
    padding: var(--mdc-layout-grid-margin-phone, 16px);
  }
}
@media (min-width: 840px) {
  .mdc-layout-grid__inner {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    margin: -12px;
    margin: calc(var(--mdc-layout-grid-gutter-desktop, 24px) / 2 * -1);
  }
  @supports (display: grid) {
    .mdc-layout-grid__inner {
      display: grid;
      margin: 0;
      grid-gap: 24px;
      grid-gap: var(--mdc-layout-grid-gutter-desktop, 24px);
      grid-template-columns: repeat(12, minmax(0, 1fr));
    }
  }
}
@media (min-width: 600px) and (max-width: 839px) {
  .mdc-layout-grid__inner {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    margin: -8px;
    margin: calc(var(--mdc-layout-grid-gutter-tablet, 16px) / 2 * -1);
  }
  @supports (display: grid) {
    .mdc-layout-grid__inner {
      display: grid;
      margin: 0;
      grid-gap: 16px;
      grid-gap: var(--mdc-layout-grid-gutter-tablet, 16px);
      grid-template-columns: repeat(8, minmax(0, 1fr));
    }
  }
}
@media (max-width: 599px) {
  .mdc-layout-grid__inner {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    margin: -8px;
    margin: calc(var(--mdc-layout-grid-gutter-phone, 16px) / 2 * -1);
  }
  @supports (display: grid) {
    .mdc-layout-grid__inner {
      display: grid;
      margin: 0;
      grid-gap: 16px;
      grid-gap: var(--mdc-layout-grid-gutter-phone, 16px);
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }
}
@media (min-width: 840px) {
  .mdc-layout-grid__cell {
    width: calc(33.3333333333% - 24px);
    width: calc(33.3333333333% - var(--mdc-layout-grid-gutter-desktop, 24px));
    box-sizing: border-box;
    margin: 12px;
    margin: calc(var(--mdc-layout-grid-gutter-desktop, 24px) / 2);
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell {
      width: auto;
      grid-column-end: span 4;
    }
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell {
      margin: 0;
    }
  }
  .mdc-layout-grid__cell--span-1,
  .mdc-layout-grid__cell--span-1-desktop {
    width: calc(8.3333333333% - 24px);
    width: calc(8.3333333333% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-1,
    .mdc-layout-grid__cell--span-1-desktop {
      width: auto;
      grid-column-end: span 1;
    }
  }
  .mdc-layout-grid__cell--span-2,
  .mdc-layout-grid__cell--span-2-desktop {
    width: calc(16.6666666667% - 24px);
    width: calc(16.6666666667% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-2,
    .mdc-layout-grid__cell--span-2-desktop {
      width: auto;
      grid-column-end: span 2;
    }
  }
  .mdc-layout-grid__cell--span-3,
  .mdc-layout-grid__cell--span-3-desktop {
    width: calc(25% - 24px);
    width: calc(25% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-3,
    .mdc-layout-grid__cell--span-3-desktop {
      width: auto;
      grid-column-end: span 3;
    }
  }
  .mdc-layout-grid__cell--span-4,
  .mdc-layout-grid__cell--span-4-desktop {
    width: calc(33.3333333333% - 24px);
    width: calc(33.3333333333% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-4,
    .mdc-layout-grid__cell--span-4-desktop {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-5,
  .mdc-layout-grid__cell--span-5-desktop {
    width: calc(41.6666666667% - 24px);
    width: calc(41.6666666667% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-5,
    .mdc-layout-grid__cell--span-5-desktop {
      width: auto;
      grid-column-end: span 5;
    }
  }
  .mdc-layout-grid__cell--span-6,
  .mdc-layout-grid__cell--span-6-desktop {
    width: calc(50% - 24px);
    width: calc(50% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-6,
    .mdc-layout-grid__cell--span-6-desktop {
      width: auto;
      grid-column-end: span 6;
    }
  }
  .mdc-layout-grid__cell--span-7,
  .mdc-layout-grid__cell--span-7-desktop {
    width: calc(58.3333333333% - 24px);
    width: calc(58.3333333333% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-7,
    .mdc-layout-grid__cell--span-7-desktop {
      width: auto;
      grid-column-end: span 7;
    }
  }
  .mdc-layout-grid__cell--span-8,
  .mdc-layout-grid__cell--span-8-desktop {
    width: calc(66.6666666667% - 24px);
    width: calc(66.6666666667% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-8,
    .mdc-layout-grid__cell--span-8-desktop {
      width: auto;
      grid-column-end: span 8;
    }
  }
  .mdc-layout-grid__cell--span-9,
  .mdc-layout-grid__cell--span-9-desktop {
    width: calc(75% - 24px);
    width: calc(75% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-9,
    .mdc-layout-grid__cell--span-9-desktop {
      width: auto;
      grid-column-end: span 9;
    }
  }
  .mdc-layout-grid__cell--span-10,
  .mdc-layout-grid__cell--span-10-desktop {
    width: calc(83.3333333333% - 24px);
    width: calc(83.3333333333% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-10,
    .mdc-layout-grid__cell--span-10-desktop {
      width: auto;
      grid-column-end: span 10;
    }
  }
  .mdc-layout-grid__cell--span-11,
  .mdc-layout-grid__cell--span-11-desktop {
    width: calc(91.6666666667% - 24px);
    width: calc(91.6666666667% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-11,
    .mdc-layout-grid__cell--span-11-desktop {
      width: auto;
      grid-column-end: span 11;
    }
  }
  .mdc-layout-grid__cell--span-12,
  .mdc-layout-grid__cell--span-12-desktop {
    width: calc(100% - 24px);
    width: calc(100% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-12,
    .mdc-layout-grid__cell--span-12-desktop {
      width: auto;
      grid-column-end: span 12;
    }
  }
}
@media (min-width: 600px) and (max-width: 839px) {
  .mdc-layout-grid__cell {
    width: calc(50% - 16px);
    width: calc(50% - var(--mdc-layout-grid-gutter-tablet, 16px));
    box-sizing: border-box;
    margin: 8px;
    margin: calc(var(--mdc-layout-grid-gutter-tablet, 16px) / 2);
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell {
      width: auto;
      grid-column-end: span 4;
    }
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell {
      margin: 0;
    }
  }
  .mdc-layout-grid__cell--span-1,
  .mdc-layout-grid__cell--span-1-tablet {
    width: calc(12.5% - 16px);
    width: calc(12.5% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-1,
    .mdc-layout-grid__cell--span-1-tablet {
      width: auto;
      grid-column-end: span 1;
    }
  }
  .mdc-layout-grid__cell--span-2,
  .mdc-layout-grid__cell--span-2-tablet {
    width: calc(25% - 16px);
    width: calc(25% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-2,
    .mdc-layout-grid__cell--span-2-tablet {
      width: auto;
      grid-column-end: span 2;
    }
  }
  .mdc-layout-grid__cell--span-3,
  .mdc-layout-grid__cell--span-3-tablet {
    width: calc(37.5% - 16px);
    width: calc(37.5% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-3,
    .mdc-layout-grid__cell--span-3-tablet {
      width: auto;
      grid-column-end: span 3;
    }
  }
  .mdc-layout-grid__cell--span-4,
  .mdc-layout-grid__cell--span-4-tablet {
    width: calc(50% - 16px);
    width: calc(50% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-4,
    .mdc-layout-grid__cell--span-4-tablet {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-5,
  .mdc-layout-grid__cell--span-5-tablet {
    width: calc(62.5% - 16px);
    width: calc(62.5% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-5,
    .mdc-layout-grid__cell--span-5-tablet {
      width: auto;
      grid-column-end: span 5;
    }
  }
  .mdc-layout-grid__cell--span-6,
  .mdc-layout-grid__cell--span-6-tablet {
    width: calc(75% - 16px);
    width: calc(75% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-6,
    .mdc-layout-grid__cell--span-6-tablet {
      width: auto;
      grid-column-end: span 6;
    }
  }
  .mdc-layout-grid__cell--span-7,
  .mdc-layout-grid__cell--span-7-tablet {
    width: calc(87.5% - 16px);
    width: calc(87.5% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-7,
    .mdc-layout-grid__cell--span-7-tablet {
      width: auto;
      grid-column-end: span 7;
    }
  }
  .mdc-layout-grid__cell--span-8,
  .mdc-layout-grid__cell--span-8-tablet {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-8,
    .mdc-layout-grid__cell--span-8-tablet {
      width: auto;
      grid-column-end: span 8;
    }
  }
  .mdc-layout-grid__cell--span-9,
  .mdc-layout-grid__cell--span-9-tablet {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-9,
    .mdc-layout-grid__cell--span-9-tablet {
      width: auto;
      grid-column-end: span 8;
    }
  }
  .mdc-layout-grid__cell--span-10,
  .mdc-layout-grid__cell--span-10-tablet {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-10,
    .mdc-layout-grid__cell--span-10-tablet {
      width: auto;
      grid-column-end: span 8;
    }
  }
  .mdc-layout-grid__cell--span-11,
  .mdc-layout-grid__cell--span-11-tablet {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-11,
    .mdc-layout-grid__cell--span-11-tablet {
      width: auto;
      grid-column-end: span 8;
    }
  }
  .mdc-layout-grid__cell--span-12,
  .mdc-layout-grid__cell--span-12-tablet {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-12,
    .mdc-layout-grid__cell--span-12-tablet {
      width: auto;
      grid-column-end: span 8;
    }
  }
}
@media (max-width: 599px) {
  .mdc-layout-grid__cell {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
    box-sizing: border-box;
    margin: 8px;
    margin: calc(var(--mdc-layout-grid-gutter-phone, 16px) / 2);
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell {
      width: auto;
      grid-column-end: span 4;
    }
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell {
      margin: 0;
    }
  }
  .mdc-layout-grid__cell--span-1,
  .mdc-layout-grid__cell--span-1-phone {
    width: calc(25% - 16px);
    width: calc(25% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-1,
    .mdc-layout-grid__cell--span-1-phone {
      width: auto;
      grid-column-end: span 1;
    }
  }
  .mdc-layout-grid__cell--span-2,
  .mdc-layout-grid__cell--span-2-phone {
    width: calc(50% - 16px);
    width: calc(50% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-2,
    .mdc-layout-grid__cell--span-2-phone {
      width: auto;
      grid-column-end: span 2;
    }
  }
  .mdc-layout-grid__cell--span-3,
  .mdc-layout-grid__cell--span-3-phone {
    width: calc(75% - 16px);
    width: calc(75% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-3,
    .mdc-layout-grid__cell--span-3-phone {
      width: auto;
      grid-column-end: span 3;
    }
  }
  .mdc-layout-grid__cell--span-4,
  .mdc-layout-grid__cell--span-4-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-4,
    .mdc-layout-grid__cell--span-4-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-5,
  .mdc-layout-grid__cell--span-5-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-5,
    .mdc-layout-grid__cell--span-5-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-6,
  .mdc-layout-grid__cell--span-6-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-6,
    .mdc-layout-grid__cell--span-6-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-7,
  .mdc-layout-grid__cell--span-7-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-7,
    .mdc-layout-grid__cell--span-7-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-8,
  .mdc-layout-grid__cell--span-8-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-8,
    .mdc-layout-grid__cell--span-8-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-9,
  .mdc-layout-grid__cell--span-9-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-9,
    .mdc-layout-grid__cell--span-9-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-10,
  .mdc-layout-grid__cell--span-10-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-10,
    .mdc-layout-grid__cell--span-10-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-11,
  .mdc-layout-grid__cell--span-11-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-11,
    .mdc-layout-grid__cell--span-11-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-12,
  .mdc-layout-grid__cell--span-12-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-12,
    .mdc-layout-grid__cell--span-12-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
}
.mdc-layout-grid__cell--order-1 {
  order: 1;
}

.mdc-layout-grid__cell--order-2 {
  order: 2;
}

.mdc-layout-grid__cell--order-3 {
  order: 3;
}

.mdc-layout-grid__cell--order-4 {
  order: 4;
}

.mdc-layout-grid__cell--order-5 {
  order: 5;
}

.mdc-layout-grid__cell--order-6 {
  order: 6;
}

.mdc-layout-grid__cell--order-7 {
  order: 7;
}

.mdc-layout-grid__cell--order-8 {
  order: 8;
}

.mdc-layout-grid__cell--order-9 {
  order: 9;
}

.mdc-layout-grid__cell--order-10 {
  order: 10;
}

.mdc-layout-grid__cell--order-11 {
  order: 11;
}

.mdc-layout-grid__cell--order-12 {
  order: 12;
}

.mdc-layout-grid__cell--align-top {
  align-self: flex-start;
}

@supports (display: grid) {
  .mdc-layout-grid__cell--align-top {
    align-self: start;
  }
}
.mdc-layout-grid__cell--align-middle {
  align-self: center;
}

.mdc-layout-grid__cell--align-bottom {
  align-self: flex-end;
}

@supports (display: grid) {
  .mdc-layout-grid__cell--align-bottom {
    align-self: end;
  }
}
@media (min-width: 840px) {
  .mdc-layout-grid--fixed-column-width {
    width: 1176px;
    width: calc(
    var(--mdc-layout-grid-column-width-desktop, 72px) * 12 +
      var(--mdc-layout-grid-gutter-desktop, 24px) * 11 +
      var(--mdc-layout-grid-margin-desktop, 24px) * 2
  );
  }
}
@media (min-width: 600px) and (max-width: 839px) {
  .mdc-layout-grid--fixed-column-width {
    width: 720px;
    width: calc(
    var(--mdc-layout-grid-column-width-tablet, 72px) * 8 +
      var(--mdc-layout-grid-gutter-tablet, 16px) * 7 +
      var(--mdc-layout-grid-margin-tablet, 16px) * 2
  );
  }
}
@media (max-width: 599px) {
  .mdc-layout-grid--fixed-column-width {
    width: 368px;
    width: calc(
    var(--mdc-layout-grid-column-width-phone, 72px) * 4 +
      var(--mdc-layout-grid-gutter-phone, 16px) * 3 +
      var(--mdc-layout-grid-margin-phone, 16px) * 2
  );
  }
}
.mdc-layout-grid--align-left {
  margin-right: auto;
  margin-left: 0;
}

.mdc-layout-grid--align-right {
  margin-right: 0;
  margin-left: auto;
}

@keyframes mdc-linear-progress-primary-indeterminate-translate {
  0% {
    transform: translateX(0);
  }
  20% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(0);
  }
  59.15% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(83.67142%);
    /* @alternate */
    transform: translateX(var(--mdc-linear-progress-primary-half, 83.67142%));
  }
  100% {
    transform: translateX(200.611057%);
    /* @alternate */
    transform: translateX(var(--mdc-linear-progress-primary-full, 200.611057%));
  }
}
@keyframes mdc-linear-progress-primary-indeterminate-scale {
  0% {
    transform: scaleX(0.08);
  }
  36.65% {
    animation-timing-function: cubic-bezier(0.334731, 0.12482, 0.785844, 1);
    transform: scaleX(0.08);
  }
  69.15% {
    animation-timing-function: cubic-bezier(0.06, 0.11, 0.6, 1);
    transform: scaleX(0.661479);
  }
  100% {
    transform: scaleX(0.08);
  }
}
@keyframes mdc-linear-progress-secondary-indeterminate-translate {
  0% {
    animation-timing-function: cubic-bezier(0.15, 0, 0.515058, 0.409685);
    transform: translateX(0);
  }
  25% {
    animation-timing-function: cubic-bezier(0.31033, 0.284058, 0.8, 0.733712);
    transform: translateX(37.651913%);
    /* @alternate */
    transform: translateX(var(--mdc-linear-progress-secondary-quarter, 37.651913%));
  }
  48.35% {
    animation-timing-function: cubic-bezier(0.4, 0.627035, 0.6, 0.902026);
    transform: translateX(84.386165%);
    /* @alternate */
    transform: translateX(var(--mdc-linear-progress-secondary-half, 84.386165%));
  }
  100% {
    transform: translateX(160.277782%);
    /* @alternate */
    transform: translateX(var(--mdc-linear-progress-secondary-full, 160.277782%));
  }
}
@keyframes mdc-linear-progress-secondary-indeterminate-scale {
  0% {
    animation-timing-function: cubic-bezier(0.205028, 0.057051, 0.57661, 0.453971);
    transform: scaleX(0.08);
  }
  19.15% {
    animation-timing-function: cubic-bezier(0.152313, 0.196432, 0.648374, 1.004315);
    transform: scaleX(0.457104);
  }
  44.15% {
    animation-timing-function: cubic-bezier(0.257759, -0.003163, 0.211762, 1.38179);
    transform: scaleX(0.72796);
  }
  100% {
    transform: scaleX(0.08);
  }
}
@keyframes mdc-linear-progress-buffering {
  from {
    transform: rotate(180deg) translateX(-10px);
  }
}
@keyframes mdc-linear-progress-primary-indeterminate-translate-reverse {
  0% {
    transform: translateX(0);
  }
  20% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(0);
  }
  59.15% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(-83.67142%);
    /* @alternate */
    transform: translateX(var(--mdc-linear-progress-primary-half-neg, -83.67142%));
  }
  100% {
    transform: translateX(-200.611057%);
    /* @alternate */
    transform: translateX(var(--mdc-linear-progress-primary-full-neg, -200.611057%));
  }
}
@keyframes mdc-linear-progress-secondary-indeterminate-translate-reverse {
  0% {
    animation-timing-function: cubic-bezier(0.15, 0, 0.515058, 0.409685);
    transform: translateX(0);
  }
  25% {
    animation-timing-function: cubic-bezier(0.31033, 0.284058, 0.8, 0.733712);
    transform: translateX(-37.651913%);
    /* @alternate */
    transform: translateX(var(--mdc-linear-progress-secondary-quarter-neg, -37.651913%));
  }
  48.35% {
    animation-timing-function: cubic-bezier(0.4, 0.627035, 0.6, 0.902026);
    transform: translateX(-84.386165%);
    /* @alternate */
    transform: translateX(var(--mdc-linear-progress-secondary-half-neg, -84.386165%));
  }
  100% {
    transform: translateX(-160.277782%);
    /* @alternate */
    transform: translateX(var(--mdc-linear-progress-secondary-full-neg, -160.277782%));
  }
}
@keyframes mdc-linear-progress-buffering-reverse {
  from {
    transform: translateX(-10px);
  }
}
.mdc-linear-progress {
  position: relative;
  width: 100%;
  transform: translateZ(0);
  outline: 1px solid transparent;
  overflow: hidden;
  transition: opacity 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}

@media screen and (forced-colors: active) {
  .mdc-linear-progress {
    outline-color: CanvasText;
  }
}
.mdc-linear-progress__bar {
  position: absolute;
  width: 100%;
  height: 100%;
  animation: none;
  transform-origin: top left;
  transition: transform 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}

.mdc-linear-progress__bar-inner {
  display: inline-block;
  position: absolute;
  width: 100%;
  animation: none;
  border-top-style: solid;
}

.mdc-linear-progress__buffer {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
}

.mdc-linear-progress__buffer-dots {
  background-repeat: repeat-x;
  flex: auto;
  transform: rotate(180deg);
  animation: mdc-linear-progress-buffering 250ms infinite linear;
}

.mdc-linear-progress__buffer-bar {
  flex: 0 1 100%;
  transition: flex-basis 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}

.mdc-linear-progress__primary-bar {
  transform: scaleX(0);
}

.mdc-linear-progress__secondary-bar {
  display: none;
}

.mdc-linear-progress--indeterminate .mdc-linear-progress__bar {
  transition: none;
}

.mdc-linear-progress--indeterminate .mdc-linear-progress__primary-bar {
  left: -145.166611%;
}

.mdc-linear-progress--indeterminate .mdc-linear-progress__secondary-bar {
  left: -54.888891%;
  display: block;
}

.mdc-linear-progress--indeterminate.mdc-linear-progress--animation-ready .mdc-linear-progress__primary-bar {
  animation: mdc-linear-progress-primary-indeterminate-translate 2s infinite linear;
}

.mdc-linear-progress--indeterminate.mdc-linear-progress--animation-ready .mdc-linear-progress__primary-bar > .mdc-linear-progress__bar-inner {
  animation: mdc-linear-progress-primary-indeterminate-scale 2s infinite linear;
}

.mdc-linear-progress--indeterminate.mdc-linear-progress--animation-ready .mdc-linear-progress__secondary-bar {
  animation: mdc-linear-progress-secondary-indeterminate-translate 2s infinite linear;
}

.mdc-linear-progress--indeterminate.mdc-linear-progress--animation-ready .mdc-linear-progress__secondary-bar > .mdc-linear-progress__bar-inner {
  animation: mdc-linear-progress-secondary-indeterminate-scale 2s infinite linear;
}

[dir=rtl] .mdc-linear-progress, .mdc-linear-progress[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-linear-progress:not([dir=ltr]) .mdc-linear-progress__bar, .mdc-linear-progress[dir=rtl]:not([dir=ltr]) .mdc-linear-progress__bar {
  /* @noflip */ /*rtl:ignore*/
  right: 0;
  /* @noflip */ /*rtl:ignore*/
  -webkit-transform-origin: center right;
  /* @noflip */ /*rtl:ignore*/
  transform-origin: center right;
}

[dir=rtl] .mdc-linear-progress:not([dir=ltr]).mdc-linear-progress--animation-ready .mdc-linear-progress__primary-bar, .mdc-linear-progress[dir=rtl]:not([dir=ltr]).mdc-linear-progress--animation-ready .mdc-linear-progress__primary-bar {
  animation-name: mdc-linear-progress-primary-indeterminate-translate-reverse;
}

[dir=rtl] .mdc-linear-progress:not([dir=ltr]).mdc-linear-progress--animation-ready .mdc-linear-progress__secondary-bar, .mdc-linear-progress[dir=rtl]:not([dir=ltr]).mdc-linear-progress--animation-ready .mdc-linear-progress__secondary-bar {
  animation-name: mdc-linear-progress-secondary-indeterminate-translate-reverse;
}

[dir=rtl] .mdc-linear-progress:not([dir=ltr]) .mdc-linear-progress__buffer-dots, .mdc-linear-progress[dir=rtl]:not([dir=ltr]) .mdc-linear-progress__buffer-dots {
  animation: mdc-linear-progress-buffering-reverse 250ms infinite linear;
  transform: rotate(0);
}

[dir=rtl] .mdc-linear-progress:not([dir=ltr]).mdc-linear-progress--indeterminate .mdc-linear-progress__primary-bar, .mdc-linear-progress[dir=rtl]:not([dir=ltr]).mdc-linear-progress--indeterminate .mdc-linear-progress__primary-bar {
  /* @noflip */ /*rtl:ignore*/
  right: -145.166611%;
  /* @noflip */ /*rtl:ignore*/
  left: auto;
}

[dir=rtl] .mdc-linear-progress:not([dir=ltr]).mdc-linear-progress--indeterminate .mdc-linear-progress__secondary-bar, .mdc-linear-progress[dir=rtl]:not([dir=ltr]).mdc-linear-progress--indeterminate .mdc-linear-progress__secondary-bar {
  /* @noflip */ /*rtl:ignore*/
  right: -54.888891%;
  /* @noflip */ /*rtl:ignore*/
  left: auto;
}

.mdc-linear-progress--closed {
  opacity: 0;
}

.mdc-linear-progress--closed-animation-off .mdc-linear-progress__buffer-dots {
  animation: none;
}

.mdc-linear-progress--closed-animation-off.mdc-linear-progress--indeterminate .mdc-linear-progress__bar,
.mdc-linear-progress--closed-animation-off.mdc-linear-progress--indeterminate .mdc-linear-progress__bar .mdc-linear-progress__bar-inner {
  animation: none;
}

.mdc-linear-progress__bar-inner {
  border-color: #6200ee;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #6200ee);
}

.mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='%23e6e6e6'/%3E%3C/svg%3E");
}

.mdc-linear-progress__buffer-bar {
  background-color: #e6e6e6;
}

.mdc-linear-progress {
  height: 4px;
}

.mdc-linear-progress__bar-inner {
  border-top-width: 4px;
}

.mdc-linear-progress__buffer-dots {
  background-size: 10px 4px;
}

.mdc-deprecated-list {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  /* @alternate */
  line-height: 1.5rem;
  margin: 0;
  padding: 8px 0;
  list-style-type: none;
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-deprecated-list:focus {
  outline: none;
}

.mdc-deprecated-list-item {
  height: 48px;
}

.mdc-deprecated-list-item__secondary-text {
  color: rgba(0, 0, 0, 0.54);
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}

.mdc-deprecated-list-item__graphic {
  background-color: transparent;
}

.mdc-deprecated-list-item__graphic {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-deprecated-list-item__meta {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-deprecated-list-group__subheader {
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__text {
  opacity: 0.38;
}

.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__text,
.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__primary-text,
.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__secondary-text {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-deprecated-list-item--selected,
.mdc-deprecated-list-item--activated {
  color: #6200ee;
  /* @alternate */
  color: var(--mdc-theme-primary, #6200ee);
}

.mdc-deprecated-list-item--selected .mdc-deprecated-list-item__graphic,
.mdc-deprecated-list-item--activated .mdc-deprecated-list-item__graphic {
  color: #6200ee;
  /* @alternate */
  color: var(--mdc-theme-primary, #6200ee);
}

.mdc-deprecated-list--dense {
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 0.812rem;
}

.mdc-deprecated-list-item__wrapper {
  display: block;
}

.mdc-deprecated-list-item {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  padding: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  height: 48px;
}

.mdc-deprecated-list-item:focus {
  outline: none;
}

.mdc-deprecated-list-item:not(.mdc-deprecated-list-item--selected):focus::before, .mdc-deprecated-list-item.mdc-ripple-upgraded--background-focused::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 1px solid transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}

@media screen and (forced-colors: active) {
  .mdc-deprecated-list-item:not(.mdc-deprecated-list-item--selected):focus::before, .mdc-deprecated-list-item.mdc-ripple-upgraded--background-focused::before {
    border-color: CanvasText;
  }
}
.mdc-deprecated-list-item.mdc-deprecated-list-item--selected::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 3px double transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}

@media screen and (forced-colors: active) {
  .mdc-deprecated-list-item.mdc-deprecated-list-item--selected::before {
    border-color: CanvasText;
  }
}
[dir=rtl] .mdc-deprecated-list-item, .mdc-deprecated-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--icon-list .mdc-deprecated-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  height: 56px;
}

[dir=rtl] .mdc-deprecated-list--icon-list .mdc-deprecated-list-item, .mdc-deprecated-list--icon-list .mdc-deprecated-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--avatar-list .mdc-deprecated-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  height: 56px;
}

[dir=rtl] .mdc-deprecated-list--avatar-list .mdc-deprecated-list-item, .mdc-deprecated-list--avatar-list .mdc-deprecated-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  height: 56px;
}

[dir=rtl] .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-item, .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--image-list .mdc-deprecated-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  height: 72px;
}

[dir=rtl] .mdc-deprecated-list--image-list .mdc-deprecated-list-item, .mdc-deprecated-list--image-list .mdc-deprecated-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--video-list .mdc-deprecated-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  height: 72px;
}

[dir=rtl] .mdc-deprecated-list--video-list .mdc-deprecated-list-item, .mdc-deprecated-list--video-list .mdc-deprecated-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--dense .mdc-deprecated-list-item__graphic {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  width: 20px;
  height: 20px;
}

[dir=rtl] .mdc-deprecated-list--dense .mdc-deprecated-list-item__graphic, .mdc-deprecated-list--dense .mdc-deprecated-list-item__graphic[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list-item__graphic {
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  fill: currentColor;
  object-fit: cover;
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 32px;
  width: 24px;
  height: 24px;
}

[dir=rtl] .mdc-deprecated-list-item__graphic, .mdc-deprecated-list-item__graphic[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 32px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--icon-list .mdc-deprecated-list-item__graphic {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 32px;
  width: 24px;
  height: 24px;
}

[dir=rtl] .mdc-deprecated-list--icon-list .mdc-deprecated-list-item__graphic, .mdc-deprecated-list--icon-list .mdc-deprecated-list-item__graphic[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 32px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--avatar-list .mdc-deprecated-list-item__graphic {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

[dir=rtl] .mdc-deprecated-list--avatar-list .mdc-deprecated-list-item__graphic, .mdc-deprecated-list--avatar-list .mdc-deprecated-list-item__graphic[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-item__graphic {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  width: 40px;
  height: 40px;
}

[dir=rtl] .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-item__graphic, .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-item__graphic[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--image-list .mdc-deprecated-list-item__graphic {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  width: 56px;
  height: 56px;
}

[dir=rtl] .mdc-deprecated-list--image-list .mdc-deprecated-list-item__graphic, .mdc-deprecated-list--image-list .mdc-deprecated-list-item__graphic[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--video-list .mdc-deprecated-list-item__graphic {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  width: 100px;
  height: 56px;
}

[dir=rtl] .mdc-deprecated-list--video-list .mdc-deprecated-list-item__graphic, .mdc-deprecated-list--video-list .mdc-deprecated-list-item__graphic[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list .mdc-deprecated-list-item__graphic {
  display: inline-flex;
}

.mdc-deprecated-list-item__meta {
  /* @noflip */ /*rtl:ignore*/
  margin-left: auto;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
}

.mdc-deprecated-list-item__meta:not(.material-icons) {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 0.75rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: 0.0333333333em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
}

.mdc-deprecated-list-item[dir=rtl] .mdc-deprecated-list-item__meta, [dir=rtl] .mdc-deprecated-list-item .mdc-deprecated-list-item__meta {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: auto;
}

.mdc-deprecated-list-item__text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.mdc-deprecated-list-item__text[for] {
  pointer-events: none;
}

.mdc-deprecated-list-item__primary-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}

.mdc-deprecated-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}

.mdc-deprecated-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-deprecated-list--video-list .mdc-deprecated-list-item__primary-text, .mdc-deprecated-list--image-list .mdc-deprecated-list-item__primary-text, .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-item__primary-text, .mdc-deprecated-list--avatar-list .mdc-deprecated-list-item__primary-text, .mdc-deprecated-list--icon-list .mdc-deprecated-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}

.mdc-deprecated-list--video-list .mdc-deprecated-list-item__primary-text::before, .mdc-deprecated-list--image-list .mdc-deprecated-list-item__primary-text::before, .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-item__primary-text::before, .mdc-deprecated-list--avatar-list .mdc-deprecated-list-item__primary-text::before, .mdc-deprecated-list--icon-list .mdc-deprecated-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}

.mdc-deprecated-list--video-list .mdc-deprecated-list-item__primary-text::after, .mdc-deprecated-list--image-list .mdc-deprecated-list-item__primary-text::after, .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-item__primary-text::after, .mdc-deprecated-list--avatar-list .mdc-deprecated-list-item__primary-text::after, .mdc-deprecated-list--icon-list .mdc-deprecated-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-deprecated-list--dense .mdc-deprecated-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}

.mdc-deprecated-list--dense .mdc-deprecated-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 24px;
  content: "";
  vertical-align: 0;
}

.mdc-deprecated-list--dense .mdc-deprecated-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-deprecated-list-item__secondary-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}

.mdc-deprecated-list-item__secondary-text::before {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: 0;
}

.mdc-deprecated-list--dense .mdc-deprecated-list-item__secondary-text {
  font-size: inherit;
}

.mdc-deprecated-list--dense .mdc-deprecated-list-item {
  height: 40px;
}

.mdc-deprecated-list--two-line .mdc-deprecated-list-item__text {
  align-self: flex-start;
}

.mdc-deprecated-list--two-line .mdc-deprecated-list-item {
  height: 64px;
}

.mdc-deprecated-list--two-line.mdc-deprecated-list--video-list .mdc-deprecated-list-item, .mdc-deprecated-list--two-line.mdc-deprecated-list--image-list .mdc-deprecated-list-item, .mdc-deprecated-list--two-line.mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-item, .mdc-deprecated-list--two-line.mdc-deprecated-list--avatar-list .mdc-deprecated-list-item, .mdc-deprecated-list--two-line.mdc-deprecated-list--icon-list .mdc-deprecated-list-item {
  height: 72px;
}

.mdc-deprecated-list--two-line.mdc-deprecated-list--icon-list .mdc-deprecated-list-item__graphic {
  align-self: flex-start;
  margin-top: 16px;
}

.mdc-deprecated-list--two-line.mdc-deprecated-list--dense .mdc-deprecated-list-item,
.mdc-deprecated-list--avatar-list.mdc-deprecated-list--dense .mdc-deprecated-list-item {
  height: 60px;
}

.mdc-deprecated-list--avatar-list.mdc-deprecated-list--dense .mdc-deprecated-list-item__graphic {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  width: 36px;
  height: 36px;
}

[dir=rtl] .mdc-deprecated-list--avatar-list.mdc-deprecated-list--dense .mdc-deprecated-list-item__graphic, .mdc-deprecated-list--avatar-list.mdc-deprecated-list--dense .mdc-deprecated-list-item__graphic[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item {
  cursor: pointer;
}

a.mdc-deprecated-list-item {
  color: inherit;
  text-decoration: none;
}

.mdc-deprecated-list-divider {
  height: 0;
  margin: 0;
  border: none;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.mdc-deprecated-list-divider {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mdc-deprecated-list-divider--padded {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 32px);
}

[dir=rtl] .mdc-deprecated-list-divider--padded, .mdc-deprecated-list-divider--padded[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list-divider--inset {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 72px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 72px);
}

[dir=rtl] .mdc-deprecated-list-divider--inset, .mdc-deprecated-list-divider--inset[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 72px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list-divider--inset.mdc-deprecated-list-divider--padded {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 72px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 88px);
}

[dir=rtl] .mdc-deprecated-list-divider--inset.mdc-deprecated-list-divider--padded, .mdc-deprecated-list-divider--inset.mdc-deprecated-list-divider--padded[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 72px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 16px);
}

[dir=rtl] .mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading, .mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list .mdc-deprecated-list-divider--inset-trailing {
  width: calc(100% - 16px);
}

.mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 32px);
}

[dir=rtl] .mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing, .mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 16px);
}

[dir=rtl] .mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding, .mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 32px);
}

[dir=rtl] .mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding, .mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 72px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 72px);
}

[dir=rtl] .mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading, .mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 72px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-trailing {
  width: calc(100% - 16px);
}

.mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 72px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 88px);
}

[dir=rtl] .mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing, .mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 72px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 16px);
}

[dir=rtl] .mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding, .mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 32px);
}

[dir=rtl] .mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding, .mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 72px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 72px);
}

[dir=rtl] .mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading, .mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 72px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-trailing {
  width: calc(100% - 16px);
}

.mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 72px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 88px);
}

[dir=rtl] .mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing, .mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 72px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 16px);
}

[dir=rtl] .mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding, .mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 32px);
}

[dir=rtl] .mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding, .mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 72px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 72px);
}

[dir=rtl] .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading, .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 72px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-trailing {
  width: calc(100% - 16px);
}

.mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 72px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 88px);
}

[dir=rtl] .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing, .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 72px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 16px);
}

[dir=rtl] .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding, .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 32px);
}

[dir=rtl] .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding, .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 88px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 88px);
}

[dir=rtl] .mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading, .mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 88px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-trailing {
  width: calc(100% - 16px);
}

.mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 88px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 104px);
}

[dir=rtl] .mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing, .mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 88px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 16px);
}

[dir=rtl] .mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding, .mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 32px);
}

[dir=rtl] .mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding, .mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 116px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 116px);
}

[dir=rtl] .mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading, .mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 116px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-trailing {
  width: calc(100% - 16px);
}

.mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 116px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 132px);
}

[dir=rtl] .mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing, .mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 116px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 0px);
}

[dir=rtl] .mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding, .mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 16px);
}

[dir=rtl] .mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding, .mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list-group .mdc-deprecated-list {
  padding: 0;
}

.mdc-deprecated-list-group__subheader {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  margin: calc((3rem - 1.5rem) / 2) 16px;
}

.mdc-list-item__primary-text {
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-item__secondary-text {
  color: rgba(0, 0, 0, 0.54);
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}

.mdc-list-item__overline-text {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item__end {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--disabled .mdc-list-item__start,
.mdc-list-item--disabled .mdc-list-item__content,
.mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}

.mdc-list-item--disabled .mdc-list-item__primary-text {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-list-item--disabled .mdc-list-item__overline-text {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
  color: #6200ee;
  /* @alternate */
  color: var(--mdc-theme-primary, #6200ee);
}

.mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #6200ee;
  /* @alternate */
  color: var(--mdc-theme-primary, #6200ee);
}

.mdc-deprecated-list-group__subheader {
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-list-divider::after {
    content: "";
    display: block;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: white;
  }
}
.mdc-list {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  /* @alternate */
  line-height: 1.5rem;
  margin: 0;
  padding: 8px 0;
  list-style-type: none;
}

.mdc-list:focus {
  outline: none;
}

.mdc-list-item__wrapper {
  display: block;
}

.mdc-list-item {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  padding: 0;
  align-items: stretch;
  cursor: pointer;
}

.mdc-list-item:focus {
  outline: none;
}

.mdc-list-item.mdc-list-item--with-one-line {
  height: 48px;
}

.mdc-list-item.mdc-list-item--with-two-lines {
  height: 64px;
}

.mdc-list-item.mdc-list-item--with-three-lines {
  height: 88px;
}

.mdc-list-item.mdc-list-item--with-one-line .mdc-list-item__start {
  align-self: center;
  margin-top: 0;
}

.mdc-list-item.mdc-list-item--with-two-lines .mdc-list-item__start {
  align-self: flex-start;
  margin-top: 16px;
}

.mdc-list-item.mdc-list-item--with-three-lines .mdc-list-item__start {
  align-self: flex-start;
  margin-top: 16px;
}

.mdc-list-item.mdc-list-item--with-one-line .mdc-list-item__end {
  align-self: center;
  margin-top: 0;
}

.mdc-list-item.mdc-list-item--with-two-lines .mdc-list-item__end {
  align-self: center;
  margin-top: 0;
}

.mdc-list-item.mdc-list-item--with-three-lines .mdc-list-item__end {
  align-self: flex-start;
  margin-top: 16px;
}

.mdc-list-item.mdc-list-item--disabled, .mdc-list-item.mdc-list-item--non-interactive {
  cursor: auto;
}

.mdc-list-item:not(.mdc-list-item--selected):focus::before, .mdc-list-item.mdc-ripple-upgraded--background-focused::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 1px solid transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}

@media screen and (forced-colors: active) {
  .mdc-list-item:not(.mdc-list-item--selected):focus::before, .mdc-list-item.mdc-ripple-upgraded--background-focused::before {
    border-color: CanvasText;
  }
}
.mdc-list-item.mdc-list-item--selected::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 3px double transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}

@media screen and (forced-colors: active) {
  .mdc-list-item.mdc-list-item--selected::before {
    border-color: CanvasText;
  }
}
.mdc-list-item.mdc-list-item--selected:focus::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 3px solid transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}

@media screen and (forced-colors: active) {
  .mdc-list-item.mdc-list-item--selected:focus::before {
    border-color: CanvasText;
  }
}
a.mdc-list-item {
  color: inherit;
  text-decoration: none;
}

.mdc-list-item__start {
  fill: currentColor;
  flex-shrink: 0;
  pointer-events: none;
}

.mdc-list-item__end {
  flex-shrink: 0;
  pointer-events: none;
}

.mdc-list-item__content {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  align-self: center;
  flex: 1;
  pointer-events: none;
}

.mdc-list-item--with-two-lines .mdc-list-item__content, .mdc-list-item--with-three-lines .mdc-list-item__content {
  align-self: stretch;
}

.mdc-list-item__content[for] {
  pointer-events: none;
}

.mdc-list-item__primary-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.mdc-list-item--with-two-lines .mdc-list-item__primary-text, .mdc-list-item--with-three-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}

.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before, .mdc-list-item--with-three-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-two-lines .mdc-list-item__primary-text::after, .mdc-list-item--with-three-lines .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-list-item__secondary-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}

.mdc-list-item__secondary-text::before {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-three-lines .mdc-list-item__secondary-text {
  white-space: normal;
  line-height: 20px;
}

.mdc-list-item--with-overline .mdc-list-item__secondary-text {
  white-space: nowrap;
  line-height: auto;
}

.mdc-list-item__overline-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-overline-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-overline-font-size, 0.75rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-overline-line-height, 2rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-overline-font-weight, 500);
  letter-spacing: 0.1666666667em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-overline-letter-spacing, 0.1666666667em);
  text-decoration: none;
  /* @alternate */
  text-decoration: var(--mdc-typography-overline-text-decoration, none);
  text-transform: uppercase;
  /* @alternate */
  text-transform: var(--mdc-typography-overline-text-transform, uppercase);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.mdc-list-item--with-two-lines .mdc-list-item__overline-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}

.mdc-list-item--with-two-lines .mdc-list-item__overline-text::before {
  display: inline-block;
  width: 0;
  height: 24px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-two-lines .mdc-list-item__overline-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-list-item--with-three-lines .mdc-list-item__overline-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}

.mdc-list-item--with-three-lines .mdc-list-item__overline-text::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-three-lines .mdc-list-item__overline-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-list-item--with-leading-avatar.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
}

[dir=rtl] .mdc-list-item--with-leading-avatar.mdc-list-item, .mdc-list-item--with-leading-avatar.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-avatar .mdc-list-item__start {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
}

[dir=rtl] .mdc-list-item--with-leading-avatar .mdc-list-item__start, .mdc-list-item--with-leading-avatar .mdc-list-item__start[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-avatar .mdc-list-item__start {
  width: 40px;
  height: 40px;
}

.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}

.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines .mdc-list-item__overline-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}

.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines .mdc-list-item__overline-text::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines .mdc-list-item__overline-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}

.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line {
  height: 56px;
}

.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines {
  height: 72px;
}

.mdc-list-item--with-leading-avatar .mdc-list-item__start {
  border-radius: 50%;
}

.mdc-list-item--with-leading-icon .mdc-list-item__start {
  width: 24px;
  height: 24px;
}

.mdc-list-item--with-leading-icon.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
}

[dir=rtl] .mdc-list-item--with-leading-icon.mdc-list-item, .mdc-list-item--with-leading-icon.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-icon .mdc-list-item__start {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 32px;
}

[dir=rtl] .mdc-list-item--with-leading-icon .mdc-list-item__start, .mdc-list-item--with-leading-icon .mdc-list-item__start[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 32px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}

.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines .mdc-list-item__overline-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}

.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines .mdc-list-item__overline-text::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines .mdc-list-item__overline-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}

.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}

.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

.mdc-list-item--with-leading-thumbnail.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
}

[dir=rtl] .mdc-list-item--with-leading-thumbnail.mdc-list-item, .mdc-list-item--with-leading-thumbnail.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-thumbnail .mdc-list-item__start {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
}

[dir=rtl] .mdc-list-item--with-leading-thumbnail .mdc-list-item__start, .mdc-list-item--with-leading-thumbnail .mdc-list-item__start[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-thumbnail .mdc-list-item__start {
  width: 40px;
  height: 40px;
}

.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-two-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}

.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-two-lines .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-two-lines .mdc-list-item__overline-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}

.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-two-lines .mdc-list-item__overline-text::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-two-lines .mdc-list-item__overline-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}

.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-one-line {
  height: 56px;
}

.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-two-lines {
  height: 72px;
}

.mdc-list-item--with-leading-image.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
}

[dir=rtl] .mdc-list-item--with-leading-image.mdc-list-item, .mdc-list-item--with-leading-image.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-image .mdc-list-item__start {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
}

[dir=rtl] .mdc-list-item--with-leading-image .mdc-list-item__start, .mdc-list-item--with-leading-image .mdc-list-item__start[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-image .mdc-list-item__start {
  width: 56px;
  height: 56px;
}

.mdc-list-item--with-leading-image.mdc-list-item--with-two-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}

.mdc-list-item--with-leading-image.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-leading-image.mdc-list-item--with-two-lines .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-list-item--with-leading-image.mdc-list-item--with-two-lines .mdc-list-item__overline-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}

.mdc-list-item--with-leading-image.mdc-list-item--with-two-lines .mdc-list-item__overline-text::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-leading-image.mdc-list-item--with-two-lines .mdc-list-item__overline-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-list-item--with-leading-image.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}

.mdc-list-item--with-leading-image.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-leading-image.mdc-list-item--with-one-line {
  height: 72px;
}

.mdc-list-item--with-leading-image.mdc-list-item--with-two-lines {
  height: 72px;
}

.mdc-list-item--with-leading-video.mdc-list-item--with-two-lines .mdc-list-item__start {
  align-self: flex-start;
  margin-top: 8px;
}

.mdc-list-item--with-leading-video.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
}

[dir=rtl] .mdc-list-item--with-leading-video.mdc-list-item, .mdc-list-item--with-leading-video.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-video .mdc-list-item__start {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
}

[dir=rtl] .mdc-list-item--with-leading-video .mdc-list-item__start, .mdc-list-item--with-leading-video .mdc-list-item__start[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-video .mdc-list-item__start {
  width: 100px;
  height: 56px;
}

.mdc-list-item--with-leading-video.mdc-list-item--with-two-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}

.mdc-list-item--with-leading-video.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-leading-video.mdc-list-item--with-two-lines .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-list-item--with-leading-video.mdc-list-item--with-two-lines .mdc-list-item__overline-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}

.mdc-list-item--with-leading-video.mdc-list-item--with-two-lines .mdc-list-item__overline-text::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-leading-video.mdc-list-item--with-two-lines .mdc-list-item__overline-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-list-item--with-leading-video.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}

.mdc-list-item--with-leading-video.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-leading-video.mdc-list-item--with-one-line {
  height: 72px;
}

.mdc-list-item--with-leading-video.mdc-list-item--with-two-lines {
  height: 72px;
}

.mdc-list-item--with-leading-checkbox.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
}

[dir=rtl] .mdc-list-item--with-leading-checkbox.mdc-list-item, .mdc-list-item--with-leading-checkbox.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-checkbox .mdc-list-item__start {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 24px;
}

[dir=rtl] .mdc-list-item--with-leading-checkbox .mdc-list-item__start, .mdc-list-item--with-leading-checkbox .mdc-list-item__start[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 24px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 8px;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-checkbox .mdc-list-item__start {
  width: 40px;
  height: 40px;
}

.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines .mdc-list-item__start {
  align-self: flex-start;
  margin-top: 8px;
}

.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}

.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines .mdc-list-item__overline-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}

.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines .mdc-list-item__overline-text::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines .mdc-list-item__overline-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}

.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line {
  height: 56px;
}

.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines {
  height: 72px;
}

.mdc-list-item--with-leading-radio.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
}

[dir=rtl] .mdc-list-item--with-leading-radio.mdc-list-item, .mdc-list-item--with-leading-radio.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-radio .mdc-list-item__start {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 24px;
}

[dir=rtl] .mdc-list-item--with-leading-radio .mdc-list-item__start, .mdc-list-item--with-leading-radio .mdc-list-item__start[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 24px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 8px;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-radio .mdc-list-item__start {
  width: 40px;
  height: 40px;
}

.mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines .mdc-list-item__start {
  align-self: flex-start;
  margin-top: 8px;
}

.mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}

.mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines .mdc-list-item__overline-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}

.mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines .mdc-list-item__overline-text::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines .mdc-list-item__overline-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}

.mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-leading-radio.mdc-list-item--with-one-line {
  height: 56px;
}

.mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines {
  height: 72px;
}

.mdc-list-item--with-leading-switch.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
}

[dir=rtl] .mdc-list-item--with-leading-switch.mdc-list-item, .mdc-list-item--with-leading-switch.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-switch .mdc-list-item__start {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
}

[dir=rtl] .mdc-list-item--with-leading-switch .mdc-list-item__start, .mdc-list-item--with-leading-switch .mdc-list-item__start[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-switch .mdc-list-item__start {
  width: 36px;
  height: 20px;
}

.mdc-list-item--with-leading-switch.mdc-list-item--with-two-lines .mdc-list-item__start {
  align-self: flex-start;
  margin-top: 16px;
}

.mdc-list-item--with-leading-switch.mdc-list-item--with-two-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}

.mdc-list-item--with-leading-switch.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-leading-switch.mdc-list-item--with-two-lines .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-list-item--with-leading-switch.mdc-list-item--with-two-lines .mdc-list-item__overline-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}

.mdc-list-item--with-leading-switch.mdc-list-item--with-two-lines .mdc-list-item__overline-text::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-leading-switch.mdc-list-item--with-two-lines .mdc-list-item__overline-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-list-item--with-leading-switch.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}

.mdc-list-item--with-leading-switch.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-leading-switch.mdc-list-item--with-one-line {
  height: 56px;
}

.mdc-list-item--with-leading-switch.mdc-list-item--with-two-lines {
  height: 72px;
}

.mdc-list-item--with-trailing-icon.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}

[dir=rtl] .mdc-list-item--with-trailing-icon.mdc-list-item, .mdc-list-item--with-trailing-icon.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
}

[dir=rtl] .mdc-list-item--with-trailing-icon .mdc-list-item__end, .mdc-list-item--with-trailing-icon .mdc-list-item__end[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  width: 24px;
  height: 24px;
}

.mdc-list-item--with-trailing-meta.mdc-list-item--with-two-lines .mdc-list-item__end {
  align-self: flex-start;
  margin-top: 0;
}

.mdc-list-item--with-trailing-meta.mdc-list-item--with-three-lines .mdc-list-item__end {
  align-self: flex-start;
  margin-top: 0;
}

.mdc-list-item--with-trailing-meta.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}

[dir=rtl] .mdc-list-item--with-trailing-meta.mdc-list-item, .mdc-list-item--with-trailing-meta.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 28px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
}

[dir=rtl] .mdc-list-item--with-trailing-meta .mdc-list-item__end, .mdc-list-item--with-trailing-meta .mdc-list-item__end[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 28px;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-trailing-meta.mdc-list-item--with-two-lines .mdc-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}

.mdc-list-item--with-trailing-meta.mdc-list-item--with-two-lines .mdc-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-trailing-meta.mdc-list-item--with-three-lines .mdc-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}

.mdc-list-item--with-trailing-meta.mdc-list-item--with-three-lines .mdc-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 0.75rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: 0.0333333333em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
}

.mdc-list-item--with-trailing-checkbox.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}

[dir=rtl] .mdc-list-item--with-trailing-checkbox.mdc-list-item, .mdc-list-item--with-trailing-checkbox.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-trailing-checkbox .mdc-list-item__end {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 24px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 8px;
}

[dir=rtl] .mdc-list-item--with-trailing-checkbox .mdc-list-item__end, .mdc-list-item--with-trailing-checkbox .mdc-list-item__end[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 24px;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-trailing-checkbox .mdc-list-item__end {
  width: 40px;
  height: 40px;
}

.mdc-list-item--with-trailing-checkbox.mdc-list-item--with-three-lines .mdc-list-item__end {
  align-self: flex-start;
  margin-top: 8px;
}

.mdc-list-item--with-trailing-radio.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}

[dir=rtl] .mdc-list-item--with-trailing-radio.mdc-list-item, .mdc-list-item--with-trailing-radio.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-trailing-radio .mdc-list-item__end {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 24px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 8px;
}

[dir=rtl] .mdc-list-item--with-trailing-radio .mdc-list-item__end, .mdc-list-item--with-trailing-radio .mdc-list-item__end[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 24px;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-trailing-radio .mdc-list-item__end {
  width: 40px;
  height: 40px;
}

.mdc-list-item--with-trailing-radio.mdc-list-item--with-three-lines .mdc-list-item__end {
  align-self: flex-start;
  margin-top: 8px;
}

.mdc-list-item--with-trailing-switch.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}

[dir=rtl] .mdc-list-item--with-trailing-switch.mdc-list-item, .mdc-list-item--with-trailing-switch.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-trailing-switch .mdc-list-item__end {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
}

[dir=rtl] .mdc-list-item--with-trailing-switch .mdc-list-item__end, .mdc-list-item--with-trailing-switch .mdc-list-item__end[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-trailing-switch .mdc-list-item__end {
  width: 36px;
  height: 20px;
}

.mdc-list-item--with-trailing-switch.mdc-list-item--with-three-lines .mdc-list-item__end {
  align-self: flex-start;
  margin-top: 16px;
}

.mdc-list-item--with-overline.mdc-list-item--with-two-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}

.mdc-list-item--with-overline.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item--with-overline.mdc-list-item--with-three-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}

.mdc-list-item--with-overline.mdc-list-item--with-three-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
}

[dir=rtl] .mdc-list-item, .mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-list-group .mdc-deprecated-list {
  padding: 0;
}

.mdc-list-group__subheader {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  margin: calc((3rem - 1.5rem) / 2) 16px;
}

.mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}

.mdc-list-divider {
  height: 1px;
}

.mdc-list-divider {
  padding: 0;
  background-clip: content-box;
}

.mdc-list-divider.mdc-list-divider--with-leading-inset,
.mdc-list-divider--with-leading-text.mdc-list-divider--with-leading-inset,
.mdc-list-divider--with-leading-icon.mdc-list-divider--with-leading-inset,
.mdc-list-divider--with-leading-image.mdc-list-divider--with-leading-inset,
.mdc-list-divider--with-leading-thumbnail.mdc-list-divider--with-leading-inset,
.mdc-list-divider--with-leading-avatar.mdc-list-divider--with-leading-inset,
.mdc-list-divider--with-leading-checkbox.mdc-list-divider--with-leading-inset,
.mdc-list-divider--with-leading-switch.mdc-list-divider--with-leading-inset,
.mdc-list-divider--with-leading-radio.mdc-list-divider--with-leading-inset {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
}

[dir=rtl] .mdc-list-divider.mdc-list-divider--with-leading-inset, [dir=rtl] .mdc-list-divider--with-leading-text.mdc-list-divider--with-leading-inset, [dir=rtl] .mdc-list-divider--with-leading-icon.mdc-list-divider--with-leading-inset, [dir=rtl] .mdc-list-divider--with-leading-image.mdc-list-divider--with-leading-inset, [dir=rtl] .mdc-list-divider--with-leading-thumbnail.mdc-list-divider--with-leading-inset, [dir=rtl] .mdc-list-divider--with-leading-avatar.mdc-list-divider--with-leading-inset, [dir=rtl] .mdc-list-divider--with-leading-checkbox.mdc-list-divider--with-leading-inset, [dir=rtl] .mdc-list-divider--with-leading-switch.mdc-list-divider--with-leading-inset, [dir=rtl] .mdc-list-divider--with-leading-radio.mdc-list-divider--with-leading-inset, .mdc-list-divider.mdc-list-divider--with-leading-inset[dir=rtl], .mdc-list-divider--with-leading-text.mdc-list-divider--with-leading-inset[dir=rtl], .mdc-list-divider--with-leading-icon.mdc-list-divider--with-leading-inset[dir=rtl], .mdc-list-divider--with-leading-image.mdc-list-divider--with-leading-inset[dir=rtl], .mdc-list-divider--with-leading-thumbnail.mdc-list-divider--with-leading-inset[dir=rtl], .mdc-list-divider--with-leading-avatar.mdc-list-divider--with-leading-inset[dir=rtl], .mdc-list-divider--with-leading-checkbox.mdc-list-divider--with-leading-inset[dir=rtl], .mdc-list-divider--with-leading-switch.mdc-list-divider--with-leading-inset[dir=rtl], .mdc-list-divider--with-leading-radio.mdc-list-divider--with-leading-inset[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-list-divider.mdc-list-divider--with-trailing-inset,
.mdc-list-divider--with-leading-text.mdc-list-divider--with-trailing-inset,
.mdc-list-divider--with-leading-icon.mdc-list-divider--with-trailing-inset,
.mdc-list-divider--with-leading-image.mdc-list-divider--with-trailing-inset,
.mdc-list-divider--with-leading-thumbnail.mdc-list-divider--with-trailing-inset,
.mdc-list-divider--with-leading-avatar.mdc-list-divider--with-trailing-inset,
.mdc-list-divider--with-leading-checkbox.mdc-list-divider--with-trailing-inset,
.mdc-list-divider--with-leading-switch.mdc-list-divider--with-trailing-inset,
.mdc-list-divider--with-leading-radio.mdc-list-divider--with-trailing-inset {
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
}

[dir=rtl] .mdc-list-divider.mdc-list-divider--with-trailing-inset, [dir=rtl] .mdc-list-divider--with-leading-text.mdc-list-divider--with-trailing-inset, [dir=rtl] .mdc-list-divider--with-leading-icon.mdc-list-divider--with-trailing-inset, [dir=rtl] .mdc-list-divider--with-leading-image.mdc-list-divider--with-trailing-inset, [dir=rtl] .mdc-list-divider--with-leading-thumbnail.mdc-list-divider--with-trailing-inset, [dir=rtl] .mdc-list-divider--with-leading-avatar.mdc-list-divider--with-trailing-inset, [dir=rtl] .mdc-list-divider--with-leading-checkbox.mdc-list-divider--with-trailing-inset, [dir=rtl] .mdc-list-divider--with-leading-switch.mdc-list-divider--with-trailing-inset, [dir=rtl] .mdc-list-divider--with-leading-radio.mdc-list-divider--with-trailing-inset, .mdc-list-divider.mdc-list-divider--with-trailing-inset[dir=rtl], .mdc-list-divider--with-leading-text.mdc-list-divider--with-trailing-inset[dir=rtl], .mdc-list-divider--with-leading-icon.mdc-list-divider--with-trailing-inset[dir=rtl], .mdc-list-divider--with-leading-image.mdc-list-divider--with-trailing-inset[dir=rtl], .mdc-list-divider--with-leading-thumbnail.mdc-list-divider--with-trailing-inset[dir=rtl], .mdc-list-divider--with-leading-avatar.mdc-list-divider--with-trailing-inset[dir=rtl], .mdc-list-divider--with-leading-checkbox.mdc-list-divider--with-trailing-inset[dir=rtl], .mdc-list-divider--with-leading-switch.mdc-list-divider--with-trailing-inset[dir=rtl], .mdc-list-divider--with-leading-radio.mdc-list-divider--with-trailing-inset[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
  /*rtl:end:ignore*/
}

.mdc-list-divider--with-leading-video.mdc-list-divider--with-leading-inset {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
}

[dir=rtl] .mdc-list-divider--with-leading-video.mdc-list-divider--with-leading-inset, .mdc-list-divider--with-leading-video.mdc-list-divider--with-leading-inset[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0px;
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-list-divider, .mdc-list-divider[dir=rtl] {
  /*rtl:begin:ignore*/
  padding: 0;
  /*rtl:end:ignore*/
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-deprecated-list-item__ripple::before,
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-deprecated-list-item__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-deprecated-list-item__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-deprecated-list-item__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded .mdc-deprecated-list-item__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded .mdc-deprecated-list-item__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded--unbounded .mdc-deprecated-list-item__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded--foreground-activation .mdc-deprecated-list-item__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded--foreground-deactivation .mdc-deprecated-list-item__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-list-item__ripple::before,
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-list-item__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-list-item__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-list-item__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded .mdc-list-item__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded--unbounded .mdc-list-item__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded--foreground-activation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded--foreground-deactivation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-deprecated-list-item__ripple::before,
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-deprecated-list-item__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded .mdc-deprecated-list-item__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-list-item__ripple::before,
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-list-item__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-deprecated-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-deprecated-list-item__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item:hover .mdc-deprecated-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-surface--hover .mdc-deprecated-list-item__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded--background-focused .mdc-deprecated-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item:not(.mdc-ripple-upgraded):focus .mdc-deprecated-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item:not(.mdc-ripple-upgraded) .mdc-deprecated-list-item__ripple::after {
  transition: opacity 150ms linear;
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item:not(.mdc-ripple-upgraded):active .mdc-deprecated-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.12);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-list-item__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item:hover .mdc-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-surface--hover .mdc-list-item__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item:not(.mdc-ripple-upgraded) .mdc-list-item__ripple::after {
  transition: opacity 150ms linear;
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item:not(.mdc-ripple-upgraded):active .mdc-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.12);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated .mdc-deprecated-list-item__ripple::before {
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-activated-opacity, 0.12);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated .mdc-deprecated-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated .mdc-deprecated-list-item__ripple::after {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #6200ee));
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated:hover .mdc-deprecated-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated.mdc-ripple-surface--hover .mdc-deprecated-list-item__ripple::before {
  opacity: 0.16;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.16);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated.mdc-ripple-upgraded--background-focused .mdc-deprecated-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated:not(.mdc-ripple-upgraded):focus .mdc-deprecated-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated:not(.mdc-ripple-upgraded) .mdc-deprecated-list-item__ripple::after {
  transition: opacity 150ms linear;
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated:not(.mdc-ripple-upgraded):active .mdc-deprecated-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.24);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated .mdc-list-item__ripple::before {
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-activated-opacity, 0.12);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated .mdc-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated .mdc-list-item__ripple::after {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #6200ee));
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated:hover .mdc-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated.mdc-ripple-surface--hover .mdc-list-item__ripple::before {
  opacity: 0.16;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.16);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated:not(.mdc-ripple-upgraded) .mdc-list-item__ripple::after {
  transition: opacity 150ms linear;
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated:not(.mdc-ripple-upgraded):active .mdc-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.24);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected .mdc-deprecated-list-item__ripple::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-ripple-selected-opacity, 0.08);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected .mdc-deprecated-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected .mdc-deprecated-list-item__ripple::after {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #6200ee));
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected:hover .mdc-deprecated-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected.mdc-ripple-surface--hover .mdc-deprecated-list-item__ripple::before {
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.12);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected.mdc-ripple-upgraded--background-focused .mdc-deprecated-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected:not(.mdc-ripple-upgraded):focus .mdc-deprecated-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.2;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.2);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected:not(.mdc-ripple-upgraded) .mdc-deprecated-list-item__ripple::after {
  transition: opacity 150ms linear;
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected:not(.mdc-ripple-upgraded):active .mdc-deprecated-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.2;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.2);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.2);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected .mdc-list-item__ripple::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-ripple-selected-opacity, 0.08);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected .mdc-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected .mdc-list-item__ripple::after {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #6200ee));
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected:hover .mdc-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected.mdc-ripple-surface--hover .mdc-list-item__ripple::before {
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.12);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.2;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.2);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected:not(.mdc-ripple-upgraded) .mdc-list-item__ripple::after {
  transition: opacity 150ms linear;
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected:not(.mdc-ripple-upgraded):active .mdc-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.2;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.2);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.2);
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-deprecated-list-item__ripple,
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-list-item__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.mdc-deprecated-list-item--disabled {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}

.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__ripple::before,
.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}

.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}

.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}

.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded .mdc-deprecated-list-item__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded .mdc-deprecated-list-item__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}

.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded--unbounded .mdc-deprecated-list-item__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}

.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded--foreground-activation .mdc-deprecated-list-item__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}

.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded--foreground-deactivation .mdc-deprecated-list-item__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-deprecated-list-item--disabled .mdc-list-item__ripple::before,
.mdc-deprecated-list-item--disabled .mdc-list-item__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}

.mdc-deprecated-list-item--disabled .mdc-list-item__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}

.mdc-deprecated-list-item--disabled .mdc-list-item__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}

.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded .mdc-list-item__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}

.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded--unbounded .mdc-list-item__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}

.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded--foreground-activation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}

.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded--foreground-deactivation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__ripple::before,
.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}

.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded .mdc-deprecated-list-item__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-deprecated-list-item--disabled .mdc-list-item__ripple::before,
.mdc-deprecated-list-item--disabled .mdc-list-item__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}

.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__ripple::before,
.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}

.mdc-deprecated-list-item--disabled .mdc-list-item__ripple::before,
.mdc-deprecated-list-item--disabled .mdc-list-item__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}

.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded--background-focused .mdc-deprecated-list-item__ripple::before, .mdc-deprecated-list-item--disabled:not(.mdc-ripple-upgraded):focus .mdc-deprecated-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}

.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, .mdc-deprecated-list-item--disabled:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}

.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__ripple,
.mdc-deprecated-list-item--disabled .mdc-list-item__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

:not(.mdc-list-item--disabled).mdc-list-item {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}

:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::before,
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}

:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}

:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}

:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded .mdc-list-item__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}

:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}

:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--unbounded .mdc-list-item__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}

:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--foreground-activation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}

:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--foreground-deactivation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}

:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::before,
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}

:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}

:not(.mdc-list-item--disabled).mdc-list-item:hover .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-surface--hover .mdc-list-item__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}

:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}

:not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded) .mdc-list-item__ripple::after {
  transition: opacity 150ms linear;
}

:not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded):active .mdc-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}

:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.12);
}

:not(.mdc-list-item--disabled).mdc-list-item--activated .mdc-list-item__ripple::before {
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-activated-opacity, 0.12);
}

:not(.mdc-list-item--disabled).mdc-list-item--activated .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item--activated .mdc-list-item__ripple::after {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #6200ee));
}

:not(.mdc-list-item--disabled).mdc-list-item--activated:hover .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item--activated.mdc-ripple-surface--hover .mdc-list-item__ripple::before {
  opacity: 0.16;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.16);
}

:not(.mdc-list-item--disabled).mdc-list-item--activated.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item--activated:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}

:not(.mdc-list-item--disabled).mdc-list-item--activated:not(.mdc-ripple-upgraded) .mdc-list-item__ripple::after {
  transition: opacity 150ms linear;
}

:not(.mdc-list-item--disabled).mdc-list-item--activated:not(.mdc-ripple-upgraded):active .mdc-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}

:not(.mdc-list-item--disabled).mdc-list-item--activated.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.24);
}

:not(.mdc-list-item--disabled).mdc-list-item--selected .mdc-list-item__ripple::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-ripple-selected-opacity, 0.08);
}

:not(.mdc-list-item--disabled).mdc-list-item--selected .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item--selected .mdc-list-item__ripple::after {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #6200ee));
}

:not(.mdc-list-item--disabled).mdc-list-item--selected:hover .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item--selected.mdc-ripple-surface--hover .mdc-list-item__ripple::before {
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.12);
}

:not(.mdc-list-item--disabled).mdc-list-item--selected.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item--selected:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.2;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.2);
}

:not(.mdc-list-item--disabled).mdc-list-item--selected:not(.mdc-ripple-upgraded) .mdc-list-item__ripple::after {
  transition: opacity 150ms linear;
}

:not(.mdc-list-item--disabled).mdc-list-item--selected:not(.mdc-ripple-upgraded):active .mdc-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.2;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.2);
}

:not(.mdc-list-item--disabled).mdc-list-item--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.2);
}

:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.mdc-list-item--disabled {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}

.mdc-list-item--disabled .mdc-list-item__ripple::before,
.mdc-list-item--disabled .mdc-list-item__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}

.mdc-list-item--disabled .mdc-list-item__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}

.mdc-list-item--disabled .mdc-list-item__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}

.mdc-list-item--disabled.mdc-ripple-upgraded .mdc-list-item__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-list-item--disabled.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}

.mdc-list-item--disabled.mdc-ripple-upgraded--unbounded .mdc-list-item__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}

.mdc-list-item--disabled.mdc-ripple-upgraded--foreground-activation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}

.mdc-list-item--disabled.mdc-ripple-upgraded--foreground-deactivation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-list-item--disabled .mdc-list-item__ripple::before,
.mdc-list-item--disabled .mdc-list-item__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}

.mdc-list-item--disabled.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-list-item--disabled .mdc-list-item__ripple::before,
.mdc-list-item--disabled .mdc-list-item__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}

.mdc-list-item--disabled.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, .mdc-list-item--disabled:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}

.mdc-list-item--disabled .mdc-list-item__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.mdc-menu {
  min-width: 112px;
  /* @alternate */
  min-width: var(--mdc-menu-min-width, 112px);
}

.mdc-menu .mdc-deprecated-list-item__meta {
  color: rgba(0, 0, 0, 0.87);
}

.mdc-menu .mdc-deprecated-list-item__graphic {
  color: rgba(0, 0, 0, 0.87);
}

.mdc-menu .mdc-menu-item--submenu-open .mdc-deprecated-list-item__ripple::before {
  opacity: 0.04;
}

.mdc-menu .mdc-menu-item--submenu-open .mdc-list-item__ripple::before {
  opacity: 0.04;
}

.mdc-menu .mdc-deprecated-list {
  color: rgba(0, 0, 0, 0.87);
}

.mdc-menu .mdc-deprecated-list,
.mdc-menu .mdc-list {
  /* @alternate */
  position: relative;
}

.mdc-menu .mdc-deprecated-list .mdc-elevation-overlay,
.mdc-menu .mdc-list .mdc-elevation-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
}

.mdc-menu .mdc-deprecated-list-divider {
  margin: 8px 0;
}

.mdc-menu .mdc-deprecated-list-item {
  user-select: none;
}

.mdc-menu .mdc-deprecated-list-item--disabled {
  cursor: auto;
}

.mdc-menu a.mdc-deprecated-list-item .mdc-deprecated-list-item__text,
.mdc-menu a.mdc-deprecated-list-item .mdc-deprecated-list-item__graphic {
  pointer-events: none;
}

.mdc-menu__selection-group {
  padding: 0;
  fill: currentColor;
}

.mdc-menu__selection-group .mdc-deprecated-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 56px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
}

[dir=rtl] .mdc-menu__selection-group .mdc-deprecated-list-item, .mdc-menu__selection-group .mdc-deprecated-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 56px;
  /*rtl:end:ignore*/
}

.mdc-menu__selection-group .mdc-menu__selection-group-icon {
  /* @noflip */ /*rtl:ignore*/
  left: 16px;
  /* @noflip */ /*rtl:ignore*/
  right: initial;
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

[dir=rtl] .mdc-menu__selection-group .mdc-menu__selection-group-icon, .mdc-menu__selection-group .mdc-menu__selection-group-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  left: initial;
  /* @noflip */ /*rtl:ignore*/
  right: 16px;
  /*rtl:end:ignore*/
}

.mdc-menu-item--selected .mdc-menu__selection-group-icon {
  display: inline;
}

.mdc-menu-surface {
  display: none;
  position: absolute;
  box-sizing: border-box;
  max-width: calc(100vw - 32px);
  /* @alternate */
  max-width: var(--mdc-menu-max-width, calc(100vw - 32px));
  max-height: calc(100vh - 32px);
  /* @alternate */
  max-height: var(--mdc-menu-max-height, calc(100vh - 32px));
  margin: 0;
  padding: 0;
  transform: scale(1);
  transform-origin: top left;
  opacity: 0;
  overflow: auto;
  will-change: transform, opacity;
  z-index: 8;
  transition: opacity 0.03s linear, transform 0.12s cubic-bezier(0, 0, 0.2, 1), height 250ms cubic-bezier(0, 0, 0.2, 1);
  /* @alternate */
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-medium, 4px);
  /* @noflip */ /*rtl:ignore*/
  transform-origin-left: top left;
  /* @noflip */ /*rtl:ignore*/
  transform-origin-right: top right;
}

.mdc-menu-surface:focus {
  outline: none;
}

.mdc-menu-surface--animating-open {
  display: inline-block;
  transform: scale(0.8);
  opacity: 0;
}

.mdc-menu-surface--open {
  display: inline-block;
  transform: scale(1);
  opacity: 1;
}

.mdc-menu-surface--animating-closed {
  display: inline-block;
  opacity: 0;
  transition: opacity 0.075s linear;
}

[dir=rtl] .mdc-menu-surface, .mdc-menu-surface[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  transform-origin-left: top right;
  /* @noflip */ /*rtl:ignore*/
  transform-origin-right: top left;
  /*rtl:end:ignore*/
}

.mdc-menu-surface--anchor {
  position: relative;
  overflow: visible;
}

.mdc-menu-surface--fixed {
  position: fixed;
}

.mdc-menu-surface--fullwidth {
  width: 100%;
}

.mdc-radio {
  padding: calc((40px - 20px) / 2);
}

.mdc-radio .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}

.mdc-radio .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle {
  border-color: #018786;
  /* @alternate */
  border-color: var(--mdc-theme-secondary, #018786);
}

.mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle {
  border-color: #018786;
  /* @alternate */
  border-color: var(--mdc-theme-secondary, #018786);
}

.mdc-radio [aria-disabled=true] .mdc-radio__native-control:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle,
.mdc-radio .mdc-radio__native-control:disabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}

.mdc-radio [aria-disabled=true] .mdc-radio__native-control:checked + .mdc-radio__background .mdc-radio__outer-circle,
.mdc-radio .mdc-radio__native-control:disabled:checked + .mdc-radio__background .mdc-radio__outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}

.mdc-radio [aria-disabled=true] .mdc-radio__native-control + .mdc-radio__background .mdc-radio__inner-circle,
.mdc-radio .mdc-radio__native-control:disabled + .mdc-radio__background .mdc-radio__inner-circle {
  border-color: rgba(0, 0, 0, 0.38);
}

.mdc-radio .mdc-radio__background::before {
  background-color: #018786;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #018786);
}

.mdc-radio .mdc-radio__background::before {
  top: calc(-1 * (40px - 20px) / 2);
  left: calc(-1 * (40px - 20px) / 2);
  width: 40px;
  height: 40px;
}

.mdc-radio .mdc-radio__native-control {
  top: calc((40px - 40px) / 2);
  right: calc((40px - 40px) / 2);
  left: calc((40px - 40px) / 2);
  width: 40px;
  height: 40px;
}

@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-radio.mdc-radio--disabled [aria-disabled=true] .mdc-radio__native-control:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle,
  .mdc-radio.mdc-radio--disabled .mdc-radio__native-control:disabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle {
    border-color: GrayText;
  }
  .mdc-radio.mdc-radio--disabled [aria-disabled=true] .mdc-radio__native-control:checked + .mdc-radio__background .mdc-radio__outer-circle,
  .mdc-radio.mdc-radio--disabled .mdc-radio__native-control:disabled:checked + .mdc-radio__background .mdc-radio__outer-circle {
    border-color: GrayText;
  }
  .mdc-radio.mdc-radio--disabled [aria-disabled=true] .mdc-radio__native-control + .mdc-radio__background .mdc-radio__inner-circle,
  .mdc-radio.mdc-radio--disabled .mdc-radio__native-control:disabled + .mdc-radio__background .mdc-radio__inner-circle {
    border-color: GrayText;
  }
}
.mdc-radio {
  display: inline-block;
  position: relative;
  flex: 0 0 auto;
  box-sizing: content-box;
  width: 20px;
  height: 20px;
  cursor: pointer;
  /* @alternate */
  will-change: opacity, transform, border-color, color;
}

.mdc-radio__background {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
}

.mdc-radio__background::before {
  position: absolute;
  transform: scale(0, 0);
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
  transition: opacity 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}

.mdc-radio__outer-circle {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-width: 2px;
  border-style: solid;
  border-radius: 50%;
  transition: border-color 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}

.mdc-radio__inner-circle {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  transform: scale(0, 0);
  border-width: 10px;
  border-style: solid;
  border-radius: 50%;
  transition: transform 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1), border-color 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}

.mdc-radio__native-control {
  position: absolute;
  margin: 0;
  padding: 0;
  opacity: 0;
  cursor: inherit;
  z-index: 1;
}

.mdc-radio--touch {
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 4px;
  margin-left: 4px;
}

.mdc-radio--touch .mdc-radio__native-control {
  top: calc((40px - 48px) / 2);
  right: calc((40px - 48px) / 2);
  left: calc((40px - 48px) / 2);
  width: 48px;
  height: 48px;
}

.mdc-radio.mdc-ripple-upgraded--background-focused .mdc-radio__focus-ring, .mdc-radio:not(.mdc-ripple-upgraded):focus .mdc-radio__focus-ring {
  pointer-events: none;
  border: 2px solid transparent;
  border-radius: 6px;
  box-sizing: content-box;
  position: absolute;
  top: 50%;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  /* @noflip */ /*rtl:ignore*/
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
}

@media screen and (forced-colors: active) {
  .mdc-radio.mdc-ripple-upgraded--background-focused .mdc-radio__focus-ring, .mdc-radio:not(.mdc-ripple-upgraded):focus .mdc-radio__focus-ring {
    border-color: CanvasText;
  }
}
.mdc-radio.mdc-ripple-upgraded--background-focused .mdc-radio__focus-ring::after, .mdc-radio:not(.mdc-ripple-upgraded):focus .mdc-radio__focus-ring::after {
  content: "";
  border: 2px solid transparent;
  border-radius: 8px;
  display: block;
  position: absolute;
  top: 50%;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  /* @noflip */ /*rtl:ignore*/
  transform: translate(-50%, -50%);
  height: calc(100% + 4px);
  width: calc(100% + 4px);
}

@media screen and (forced-colors: active) {
  .mdc-radio.mdc-ripple-upgraded--background-focused .mdc-radio__focus-ring::after, .mdc-radio:not(.mdc-ripple-upgraded):focus .mdc-radio__focus-ring::after {
    border-color: CanvasText;
  }
}
.mdc-radio__native-control:checked + .mdc-radio__background,
.mdc-radio__native-control:disabled + .mdc-radio__background {
  transition: opacity 120ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 120ms 0ms cubic-bezier(0, 0, 0.2, 1);
}

.mdc-radio__native-control:checked + .mdc-radio__background .mdc-radio__outer-circle,
.mdc-radio__native-control:disabled + .mdc-radio__background .mdc-radio__outer-circle {
  transition: border-color 120ms 0ms cubic-bezier(0, 0, 0.2, 1);
}

.mdc-radio__native-control:checked + .mdc-radio__background .mdc-radio__inner-circle,
.mdc-radio__native-control:disabled + .mdc-radio__background .mdc-radio__inner-circle {
  transition: transform 120ms 0ms cubic-bezier(0, 0, 0.2, 1), border-color 120ms 0ms cubic-bezier(0, 0, 0.2, 1);
}

.mdc-radio--disabled {
  cursor: default;
  pointer-events: none;
}

.mdc-radio__native-control:checked + .mdc-radio__background .mdc-radio__inner-circle {
  transform: scale(0.5);
  transition: transform 120ms 0ms cubic-bezier(0, 0, 0.2, 1), border-color 120ms 0ms cubic-bezier(0, 0, 0.2, 1);
}

.mdc-radio__native-control:disabled + .mdc-radio__background,
[aria-disabled=true] .mdc-radio__native-control + .mdc-radio__background {
  cursor: default;
}

.mdc-radio__native-control:focus + .mdc-radio__background::before {
  transform: scale(1);
  opacity: 0.12;
  transition: opacity 120ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 120ms 0ms cubic-bezier(0, 0, 0.2, 1);
}

.mdc-radio {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}

.mdc-radio .mdc-radio__ripple::before,
.mdc-radio .mdc-radio__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}

.mdc-radio .mdc-radio__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}

.mdc-radio .mdc-radio__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}

.mdc-radio.mdc-ripple-upgraded .mdc-radio__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-radio.mdc-ripple-upgraded .mdc-radio__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}

.mdc-radio.mdc-ripple-upgraded--unbounded .mdc-radio__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}

.mdc-radio.mdc-ripple-upgraded--foreground-activation .mdc-radio__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}

.mdc-radio.mdc-ripple-upgraded--foreground-deactivation .mdc-radio__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-radio .mdc-radio__ripple::before,
.mdc-radio .mdc-radio__ripple::after {
  top: calc(50% - 50%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 50%);
  width: 100%;
  height: 100%;
}

.mdc-radio.mdc-ripple-upgraded .mdc-radio__ripple::before,
.mdc-radio.mdc-ripple-upgraded .mdc-radio__ripple::after {
  top: var(--mdc-ripple-top, calc(50% - 50%));
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, calc(50% - 50%));
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-radio.mdc-ripple-upgraded .mdc-radio__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-radio .mdc-radio__ripple::before, .mdc-radio .mdc-radio__ripple::after {
  background-color: #018786;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-secondary, #018786));
}

.mdc-radio:hover .mdc-radio__ripple::before, .mdc-radio.mdc-ripple-surface--hover .mdc-radio__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}

.mdc-radio.mdc-ripple-upgraded--background-focused .mdc-radio__ripple::before, .mdc-radio:not(.mdc-ripple-upgraded):focus .mdc-radio__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}

.mdc-radio:not(.mdc-ripple-upgraded) .mdc-radio__ripple::after {
  transition: opacity 150ms linear;
}

.mdc-radio:not(.mdc-ripple-upgraded):active .mdc-radio__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-radio.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-radio.mdc-ripple-upgraded .mdc-radio__background::before, .mdc-radio.mdc-ripple-upgraded--background-focused .mdc-radio__background::before {
  content: none;
}

.mdc-radio__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.mdc-ripple-surface {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  position: relative;
  outline: none;
  overflow: hidden;
}

.mdc-ripple-surface::before, .mdc-ripple-surface::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}

.mdc-ripple-surface::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}

.mdc-ripple-surface::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}

.mdc-ripple-surface.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-ripple-surface.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}

.mdc-ripple-surface.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}

.mdc-ripple-surface.mdc-ripple-upgraded--foreground-activation::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}

.mdc-ripple-surface.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-ripple-surface::before, .mdc-ripple-surface::after {
  top: calc(50% - 100%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}

.mdc-ripple-surface.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-ripple-surface[data-mdc-ripple-is-unbounded],
.mdc-ripple-upgraded--unbounded {
  overflow: visible;
}

.mdc-ripple-surface[data-mdc-ripple-is-unbounded]::before, .mdc-ripple-surface[data-mdc-ripple-is-unbounded]::after,
.mdc-ripple-upgraded--unbounded::before,
.mdc-ripple-upgraded--unbounded::after {
  top: calc(50% - 50%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 50%);
  width: 100%;
  height: 100%;
}

.mdc-ripple-surface[data-mdc-ripple-is-unbounded].mdc-ripple-upgraded::before, .mdc-ripple-surface[data-mdc-ripple-is-unbounded].mdc-ripple-upgraded::after,
.mdc-ripple-upgraded--unbounded.mdc-ripple-upgraded::before,
.mdc-ripple-upgraded--unbounded.mdc-ripple-upgraded::after {
  top: var(--mdc-ripple-top, calc(50% - 50%));
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, calc(50% - 50%));
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-ripple-surface[data-mdc-ripple-is-unbounded].mdc-ripple-upgraded::after,
.mdc-ripple-upgraded--unbounded.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-ripple-surface::before, .mdc-ripple-surface::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}

.mdc-ripple-surface:hover::before, .mdc-ripple-surface.mdc-ripple-surface--hover::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}

.mdc-ripple-surface.mdc-ripple-upgraded--background-focused::before, .mdc-ripple-surface:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}

.mdc-ripple-surface:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}

.mdc-ripple-surface:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-ripple-surface.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-segmented-button {
  display: inline-block;
  font-size: 0;
}

.mdc-segmented-button__segment {
  border-color: rgba(0, 0, 0, 0.12);
  /* @alternate */
  border-color: var(--mdc-segmented-button-outline-color, rgba(0, 0, 0, 0.12));
}

.mdc-segmented-button__segment {
  color: rgba(0, 0, 0, 0.6);
  /* @alternate */
  color: var(--mdc-segmented-button-unselected-ink-color, rgba(0, 0, 0, 0.6));
}

.mdc-segmented-button__segment {
  background-color: white;
  /* @alternate */
  background-color: var(--mdc-segmented-button-unselected-container-fill-color, white);
}

.mdc-segmented-button__segment--selected {
  color: #6200ee;
  /* @alternate */
  color: var(--mdc-segmented-button-selected-ink-color, #6200ee);
}

.mdc-segmented-button__segment--selected {
  background-color: rgba(98, 0, 238, 0.08);
  /* @alternate */
  background-color: var(--mdc-segmented-button-selected-container-fill-color, rgba(98, 0, 238, 0.08));
}

.mdc-segmented-button__segment {
  /* @alternate */
  position: relative;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-button-font-size, 0.875rem);
  line-height: 2.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-button-line-height, 2.25rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: 0.0892857143em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-button-letter-spacing, 0.0892857143em);
  text-decoration: none;
  /* @alternate */
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: uppercase;
  /* @alternate */
  text-transform: var(--mdc-typography-button-text-transform, uppercase);
  display: inline-flex;
  vertical-align: top;
  align-items: center;
  height: 36px;
  min-width: 48px;
  padding: 0 12px;
  border-width: 1px 0 1px 1px;
}

.mdc-segmented-button__segment .mdc-elevation-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
}

.mdc-segmented-button__segment:hover {
  cursor: pointer;
}

.mdc-segmented-button__segment:focus {
  outline-width: 0;
}

.mdc-segmented-button__segment:first-child {
  border-radius: 4px 0 0 4px;
}

.mdc-segmented-button__segment:last-child {
  border-right-width: 1px;
  border-radius: 0 4px 4px 0;
}

.mdc-segmented-button__segment .mdc-segmented-button__segment__touch {
  position: absolute;
  top: 50%;
  height: 48px;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.mdc-segmented-button__segment .mdc-segmented-button__segment--touch {
  margin-top: 0px;
  margin-bottom: 0px;
}

.mdc-touch-target-wrapper .mdc-segmented-button__segment {
  border-radius: 0;
  border-right-width: 0;
}

.mdc-touch-target-wrapper:first-child .mdc-segmented-button__segment {
  border-radius: 4px 0 0 4px;
}

.mdc-touch-target-wrapper:last-child .mdc-segmented-button__segment {
  border-right-width: 1px;
  border-radius: 0 4px 4px 0;
}

.mdc-segmented-button__icon {
  width: 24px;
  font-size: 18px;
}

.mdc-segmented-button__icon + .mdc-segmented-button__label {
  padding-left: 6px;
}

.mdc-segmented-button__segment {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  overflow: hidden;
}

.mdc-segmented-button__segment .mdc-segmented-button__ripple::before,
.mdc-segmented-button__segment .mdc-segmented-button__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}

.mdc-segmented-button__segment .mdc-segmented-button__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}

.mdc-segmented-button__segment .mdc-segmented-button__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}

.mdc-segmented-button__segment.mdc-ripple-upgraded .mdc-segmented-button__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-segmented-button__segment.mdc-ripple-upgraded .mdc-segmented-button__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}

.mdc-segmented-button__segment.mdc-ripple-upgraded--unbounded .mdc-segmented-button__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}

.mdc-segmented-button__segment.mdc-ripple-upgraded--foreground-activation .mdc-segmented-button__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}

.mdc-segmented-button__segment.mdc-ripple-upgraded--foreground-deactivation .mdc-segmented-button__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-segmented-button__segment .mdc-segmented-button__ripple::before,
.mdc-segmented-button__segment .mdc-segmented-button__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}

.mdc-segmented-button__segment.mdc-ripple-upgraded .mdc-segmented-button__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-segmented-button__segment .mdc-segmented-button__ripple::before, .mdc-segmented-button__segment .mdc-segmented-button__ripple::after {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #6200ee);
}

.mdc-segmented-button__segment:hover .mdc-segmented-button__ripple::before, .mdc-segmented-button__segment.mdc-ripple-surface--hover .mdc-segmented-button__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}

.mdc-segmented-button__segment.mdc-ripple-upgraded--background-focused .mdc-segmented-button__ripple::before, .mdc-segmented-button__segment.mdc-ripple-upgraded:focus-within .mdc-segmented-button__ripple::before, .mdc-segmented-button__segment:not(.mdc-ripple-upgraded):focus .mdc-segmented-button__ripple::before, .mdc-segmented-button__segment:not(.mdc-ripple-upgraded):focus-within .mdc-segmented-button__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}

.mdc-segmented-button__segment:not(.mdc-ripple-upgraded) .mdc-segmented-button__ripple::after {
  transition: opacity 150ms linear;
}

.mdc-segmented-button__segment:not(.mdc-ripple-upgraded):active .mdc-segmented-button__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-segmented-button__segment.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-segmented-button__segment .mdc-segmented-button__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.mdc-slider__thumb {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}

.mdc-slider__thumb::before, .mdc-slider__thumb::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}

.mdc-slider__thumb::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}

.mdc-slider__thumb::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}

.mdc-slider__thumb.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-slider__thumb.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}

.mdc-slider__thumb.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}

.mdc-slider__thumb.mdc-ripple-upgraded--foreground-activation::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}

.mdc-slider__thumb.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-slider__thumb::before, .mdc-slider__thumb::after {
  top: calc(50% - 50%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 50%);
  width: 100%;
  height: 100%;
}

.mdc-slider__thumb.mdc-ripple-upgraded::before, .mdc-slider__thumb.mdc-ripple-upgraded::after {
  top: var(--mdc-ripple-top, calc(50% - 50%));
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, calc(50% - 50%));
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-slider__thumb.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-slider__thumb::before, .mdc-slider__thumb::after {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #6200ee));
}

.mdc-slider__thumb:hover::before, .mdc-slider__thumb.mdc-ripple-surface--hover::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}

.mdc-slider__thumb.mdc-ripple-upgraded--background-focused::before, .mdc-slider__thumb:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}

.mdc-slider__thumb:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}

.mdc-slider__thumb:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-slider__thumb.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-slider {
  cursor: pointer;
  height: 48px;
  margin: 0 24px;
  position: relative;
  touch-action: pan-y;
}

.mdc-slider .mdc-slider__track {
  height: 4px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

.mdc-slider .mdc-slider__track--active,
.mdc-slider .mdc-slider__track--inactive {
  display: flex;
  height: 100%;
  position: absolute;
  width: 100%;
}

.mdc-slider .mdc-slider__track--active {
  border-radius: 3px;
  height: 6px;
  overflow: hidden;
  top: -1px;
}

.mdc-slider .mdc-slider__track--active_fill {
  border-top: 6px solid;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  position: relative;
  /* @noflip */ /*rtl:ignore*/
  -webkit-transform-origin: left;
  /* @noflip */ /*rtl:ignore*/
  transform-origin: left;
}

[dir=rtl] .mdc-slider .mdc-slider__track--active_fill, .mdc-slider .mdc-slider__track--active_fill[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  -webkit-transform-origin: right;
  /* @noflip */ /*rtl:ignore*/
  transform-origin: right;
  /*rtl:end:ignore*/
}

.mdc-slider .mdc-slider__track--inactive {
  border-radius: 2px;
  height: 4px;
  left: 0;
  top: 0;
}

.mdc-slider .mdc-slider__track--inactive::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 1px solid transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}

@media screen and (forced-colors: active) {
  .mdc-slider .mdc-slider__track--inactive::before {
    border-color: CanvasText;
  }
}
.mdc-slider .mdc-slider__track--active_fill {
  border-color: #6200ee;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #6200ee);
}

.mdc-slider.mdc-slider--disabled .mdc-slider__track--active_fill {
  border-color: #000;
  /* @alternate */
  border-color: var(--mdc-theme-on-surface, #000);
}

.mdc-slider .mdc-slider__track--inactive {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #6200ee);
  opacity: 0.24;
}

.mdc-slider.mdc-slider--disabled .mdc-slider__track--inactive {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-theme-on-surface, #000);
  opacity: 0.24;
}

.mdc-slider .mdc-slider__value-indicator-container {
  bottom: 44px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  left: var(--slider-value-indicator-container-left, 50%);
  pointer-events: none;
  position: absolute;
  /* @noflip */ /*rtl:ignore*/
  right: var(--slider-value-indicator-container-right);
  transform: translateX(-50%);
  /* @alternate */
  transform: var(--slider-value-indicator-container-transform, translateX(-50%));
}

.mdc-slider .mdc-slider__value-indicator {
  transition: transform 100ms 0ms cubic-bezier(0.4, 0, 1, 1);
  align-items: center;
  border-radius: 4px;
  display: flex;
  height: 32px;
  padding: 0 12px;
  transform: scale(0);
  transform-origin: bottom;
}

.mdc-slider .mdc-slider__value-indicator::before {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid;
  bottom: -5px;
  content: "";
  height: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  left: var(--slider-value-indicator-caret-left, 50%);
  position: absolute;
  /* @noflip */ /*rtl:ignore*/
  right: var(--slider-value-indicator-caret-right);
  transform: translateX(-50%);
  /* @alternate */
  transform: var(--slider-value-indicator-caret-transform, translateX(-50%));
  width: 0;
}

.mdc-slider .mdc-slider__value-indicator::after {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 1px solid transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}

@media screen and (forced-colors: active) {
  .mdc-slider .mdc-slider__value-indicator::after {
    border-color: CanvasText;
  }
}
.mdc-slider .mdc-slider__thumb--with-indicator .mdc-slider__value-indicator-container {
  pointer-events: auto;
}

.mdc-slider .mdc-slider__thumb--with-indicator .mdc-slider__value-indicator {
  transition: transform 100ms 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(1);
}

@media (prefers-reduced-motion) {
  .mdc-slider .mdc-slider__value-indicator,
  .mdc-slider .mdc-slider__thumb--with-indicator .mdc-slider__value-indicator {
    transition: none;
  }
}
.mdc-slider .mdc-slider__value-indicator-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle2-font-size, 0.875rem);
  line-height: 1.375rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle2-line-height, 1.375rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle2-font-weight, 500);
  letter-spacing: 0.0071428571em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle2-letter-spacing, 0.0071428571em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle2-text-transform, inherit);
}

.mdc-slider .mdc-slider__value-indicator {
  background-color: #000;
  opacity: 0.6;
}

.mdc-slider .mdc-slider__value-indicator::before {
  border-top-color: #000;
}

.mdc-slider .mdc-slider__value-indicator {
  color: #fff;
  /* @alternate */
  color: var(--mdc-theme-on-primary, #fff);
}

.mdc-slider .mdc-slider__thumb {
  display: flex;
  height: 48px;
  /* @noflip */ /*rtl:ignore*/
  left: -24px;
  outline: none;
  position: absolute;
  user-select: none;
  width: 48px;
}

.mdc-slider .mdc-slider__thumb--top {
  z-index: 1;
}

.mdc-slider .mdc-slider__thumb--top .mdc-slider__thumb-knob, .mdc-slider .mdc-slider__thumb--top.mdc-slider__thumb:hover .mdc-slider__thumb-knob, .mdc-slider .mdc-slider__thumb--top.mdc-slider__thumb--focused .mdc-slider__thumb-knob {
  border-style: solid;
  border-width: 1px;
  box-sizing: content-box;
}

.mdc-slider .mdc-slider__thumb-knob {
  /* @alternate */
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border: 10px solid;
  border-radius: 50%;
  box-sizing: border-box;
  height: 20px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
}

.mdc-slider .mdc-slider__thumb-knob {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #6200ee);
  border-color: #6200ee;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #6200ee);
}

.mdc-slider .mdc-slider__thumb--top .mdc-slider__thumb-knob, .mdc-slider .mdc-slider__thumb--top.mdc-slider__thumb:hover .mdc-slider__thumb-knob, .mdc-slider .mdc-slider__thumb--top.mdc-slider__thumb--focused .mdc-slider__thumb-knob {
  border-color: #fff;
}

.mdc-slider.mdc-slider--disabled .mdc-slider__thumb-knob {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-theme-on-surface, #000);
  border-color: #000;
  /* @alternate */
  border-color: var(--mdc-theme-on-surface, #000);
}

.mdc-slider.mdc-slider--disabled .mdc-slider__thumb--top .mdc-slider__thumb-knob, .mdc-slider.mdc-slider--disabled .mdc-slider__thumb--top.mdc-slider__thumb:hover .mdc-slider__thumb-knob, .mdc-slider.mdc-slider--disabled .mdc-slider__thumb--top.mdc-slider__thumb--focused .mdc-slider__thumb-knob {
  border-color: #fff;
}

.mdc-slider .mdc-slider__thumb::before, .mdc-slider .mdc-slider__thumb::after {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #6200ee));
}

.mdc-slider .mdc-slider__thumb:hover::before, .mdc-slider .mdc-slider__thumb.mdc-ripple-surface--hover::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}

.mdc-slider .mdc-slider__thumb.mdc-ripple-upgraded--background-focused::before, .mdc-slider .mdc-slider__thumb:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}

.mdc-slider .mdc-slider__thumb:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}

.mdc-slider .mdc-slider__thumb:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-slider .mdc-slider__thumb.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-slider .mdc-slider__tick-marks {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding: 0 1px;
  position: absolute;
  width: 100%;
}

.mdc-slider .mdc-slider__tick-mark--active,
.mdc-slider .mdc-slider__tick-mark--inactive {
  border-radius: 50%;
  height: 2px;
  width: 2px;
}

.mdc-slider .mdc-slider__tick-mark--active {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-on-primary, #fff);
  opacity: 0.6;
}

.mdc-slider.mdc-slider--disabled .mdc-slider__tick-mark--active {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-on-primary, #fff);
  opacity: 0.6;
}

.mdc-slider .mdc-slider__tick-mark--inactive {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #6200ee);
  opacity: 0.6;
}

.mdc-slider.mdc-slider--disabled .mdc-slider__tick-mark--inactive {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-theme-on-surface, #000);
  opacity: 0.6;
}

.mdc-slider--discrete .mdc-slider__thumb,
.mdc-slider--discrete .mdc-slider__track--active_fill {
  transition: transform 80ms ease;
}

@media (prefers-reduced-motion) {
  .mdc-slider--discrete .mdc-slider__thumb,
  .mdc-slider--discrete .mdc-slider__track--active_fill {
    transition: none;
  }
}
.mdc-slider--disabled {
  opacity: 0.38;
  cursor: auto;
}

.mdc-slider--disabled .mdc-slider__thumb {
  pointer-events: none;
}

.mdc-slider__input {
  cursor: pointer;
  left: 0;
  margin: 0;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
}

.mdc-snackbar {
  z-index: 8;
  margin: 8px;
  display: none;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  pointer-events: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.mdc-snackbar__surface {
  background-color: #333333;
}

.mdc-snackbar__label {
  color: rgba(255, 255, 255, 0.87);
}

.mdc-snackbar__surface {
  min-width: 344px;
}

@media (max-width: 480px), (max-width: 344px) {
  .mdc-snackbar__surface {
    min-width: 100%;
  }
}
.mdc-snackbar__surface {
  max-width: 672px;
}

.mdc-snackbar__surface {
  /* @alternate */
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mdc-snackbar__surface {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-small, 4px);
}

.mdc-snackbar--opening,
.mdc-snackbar--open,
.mdc-snackbar--closing {
  display: flex;
}

.mdc-snackbar--open .mdc-snackbar__label,
.mdc-snackbar--open .mdc-snackbar__actions {
  visibility: visible;
}

.mdc-snackbar--leading {
  justify-content: flex-start;
}

.mdc-snackbar--stacked .mdc-snackbar__label {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 8px;
  padding-bottom: 12px;
}

[dir=rtl] .mdc-snackbar--stacked .mdc-snackbar__label, .mdc-snackbar--stacked .mdc-snackbar__label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-snackbar--stacked .mdc-snackbar__surface {
  flex-direction: column;
  align-items: flex-start;
}

.mdc-snackbar--stacked .mdc-snackbar__actions {
  align-self: flex-end;
  margin-bottom: 8px;
}

.mdc-snackbar__surface {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  transform: scale(0.8);
  opacity: 0;
}

.mdc-snackbar__surface::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 1px solid transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}

@media screen and (forced-colors: active) {
  .mdc-snackbar__surface::before {
    border-color: CanvasText;
  }
}
[dir=rtl] .mdc-snackbar__surface, .mdc-snackbar__surface[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-snackbar--open .mdc-snackbar__surface {
  transform: scale(1);
  opacity: 1;
  pointer-events: auto;
  transition: opacity 150ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 150ms 0ms cubic-bezier(0, 0, 0.2, 1);
}

.mdc-snackbar--closing .mdc-snackbar__surface {
  transform: scale(1);
  transition: opacity 75ms 0ms cubic-bezier(0.4, 0, 1, 1);
}

.mdc-snackbar__label {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 8px;
  width: 100%;
  flex-grow: 1;
  box-sizing: border-box;
  margin: 0;
  visibility: hidden;
  padding-top: 14px;
  padding-bottom: 14px;
}

[dir=rtl] .mdc-snackbar__label, .mdc-snackbar__label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-snackbar__label::before {
  display: inline;
  content: attr(data-mdc-snackbar-label-text);
}

.mdc-snackbar__actions {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  box-sizing: border-box;
  visibility: hidden;
}

.mdc-snackbar__action:not(:disabled) {
  color: #bb86fc;
}

.mdc-snackbar__action .mdc-button__ripple::before, .mdc-snackbar__action .mdc-button__ripple::after {
  background-color: #bb86fc;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #bb86fc);
}

.mdc-snackbar__action:hover .mdc-button__ripple::before, .mdc-snackbar__action.mdc-ripple-surface--hover .mdc-button__ripple::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.08);
}

.mdc-snackbar__action.mdc-ripple-upgraded--background-focused .mdc-button__ripple::before, .mdc-snackbar__action:not(.mdc-ripple-upgraded):focus .mdc-button__ripple::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}

.mdc-snackbar__action:not(.mdc-ripple-upgraded) .mdc-button__ripple::after {
  transition: opacity 150ms linear;
}

.mdc-snackbar__action:not(.mdc-ripple-upgraded):active .mdc-button__ripple::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}

.mdc-snackbar__action.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.24);
}

.mdc-snackbar__dismiss {
  color: rgba(255, 255, 255, 0.87);
}

.mdc-snackbar__dismiss .mdc-icon-button__ripple::before, .mdc-snackbar__dismiss .mdc-icon-button__ripple::after {
  background-color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  background-color: var(--mdc-ripple-color, rgba(255, 255, 255, 0.87));
}

.mdc-snackbar__dismiss:hover .mdc-icon-button__ripple::before, .mdc-snackbar__dismiss.mdc-ripple-surface--hover .mdc-icon-button__ripple::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.08);
}

.mdc-snackbar__dismiss.mdc-ripple-upgraded--background-focused .mdc-icon-button__ripple::before, .mdc-snackbar__dismiss:not(.mdc-ripple-upgraded):focus .mdc-icon-button__ripple::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}

.mdc-snackbar__dismiss:not(.mdc-ripple-upgraded) .mdc-icon-button__ripple::after {
  transition: opacity 150ms linear;
}

.mdc-snackbar__dismiss:not(.mdc-ripple-upgraded):active .mdc-icon-button__ripple::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}

.mdc-snackbar__dismiss.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.24);
}

.mdc-snackbar__dismiss.mdc-snackbar__dismiss {
  width: 36px;
  height: 36px;
  padding: 6px;
  font-size: 18px;
}

.mdc-snackbar__dismiss.mdc-snackbar__dismiss .mdc-icon-button__focus-ring {
  display: none;
}

.mdc-snackbar__dismiss.mdc-snackbar__dismiss.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring, .mdc-snackbar__dismiss.mdc-snackbar__dismiss:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring {
  display: block;
  max-height: 36px;
  max-width: 36px;
}

@media screen and (forced-colors: active) {
  .mdc-snackbar__dismiss.mdc-snackbar__dismiss.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring, .mdc-snackbar__dismiss.mdc-snackbar__dismiss:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring {
    pointer-events: none;
    border: 2px solid transparent;
    border-radius: 6px;
    box-sizing: content-box;
    position: absolute;
    top: 50%;
    /* @noflip */ /*rtl:ignore*/
    left: 50%;
    /* @noflip */ /*rtl:ignore*/
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
  }
}
@media screen and (forced-colors: active) and (forced-colors: active) {
  .mdc-snackbar__dismiss.mdc-snackbar__dismiss.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring, .mdc-snackbar__dismiss.mdc-snackbar__dismiss:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring {
    border-color: CanvasText;
  }
}
@media screen and (forced-colors: active) {
  .mdc-snackbar__dismiss.mdc-snackbar__dismiss.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring::after, .mdc-snackbar__dismiss.mdc-snackbar__dismiss:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring::after {
    content: "";
    border: 2px solid transparent;
    border-radius: 8px;
    display: block;
    position: absolute;
    top: 50%;
    /* @noflip */ /*rtl:ignore*/
    left: 50%;
    /* @noflip */ /*rtl:ignore*/
    transform: translate(-50%, -50%);
    height: calc(100% + 4px);
    width: calc(100% + 4px);
  }
}
@media screen and (forced-colors: active) and (forced-colors: active) {
  .mdc-snackbar__dismiss.mdc-snackbar__dismiss.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring::after, .mdc-snackbar__dismiss.mdc-snackbar__dismiss:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring::after {
    border-color: CanvasText;
  }
}
.mdc-snackbar__dismiss.mdc-snackbar__dismiss.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 36px;
  height: 36px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  margin-left: 0px;
}

.mdc-snackbar__dismiss.mdc-snackbar__dismiss.mdc-icon-button--reduced-size.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring, .mdc-snackbar__dismiss.mdc-snackbar__dismiss.mdc-icon-button--reduced-size:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring {
  max-height: 36px;
  max-width: 36px;
}

.mdc-snackbar__dismiss.mdc-snackbar__dismiss .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 36px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 36px;
  transform: translate(-50%, -50%);
}

.mdc-snackbar__action + .mdc-snackbar__dismiss {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
}

[dir=rtl] .mdc-snackbar__action + .mdc-snackbar__dismiss, .mdc-snackbar__action + .mdc-snackbar__dismiss[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 8px;
  /*rtl:end:ignore*/
}

.mdc-switch {
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  display: inline-flex;
  flex-shrink: 0;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 0;
  position: relative;
}

.mdc-switch:disabled {
  cursor: default;
  pointer-events: none;
}

.mdc-switch__track {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.mdc-switch__track::before, .mdc-switch__track::after {
  border: 1px solid transparent;
  border-radius: inherit;
  box-sizing: border-box;
  content: "";
  height: 100%;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  position: absolute;
  width: 100%;
}

@media screen and (forced-colors: active) {
  .mdc-switch__track::before, .mdc-switch__track::after {
    border-color: currentColor;
  }
}
.mdc-switch__track::before {
  transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: translateX(0);
}

.mdc-switch__track::after {
  transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  transform: translateX(-100%);
}

[dir=rtl] .mdc-switch__track::after, .mdc-switch__track[dir=rtl]::after {
  /*rtl:begin:ignore*/
  transform: translateX(100%);
  /*rtl:end:ignore*/
}

.mdc-switch--selected .mdc-switch__track::before {
  transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  transform: translateX(100%);
}

[dir=rtl] .mdc-switch--selected .mdc-switch__track::before, .mdc-switch--selected .mdc-switch__track[dir=rtl]::before {
  /*rtl:begin:ignore*/
  transform: translateX(-100%);
  /*rtl:end:ignore*/
}

.mdc-switch--selected .mdc-switch__track::after {
  transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: translateX(0);
}

.mdc-switch__handle-track {
  height: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  /* @noflip */ /*rtl:ignore*/
  right: auto;
  transform: translateX(0);
}

[dir=rtl] .mdc-switch__handle-track, .mdc-switch__handle-track[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  left: auto;
  /* @noflip */ /*rtl:ignore*/
  right: 0;
  /*rtl:end:ignore*/
}

.mdc-switch--selected .mdc-switch__handle-track {
  transform: translateX(100%);
}

[dir=rtl] .mdc-switch--selected .mdc-switch__handle-track, .mdc-switch--selected .mdc-switch__handle-track[dir=rtl] {
  /*rtl:begin:ignore*/
  transform: translateX(-100%);
  /*rtl:end:ignore*/
}

.mdc-switch__handle {
  display: flex;
  pointer-events: auto;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  /* @noflip */ /*rtl:ignore*/
  right: auto;
}

[dir=rtl] .mdc-switch__handle, .mdc-switch__handle[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  left: auto;
  /* @noflip */ /*rtl:ignore*/
  right: 0;
  /*rtl:end:ignore*/
}

.mdc-switch__handle::before, .mdc-switch__handle::after {
  border: 1px solid transparent;
  border-radius: inherit;
  box-sizing: border-box;
  content: "";
  width: 100%;
  height: 100%;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  position: absolute;
  top: 0;
  transition: background-color 75ms 0ms cubic-bezier(0.4, 0, 0.2, 1), border-color 75ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
  z-index: -1;
}

@media screen and (forced-colors: active) {
  .mdc-switch__handle::before, .mdc-switch__handle::after {
    border-color: currentColor;
  }
}
.mdc-switch__shadow {
  border-radius: inherit;
  bottom: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  position: absolute;
  /* @noflip */ /*rtl:ignore*/
  right: 0;
  top: 0;
}

.mdc-elevation-overlay {
  bottom: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  /* @noflip */ /*rtl:ignore*/
  right: 0;
  top: 0;
}

.mdc-switch__ripple {
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.mdc-switch:disabled .mdc-switch__ripple {
  display: none;
}

.mdc-switch__icons {
  height: 100%;
  position: relative;
  width: 100%;
  z-index: 1;
}

.mdc-switch__icon {
  bottom: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  margin: auto;
  position: absolute;
  /* @noflip */ /*rtl:ignore*/
  right: 0;
  top: 0;
  opacity: 0;
  transition: opacity 30ms 0ms cubic-bezier(0.4, 0, 1, 1);
}

.mdc-switch--selected .mdc-switch__icon--on,
.mdc-switch--unselected .mdc-switch__icon--off {
  opacity: 1;
  transition: opacity 45ms 30ms cubic-bezier(0, 0, 0.2, 1);
}

.mdc-switch {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}

.mdc-switch .mdc-switch__ripple::before,
.mdc-switch .mdc-switch__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}

.mdc-switch .mdc-switch__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}

.mdc-switch .mdc-switch__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}

.mdc-switch.mdc-ripple-upgraded .mdc-switch__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-switch.mdc-ripple-upgraded .mdc-switch__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}

.mdc-switch.mdc-ripple-upgraded--unbounded .mdc-switch__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}

.mdc-switch.mdc-ripple-upgraded--foreground-activation .mdc-switch__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}

.mdc-switch.mdc-ripple-upgraded--foreground-deactivation .mdc-switch__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-switch .mdc-switch__ripple::before,
.mdc-switch .mdc-switch__ripple::after {
  top: calc(50% - 50%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 50%);
  width: 100%;
  height: 100%;
}

.mdc-switch.mdc-ripple-upgraded .mdc-switch__ripple::before,
.mdc-switch.mdc-ripple-upgraded .mdc-switch__ripple::after {
  top: var(--mdc-ripple-top, calc(50% - 50%));
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, calc(50% - 50%));
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-switch.mdc-ripple-upgraded .mdc-switch__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-switch .mdc-switch__focus-ring-wrapper {
  width: 100%;
  position: absolute;
  top: 50%;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  /* @noflip */ /*rtl:ignore*/
  transform: translate(-50%, -50%);
}

.mdc-switch.mdc-ripple-upgraded--background-focused .mdc-switch__focus-ring, .mdc-switch:not(.mdc-ripple-upgraded):focus .mdc-switch__focus-ring {
  pointer-events: none;
  border: 2px solid transparent;
  border-radius: 6px;
  box-sizing: content-box;
  position: absolute;
  top: 50%;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  /* @noflip */ /*rtl:ignore*/
  transform: translate(-50%, -50%);
  height: calc(
      100% + 4px
    );
  width: calc(
      100% + 4px
    );
}

@media screen and (forced-colors: active) {
  .mdc-switch.mdc-ripple-upgraded--background-focused .mdc-switch__focus-ring, .mdc-switch:not(.mdc-ripple-upgraded):focus .mdc-switch__focus-ring {
    border-color: CanvasText;
  }
}
.mdc-switch.mdc-ripple-upgraded--background-focused .mdc-switch__focus-ring::after, .mdc-switch:not(.mdc-ripple-upgraded):focus .mdc-switch__focus-ring::after {
  content: "";
  border: 2px solid transparent;
  border-radius: 8px;
  display: block;
  position: absolute;
  top: 50%;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  /* @noflip */ /*rtl:ignore*/
  transform: translate(-50%, -50%);
  height: calc(100% + 4px);
  width: calc(100% + 4px);
}

@media screen and (forced-colors: active) {
  .mdc-switch.mdc-ripple-upgraded--background-focused .mdc-switch__focus-ring::after, .mdc-switch:not(.mdc-ripple-upgraded):focus .mdc-switch__focus-ring::after {
    border-color: CanvasText;
  }
}
.mdc-switch {
  width: 36px;
  /* @alternate */
  width: var(--mdc-switch-track-width, 36px);
}

.mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
  background: #6200ee;
  /* @alternate */
  background: var(--mdc-switch-selected-handle-color, var(--mdc-theme-primary, #6200ee));
}

.mdc-switch.mdc-switch--selected:enabled:hover:not(:focus):not(:active) .mdc-switch__handle::after {
  background: #310077;
  /* @alternate */
  background: var(--mdc-switch-selected-hover-handle-color, #310077);
}

.mdc-switch.mdc-switch--selected:enabled:focus:not(:active) .mdc-switch__handle::after {
  background: #310077;
  /* @alternate */
  background: var(--mdc-switch-selected-focus-handle-color, #310077);
}

.mdc-switch.mdc-switch--selected:enabled:active .mdc-switch__handle::after {
  background: #310077;
  /* @alternate */
  background: var(--mdc-switch-selected-pressed-handle-color, #310077);
}

.mdc-switch.mdc-switch--selected:disabled .mdc-switch__handle::after {
  background: #424242;
  /* @alternate */
  background: var(--mdc-switch-disabled-selected-handle-color, #424242);
}

.mdc-switch.mdc-switch--unselected:enabled .mdc-switch__handle::after {
  background: #616161;
  /* @alternate */
  background: var(--mdc-switch-unselected-handle-color, #616161);
}

.mdc-switch.mdc-switch--unselected:enabled:hover:not(:focus):not(:active) .mdc-switch__handle::after {
  background: #212121;
  /* @alternate */
  background: var(--mdc-switch-unselected-hover-handle-color, #212121);
}

.mdc-switch.mdc-switch--unselected:enabled:focus:not(:active) .mdc-switch__handle::after {
  background: #212121;
  /* @alternate */
  background: var(--mdc-switch-unselected-focus-handle-color, #212121);
}

.mdc-switch.mdc-switch--unselected:enabled:active .mdc-switch__handle::after {
  background: #212121;
  /* @alternate */
  background: var(--mdc-switch-unselected-pressed-handle-color, #212121);
}

.mdc-switch.mdc-switch--unselected:disabled .mdc-switch__handle::after {
  background: #424242;
  /* @alternate */
  background: var(--mdc-switch-disabled-unselected-handle-color, #424242);
}

.mdc-switch .mdc-switch__handle::before {
  background: #fff;
  /* @alternate */
  background: var(--mdc-switch-handle-surface-color, var(--mdc-theme-surface, #fff));
}

.mdc-switch:enabled .mdc-switch__shadow {
  --mdc-elevation-box-shadow-for-gss:0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  /* @alternate */
  box-shadow: var(--mdc-switch-handle-elevation, var(--mdc-elevation-box-shadow-for-gss));
}

.mdc-switch:disabled .mdc-switch__shadow {
  --mdc-elevation-box-shadow-for-gss:0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  /* @alternate */
  box-shadow: var(--mdc-switch-disabled-handle-elevation, var(--mdc-elevation-box-shadow-for-gss));
}

.mdc-switch .mdc-switch__focus-ring-wrapper,
.mdc-switch .mdc-switch__handle {
  height: 20px;
  /* @alternate */
  height: var(--mdc-switch-handle-height, 20px);
}

.mdc-switch:disabled .mdc-switch__handle::after {
  opacity: 0.38;
  /* @alternate */
  opacity: var(--mdc-switch-disabled-handle-opacity, 0.38);
}

.mdc-switch .mdc-switch__handle {
  border-radius: 10px;
  /* @alternate */
  border-radius: var(--mdc-switch-handle-shape, 10px);
}

.mdc-switch .mdc-switch__handle {
  width: 20px;
  /* @alternate */
  width: var(--mdc-switch-handle-width, 20px);
}

.mdc-switch .mdc-switch__handle-track {
  width: calc(100% - 20px);
  /* @alternate */
  width: calc(100% - var(--mdc-switch-handle-width, 20px));
}

.mdc-switch.mdc-switch--selected:enabled .mdc-switch__icon {
  fill: #fff;
  /* @alternate */
  fill: var(--mdc-switch-selected-icon-color, var(--mdc-theme-on-primary, #fff));
}

.mdc-switch.mdc-switch--selected:disabled .mdc-switch__icon {
  fill: #fff;
  /* @alternate */
  fill: var(--mdc-switch-disabled-selected-icon-color, var(--mdc-theme-on-primary, #fff));
}

.mdc-switch.mdc-switch--unselected:enabled .mdc-switch__icon {
  fill: #fff;
  /* @alternate */
  fill: var(--mdc-switch-unselected-icon-color, var(--mdc-theme-on-primary, #fff));
}

.mdc-switch.mdc-switch--unselected:disabled .mdc-switch__icon {
  fill: #fff;
  /* @alternate */
  fill: var(--mdc-switch-disabled-unselected-icon-color, var(--mdc-theme-on-primary, #fff));
}

.mdc-switch.mdc-switch--selected:disabled .mdc-switch__icons {
  opacity: 0.38;
  /* @alternate */
  opacity: var(--mdc-switch-disabled-selected-icon-opacity, 0.38);
}

.mdc-switch.mdc-switch--unselected:disabled .mdc-switch__icons {
  opacity: 0.38;
  /* @alternate */
  opacity: var(--mdc-switch-disabled-unselected-icon-opacity, 0.38);
}

.mdc-switch.mdc-switch--selected .mdc-switch__icon {
  width: 18px;
  /* @alternate */
  width: var(--mdc-switch-selected-icon-size, 18px);
  height: 18px;
  /* @alternate */
  height: var(--mdc-switch-selected-icon-size, 18px);
}

.mdc-switch.mdc-switch--unselected .mdc-switch__icon {
  width: 18px;
  /* @alternate */
  width: var(--mdc-switch-unselected-icon-size, 18px);
  height: 18px;
  /* @alternate */
  height: var(--mdc-switch-unselected-icon-size, 18px);
}

.mdc-switch.mdc-switch--selected:enabled:hover:not(:focus) .mdc-switch__ripple::before,
.mdc-switch.mdc-switch--selected:enabled:hover:not(:focus) .mdc-switch__ripple::after {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-switch-selected-hover-state-layer-color, var(--mdc-theme-primary, #6200ee));
}

.mdc-switch.mdc-switch--selected:enabled:focus .mdc-switch__ripple::before,
.mdc-switch.mdc-switch--selected:enabled:focus .mdc-switch__ripple::after {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-switch-selected-focus-state-layer-color, var(--mdc-theme-primary, #6200ee));
}

.mdc-switch.mdc-switch--selected:enabled:active .mdc-switch__ripple::before,
.mdc-switch.mdc-switch--selected:enabled:active .mdc-switch__ripple::after {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-switch-selected-pressed-state-layer-color, var(--mdc-theme-primary, #6200ee));
}

.mdc-switch.mdc-switch--unselected:enabled:hover:not(:focus) .mdc-switch__ripple::before,
.mdc-switch.mdc-switch--unselected:enabled:hover:not(:focus) .mdc-switch__ripple::after {
  background-color: #424242;
  /* @alternate */
  background-color: var(--mdc-switch-unselected-hover-state-layer-color, #424242);
}

.mdc-switch.mdc-switch--unselected:enabled:focus .mdc-switch__ripple::before,
.mdc-switch.mdc-switch--unselected:enabled:focus .mdc-switch__ripple::after {
  background-color: #424242;
  /* @alternate */
  background-color: var(--mdc-switch-unselected-focus-state-layer-color, #424242);
}

.mdc-switch.mdc-switch--unselected:enabled:active .mdc-switch__ripple::before,
.mdc-switch.mdc-switch--unselected:enabled:active .mdc-switch__ripple::after {
  background-color: #424242;
  /* @alternate */
  background-color: var(--mdc-switch-unselected-pressed-state-layer-color, #424242);
}

.mdc-switch.mdc-switch--selected:enabled:hover:not(:focus):hover .mdc-switch__ripple::before, .mdc-switch.mdc-switch--selected:enabled:hover:not(:focus).mdc-ripple-surface--hover .mdc-switch__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-switch-selected-hover-state-layer-opacity, 0.04);
}

.mdc-switch.mdc-switch--selected:enabled:focus.mdc-ripple-upgraded--background-focused .mdc-switch__ripple::before, .mdc-switch.mdc-switch--selected:enabled:focus:not(.mdc-ripple-upgraded):focus .mdc-switch__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-switch-selected-focus-state-layer-opacity, 0.12);
}

.mdc-switch.mdc-switch--selected:enabled:active:not(.mdc-ripple-upgraded) .mdc-switch__ripple::after {
  transition: opacity 150ms linear;
}

.mdc-switch.mdc-switch--selected:enabled:active:not(.mdc-ripple-upgraded):active .mdc-switch__ripple::after {
  transition-duration: 75ms;
  opacity: 0.1;
  /* @alternate */
  opacity: var(--mdc-switch-selected-pressed-state-layer-opacity, 0.1);
}

.mdc-switch.mdc-switch--selected:enabled:active.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-switch-selected-pressed-state-layer-opacity, 0.1);
}

.mdc-switch.mdc-switch--unselected:enabled:hover:not(:focus):hover .mdc-switch__ripple::before, .mdc-switch.mdc-switch--unselected:enabled:hover:not(:focus).mdc-ripple-surface--hover .mdc-switch__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-switch-unselected-hover-state-layer-opacity, 0.04);
}

.mdc-switch.mdc-switch--unselected:enabled:focus.mdc-ripple-upgraded--background-focused .mdc-switch__ripple::before, .mdc-switch.mdc-switch--unselected:enabled:focus:not(.mdc-ripple-upgraded):focus .mdc-switch__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-switch-unselected-focus-state-layer-opacity, 0.12);
}

.mdc-switch.mdc-switch--unselected:enabled:active:not(.mdc-ripple-upgraded) .mdc-switch__ripple::after {
  transition: opacity 150ms linear;
}

.mdc-switch.mdc-switch--unselected:enabled:active:not(.mdc-ripple-upgraded):active .mdc-switch__ripple::after {
  transition-duration: 75ms;
  opacity: 0.1;
  /* @alternate */
  opacity: var(--mdc-switch-unselected-pressed-state-layer-opacity, 0.1);
}

.mdc-switch.mdc-switch--unselected:enabled:active.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-switch-unselected-pressed-state-layer-opacity, 0.1);
}

.mdc-switch .mdc-switch__ripple {
  height: 48px;
  /* @alternate */
  height: var(--mdc-switch-state-layer-size, 48px);
  width: 48px;
  /* @alternate */
  width: var(--mdc-switch-state-layer-size, 48px);
}

.mdc-switch .mdc-switch__track {
  height: 14px;
  /* @alternate */
  height: var(--mdc-switch-track-height, 14px);
}

.mdc-switch:disabled .mdc-switch__track {
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-switch-disabled-track-opacity, 0.12);
}

.mdc-switch:enabled .mdc-switch__track::after {
  background: #d7bbff;
  /* @alternate */
  background: var(--mdc-switch-selected-track-color, #d7bbff);
}

.mdc-switch:enabled:hover:not(:focus):not(:active) .mdc-switch__track::after {
  background: #d7bbff;
  /* @alternate */
  background: var(--mdc-switch-selected-hover-track-color, #d7bbff);
}

.mdc-switch:enabled:focus:not(:active) .mdc-switch__track::after {
  background: #d7bbff;
  /* @alternate */
  background: var(--mdc-switch-selected-focus-track-color, #d7bbff);
}

.mdc-switch:enabled:active .mdc-switch__track::after {
  background: #d7bbff;
  /* @alternate */
  background: var(--mdc-switch-selected-pressed-track-color, #d7bbff);
}

.mdc-switch:disabled .mdc-switch__track::after {
  background: #424242;
  /* @alternate */
  background: var(--mdc-switch-disabled-selected-track-color, #424242);
}

.mdc-switch:enabled .mdc-switch__track::before {
  background: #e0e0e0;
  /* @alternate */
  background: var(--mdc-switch-unselected-track-color, #e0e0e0);
}

.mdc-switch:enabled:hover:not(:focus):not(:active) .mdc-switch__track::before {
  background: #e0e0e0;
  /* @alternate */
  background: var(--mdc-switch-unselected-hover-track-color, #e0e0e0);
}

.mdc-switch:enabled:focus:not(:active) .mdc-switch__track::before {
  background: #e0e0e0;
  /* @alternate */
  background: var(--mdc-switch-unselected-focus-track-color, #e0e0e0);
}

.mdc-switch:enabled:active .mdc-switch__track::before {
  background: #e0e0e0;
  /* @alternate */
  background: var(--mdc-switch-unselected-pressed-track-color, #e0e0e0);
}

.mdc-switch:disabled .mdc-switch__track::before {
  background: #424242;
  /* @alternate */
  background: var(--mdc-switch-disabled-unselected-track-color, #424242);
}

.mdc-switch .mdc-switch__track {
  border-radius: 7px;
  /* @alternate */
  border-radius: var(--mdc-switch-track-shape, 7px);
}

@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-switch:enabled .mdc-switch__shadow {
    /* @alternate */
  }
  .mdc-switch:disabled .mdc-switch__shadow {
    /* @alternate */
  }
  .mdc-switch:disabled .mdc-switch__handle::after {
    opacity: 1;
    /* @alternate */
    opacity: var(--mdc-switch-disabled-handle-opacity, 1);
  }
  .mdc-switch.mdc-switch--selected:enabled .mdc-switch__icon {
    fill: ButtonText;
    /* @alternate */
    fill: var(--mdc-switch-selected-icon-color, ButtonText);
  }
  .mdc-switch.mdc-switch--selected:disabled .mdc-switch__icon {
    fill: GrayText;
    /* @alternate */
    fill: var(--mdc-switch-disabled-selected-icon-color, GrayText);
  }
  .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__icon {
    fill: ButtonText;
    /* @alternate */
    fill: var(--mdc-switch-unselected-icon-color, ButtonText);
  }
  .mdc-switch.mdc-switch--unselected:disabled .mdc-switch__icon {
    fill: GrayText;
    /* @alternate */
    fill: var(--mdc-switch-disabled-unselected-icon-color, GrayText);
  }
  .mdc-switch.mdc-switch--selected:disabled .mdc-switch__icons {
    opacity: 1;
    /* @alternate */
    opacity: var(--mdc-switch-disabled-selected-icon-opacity, 1);
  }
  .mdc-switch.mdc-switch--unselected:disabled .mdc-switch__icons {
    opacity: 1;
    /* @alternate */
    opacity: var(--mdc-switch-disabled-unselected-icon-opacity, 1);
  }
  .mdc-switch:disabled .mdc-switch__track {
    opacity: 1;
    /* @alternate */
    opacity: var(--mdc-switch-disabled-track-opacity, 1);
  }
}
.mdc-tab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-button-font-size, 0.875rem);
  line-height: 2.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-button-line-height, 2.25rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: 0.0892857143em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-button-letter-spacing, 0.0892857143em);
  text-decoration: none;
  /* @alternate */
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: uppercase;
  /* @alternate */
  text-transform: var(--mdc-typography-button-text-transform, uppercase);
  position: relative;
}

.mdc-tab .mdc-tab__text-label {
  color: rgba(0, 0, 0, 0.6);
}

.mdc-tab .mdc-tab__icon {
  color: rgba(0, 0, 0, 0.54);
  fill: currentColor;
}

.mdc-tab.mdc-ripple-upgraded--background-focused.mdc-tab--active .mdc-tab__focus-ring, .mdc-tab:not(.mdc-ripple-upgraded):focus.mdc-tab--active .mdc-tab__focus-ring {
  pointer-events: none;
  border: 2px solid transparent;
  border-radius: 8px;
  box-sizing: content-box;
  position: absolute;
  top: 50%;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  /* @noflip */ /*rtl:ignore*/
  transform: translate(-50%, -50%);
  height: calc(
      100% + -12px
    );
  width: calc(
      100% + -8px
    );
  margin-top: -2px;
  z-index: 2;
}

@media screen and (forced-colors: active) {
  .mdc-tab.mdc-ripple-upgraded--background-focused.mdc-tab--active .mdc-tab__focus-ring, .mdc-tab:not(.mdc-ripple-upgraded):focus.mdc-tab--active .mdc-tab__focus-ring {
    border-color: CanvasText;
  }
}
.mdc-tab.mdc-ripple-upgraded--background-focused.mdc-tab--active .mdc-tab__focus-ring::after, .mdc-tab:not(.mdc-ripple-upgraded):focus.mdc-tab--active .mdc-tab__focus-ring::after {
  content: "";
  border: 2px solid transparent;
  border-radius: 10px;
  display: block;
  position: absolute;
  top: 50%;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  /* @noflip */ /*rtl:ignore*/
  transform: translate(-50%, -50%);
  height: calc(100% + 4px);
  width: calc(100% + 4px);
}

@media screen and (forced-colors: active) {
  .mdc-tab.mdc-ripple-upgraded--background-focused.mdc-tab--active .mdc-tab__focus-ring::after, .mdc-tab:not(.mdc-ripple-upgraded):focus.mdc-tab--active .mdc-tab__focus-ring::after {
    border-color: CanvasText;
  }
}
.mdc-tab.mdc-ripple-upgraded--background-focused .mdc-tab__focus-ring, .mdc-tab:not(.mdc-ripple-upgraded):focus .mdc-tab__focus-ring {
  pointer-events: none;
  border: 2px solid transparent;
  border-radius: 8px;
  box-sizing: content-box;
  position: absolute;
  top: 50%;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  /* @noflip */ /*rtl:ignore*/
  transform: translate(-50%, -50%);
  height: calc(
      100% + -8px
    );
  width: calc(
      100% + -8px
    );
  z-index: 2;
}

@media screen and (forced-colors: active) {
  .mdc-tab.mdc-ripple-upgraded--background-focused .mdc-tab__focus-ring, .mdc-tab:not(.mdc-ripple-upgraded):focus .mdc-tab__focus-ring {
    border-color: CanvasText;
  }
}
.mdc-tab.mdc-ripple-upgraded--background-focused .mdc-tab__focus-ring::after, .mdc-tab:not(.mdc-ripple-upgraded):focus .mdc-tab__focus-ring::after {
  content: "";
  border: 2px solid transparent;
  border-radius: 10px;
  display: block;
  position: absolute;
  top: 50%;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  /* @noflip */ /*rtl:ignore*/
  transform: translate(-50%, -50%);
  height: calc(100% + 4px);
  width: calc(100% + 4px);
}

@media screen and (forced-colors: active) {
  .mdc-tab.mdc-ripple-upgraded--background-focused .mdc-tab__focus-ring::after, .mdc-tab:not(.mdc-ripple-upgraded):focus .mdc-tab__focus-ring::after {
    border-color: CanvasText;
  }
}
.mdc-tab__content {
  position: relative;
}

.mdc-tab__icon {
  width: 24px;
  height: 24px;
  font-size: 24px;
}

.mdc-tab--active .mdc-tab__text-label {
  color: #6200ee;
  /* @alternate */
  color: var(--mdc-theme-primary, #6200ee);
}

.mdc-tab--active .mdc-tab__icon {
  color: #6200ee;
  /* @alternate */
  color: var(--mdc-theme-primary, #6200ee);
  fill: currentColor;
}

.mdc-tab {
  background: none;
}

.mdc-tab {
  min-width: 90px;
  padding-right: 24px;
  padding-left: 24px;
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
  box-sizing: border-box;
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  border: none;
  outline: none;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  -webkit-appearance: none;
  z-index: 1;
}

.mdc-tab::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mdc-tab--min-width {
  flex: 0 1 auto;
}

.mdc-tab__content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  pointer-events: none;
}

.mdc-tab__text-label {
  transition: 150ms color linear;
  display: inline-block;
  line-height: 1;
  z-index: 2;
}

.mdc-tab__icon {
  transition: 150ms color linear;
  z-index: 2;
}

.mdc-tab--stacked .mdc-tab__content {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mdc-tab--stacked .mdc-tab__text-label {
  padding-top: 6px;
  padding-bottom: 4px;
}

.mdc-tab--active .mdc-tab__text-label,
.mdc-tab--active .mdc-tab__icon {
  transition-delay: 100ms;
}

.mdc-tab:not(.mdc-tab--stacked) .mdc-tab__icon + .mdc-tab__text-label {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}

[dir=rtl] .mdc-tab:not(.mdc-tab--stacked) .mdc-tab__icon + .mdc-tab__text-label, .mdc-tab:not(.mdc-tab--stacked) .mdc-tab__icon + .mdc-tab__text-label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 8px;
  /*rtl:end:ignore*/
}

.mdc-tab {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.mdc-tab .mdc-tab__ripple::before,
.mdc-tab .mdc-tab__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}

.mdc-tab .mdc-tab__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}

.mdc-tab .mdc-tab__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}

.mdc-tab.mdc-ripple-upgraded .mdc-tab__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-tab.mdc-ripple-upgraded .mdc-tab__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}

.mdc-tab.mdc-ripple-upgraded--unbounded .mdc-tab__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}

.mdc-tab.mdc-ripple-upgraded--foreground-activation .mdc-tab__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}

.mdc-tab.mdc-ripple-upgraded--foreground-deactivation .mdc-tab__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-tab .mdc-tab__ripple::before,
.mdc-tab .mdc-tab__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}

.mdc-tab.mdc-ripple-upgraded .mdc-tab__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-tab .mdc-tab__ripple::before, .mdc-tab .mdc-tab__ripple::after {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #6200ee));
}

.mdc-tab:hover .mdc-tab__ripple::before, .mdc-tab.mdc-ripple-surface--hover .mdc-tab__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}

.mdc-tab.mdc-ripple-upgraded--background-focused .mdc-tab__ripple::before, .mdc-tab:not(.mdc-ripple-upgraded):focus .mdc-tab__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}

.mdc-tab:not(.mdc-ripple-upgraded) .mdc-tab__ripple::after {
  transition: opacity 150ms linear;
}

.mdc-tab:not(.mdc-ripple-upgraded):active .mdc-tab__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-tab.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-tab__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  will-change: transform, opacity;
}

/**
 * @license
 * Copyright 2018 Google Inc.
 *
 * Permission is hereby granted, free of charge, to any person obtaining a copy
 * of this software and associated documentation files (the "Software"), to deal
 * in the Software without restriction, including without limitation the rights
 * to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
 * copies of the Software, and to permit persons to whom the Software is
 * furnished to do so, subject to the following conditions:
 *
 * The above copyright notice and this permission notice shall be included in
 * all copies or substantial portions of the Software.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 * IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
 * AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 * LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
 * OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
 * THE SOFTWARE.
 */
.mdc-tab-bar {
  width: 100%;
}

.mdc-tab {
  height: 48px;
}

.mdc-tab--stacked {
  height: 72px;
}

/**
 * @license
 * Copyright 2018 Google Inc.
 *
 * Permission is hereby granted, free of charge, to any person obtaining a copy
 * of this software and associated documentation files (the "Software"), to deal
 * in the Software without restriction, including without limitation the rights
 * to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
 * copies of the Software, and to permit persons to whom the Software is
 * furnished to do so, subject to the following conditions:
 *
 * The above copyright notice and this permission notice shall be included in
 * all copies or substantial portions of the Software.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 * IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
 * AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 * LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
 * OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
 * THE SOFTWARE.
 */
.mdc-tab-indicator .mdc-tab-indicator__content--underline {
  border-color: #6200ee;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #6200ee);
}

.mdc-tab-indicator .mdc-tab-indicator__content--icon {
  color: #018786;
  /* @alternate */
  color: var(--mdc-theme-secondary, #018786);
}

.mdc-tab-indicator .mdc-tab-indicator__content--underline {
  border-top-width: 2px;
}

.mdc-tab-indicator .mdc-tab-indicator__content--icon {
  height: 34px;
  font-size: 34px;
}

.mdc-tab-indicator {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1;
}

.mdc-tab-indicator__content {
  transform-origin: left;
  opacity: 0;
}

.mdc-tab-indicator__content--underline {
  align-self: flex-end;
  box-sizing: border-box;
  width: 100%;
  border-top-style: solid;
}

.mdc-tab-indicator__content--icon {
  align-self: center;
  margin: 0 auto;
}

.mdc-tab-indicator--active .mdc-tab-indicator__content {
  opacity: 1;
}

.mdc-tab-indicator .mdc-tab-indicator__content {
  transition: 250ms transform cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-tab-indicator--no-transition .mdc-tab-indicator__content {
  transition: none;
}

.mdc-tab-indicator--fade .mdc-tab-indicator__content {
  transition: 150ms opacity linear;
}

.mdc-tab-indicator--active.mdc-tab-indicator--fade .mdc-tab-indicator__content {
  transition-delay: 100ms;
}

/**
 * @license
 * Copyright 2018 Google Inc.
 *
 * Permission is hereby granted, free of charge, to any person obtaining a copy
 * of this software and associated documentation files (the "Software"), to deal
 * in the Software without restriction, including without limitation the rights
 * to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
 * copies of the Software, and to permit persons to whom the Software is
 * furnished to do so, subject to the following conditions:
 *
 * The above copyright notice and this permission notice shall be included in
 * all copies or substantial portions of the Software.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 * IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
 * AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 * LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
 * OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
 * THE SOFTWARE.
 */
.mdc-tab-scroller {
  overflow-y: hidden;
}

.mdc-tab-scroller.mdc-tab-scroller--animating .mdc-tab-scroller__scroll-content {
  transition: 250ms transform cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-tab-scroller__test {
  position: absolute;
  top: -9999px;
  width: 100px;
  height: 100px;
  overflow-x: scroll;
}

.mdc-tab-scroller__scroll-area {
  -webkit-overflow-scrolling: touch;
  display: flex;
  overflow-x: hidden;
}

.mdc-tab-scroller__scroll-area::-webkit-scrollbar,
.mdc-tab-scroller__test::-webkit-scrollbar {
  display: none;
}

.mdc-tab-scroller__scroll-area--scroll {
  overflow-x: scroll;
}

.mdc-tab-scroller__scroll-content {
  position: relative;
  display: flex;
  flex: 1 0 auto;
  transform: none;
  will-change: transform;
}

.mdc-tab-scroller--align-start .mdc-tab-scroller__scroll-content {
  justify-content: flex-start;
}

.mdc-tab-scroller--align-end .mdc-tab-scroller__scroll-content {
  justify-content: flex-end;
}

.mdc-tab-scroller--align-center .mdc-tab-scroller__scroll-content {
  justify-content: center;
}

.mdc-tab-scroller--animating .mdc-tab-scroller__scroll-area {
  -webkit-overflow-scrolling: auto;
}

.mdc-text-field--filled {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}

.mdc-text-field--filled .mdc-text-field__ripple::before,
.mdc-text-field--filled .mdc-text-field__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}

.mdc-text-field--filled .mdc-text-field__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}

.mdc-text-field--filled .mdc-text-field__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}

.mdc-text-field--filled.mdc-ripple-upgraded .mdc-text-field__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-text-field--filled.mdc-ripple-upgraded .mdc-text-field__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}

.mdc-text-field--filled.mdc-ripple-upgraded--unbounded .mdc-text-field__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}

.mdc-text-field--filled.mdc-ripple-upgraded--foreground-activation .mdc-text-field__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}

.mdc-text-field--filled.mdc-ripple-upgraded--foreground-deactivation .mdc-text-field__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-text-field--filled .mdc-text-field__ripple::before,
.mdc-text-field--filled .mdc-text-field__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}

.mdc-text-field--filled.mdc-ripple-upgraded .mdc-text-field__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-text-field__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.mdc-text-field {
  border-top-left-radius: 4px;
  /* @alternate */
  border-top-left-radius: var(--mdc-shape-small, 4px);
  border-top-right-radius: 4px;
  /* @alternate */
  border-top-right-radius: var(--mdc-shape-small, 4px);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  display: inline-flex;
  align-items: baseline;
  padding: 0 16px;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  /* @alternate */
  will-change: opacity, transform, color;
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.87);
}

@media all {
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.54);
  }
}
@media all {
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.54);
  }
}
.mdc-text-field .mdc-text-field__input {
  caret-color: #6200ee;
  /* @alternate */
  caret-color: var(--mdc-theme-primary, #6200ee);
}

.mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.6);
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.6);
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.6);
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.6);
}

.mdc-text-field .mdc-floating-label {
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.mdc-text-field__input {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  height: 28px;
  transition: opacity 150ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
  width: 100%;
  min-width: 0;
  border: none;
  border-radius: 0;
  background: none;
  appearance: none;
  padding: 0;
}

.mdc-text-field__input::-ms-clear {
  display: none;
}

.mdc-text-field__input::-webkit-calendar-picker-indicator {
  display: none;
}

.mdc-text-field__input:focus {
  outline: none;
}

.mdc-text-field__input:invalid {
  box-shadow: none;
}

@media all {
  .mdc-text-field__input::placeholder {
    transition: opacity 67ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
  }
}
@media all {
  .mdc-text-field__input:-ms-input-placeholder {
    transition: opacity 67ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
  }
}
@media all {
  .mdc-text-field--no-label .mdc-text-field__input::placeholder, .mdc-text-field--focused .mdc-text-field__input::placeholder {
    transition-delay: 40ms;
    transition-duration: 110ms;
    opacity: 1;
  }
}
@media all {
  .mdc-text-field--no-label .mdc-text-field__input:-ms-input-placeholder, .mdc-text-field--focused .mdc-text-field__input:-ms-input-placeholder {
    transition-delay: 40ms;
    transition-duration: 110ms;
    opacity: 1;
  }
}
.mdc-text-field__affix {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  height: 28px;
  transition: opacity 150ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  white-space: nowrap;
}

.mdc-text-field--label-floating .mdc-text-field__affix, .mdc-text-field--no-label .mdc-text-field__affix {
  opacity: 1;
}

@supports (-webkit-hyphens: none) {
  .mdc-text-field--outlined .mdc-text-field__affix {
    align-items: center;
    align-self: center;
    display: inline-flex;
    height: 100%;
  }
}
.mdc-text-field__affix--prefix {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 2px;
}

[dir=rtl] .mdc-text-field__affix--prefix, .mdc-text-field__affix--prefix[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 2px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-text-field--end-aligned .mdc-text-field__affix--prefix {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 12px;
}

[dir=rtl] .mdc-text-field--end-aligned .mdc-text-field__affix--prefix, .mdc-text-field--end-aligned .mdc-text-field__affix--prefix[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-text-field__affix--suffix {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}

[dir=rtl] .mdc-text-field__affix--suffix, .mdc-text-field__affix--suffix[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 12px;
  /*rtl:end:ignore*/
}

.mdc-text-field--end-aligned .mdc-text-field__affix--suffix {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 2px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}

[dir=rtl] .mdc-text-field--end-aligned .mdc-text-field__affix--suffix, .mdc-text-field--end-aligned .mdc-text-field__affix--suffix[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 2px;
  /*rtl:end:ignore*/
}

.mdc-text-field--filled {
  height: 56px;
}

.mdc-text-field--filled .mdc-text-field__ripple::before,
.mdc-text-field--filled .mdc-text-field__ripple::after {
  background-color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.87));
}

.mdc-text-field--filled:hover .mdc-text-field__ripple::before, .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}

.mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before, .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}

.mdc-text-field--filled::before {
  display: inline-block;
  width: 0;
  height: 40px;
  content: "";
  vertical-align: 0;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: whitesmoke;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}

.mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}

.mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: #6200ee;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-primary, #6200ee);
}

.mdc-text-field--filled .mdc-floating-label {
  /* @noflip */ /*rtl:ignore*/
  left: 16px;
  /* @noflip */ /*rtl:ignore*/
  right: initial;
}

[dir=rtl] .mdc-text-field--filled .mdc-floating-label, .mdc-text-field--filled .mdc-floating-label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  left: initial;
  /* @noflip */ /*rtl:ignore*/
  right: 16px;
  /*rtl:end:ignore*/
}

.mdc-text-field--filled .mdc-floating-label--float-above {
  transform: translateY(-106%) scale(0.75);
}

.mdc-text-field--filled.mdc-text-field--no-label .mdc-text-field__input {
  height: 100%;
}

.mdc-text-field--filled.mdc-text-field--no-label .mdc-floating-label {
  display: none;
}

.mdc-text-field--filled.mdc-text-field--no-label::before {
  display: none;
}

@supports (-webkit-hyphens: none) {
  .mdc-text-field--filled.mdc-text-field--no-label .mdc-text-field__affix {
    align-items: center;
    align-self: center;
    display: inline-flex;
    height: 100%;
  }
}
.mdc-text-field--outlined {
  height: 56px;
  overflow: visible;
}

.mdc-text-field--outlined .mdc-floating-label--float-above {
  transform: translateY(-37.25px) scale(1);
}

.mdc-text-field--outlined .mdc-floating-label--float-above {
  font-size: 0.75rem;
}

.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-34.75px) scale(0.75);
}

.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}

.mdc-text-field--outlined .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined 250ms 1;
}

@keyframes mdc-floating-label-shake-float-above-text-field-outlined {
  0% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - 0%)) translateY(-34.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(4% - 0%)) translateY(-34.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(-4% - 0%)) translateY(-34.75px) scale(0.75);
  }
  100% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - 0%)) translateY(-34.75px) scale(0.75);
  }
}
.mdc-text-field--outlined .mdc-text-field__input {
  height: 100%;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.38);
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.87);
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: #6200ee;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #6200ee);
}

.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
  /* @noflip */ /*rtl:ignore*/
  border-top-left-radius: 4px;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-top-left-radius: var(--mdc-shape-small, 4px);
  /* @noflip */ /*rtl:ignore*/
  border-top-right-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-bottom-right-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-bottom-left-radius: 4px;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-bottom-left-radius: var(--mdc-shape-small, 4px);
}

[dir=rtl] .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading, .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  border-top-left-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-top-right-radius: 4px;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-top-right-radius: var(--mdc-shape-small, 4px);
  /* @noflip */ /*rtl:ignore*/
  border-bottom-right-radius: 4px;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-bottom-right-radius: var(--mdc-shape-small, 4px);
  /* @noflip */ /*rtl:ignore*/
  border-bottom-left-radius: 0;
  /*rtl:end:ignore*/
}

@supports (top: max(0%)) {
  .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
    width: max(12px, var(--mdc-shape-small, 4px));
  }
}
@supports (top: max(0%)) {
  .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__notch {
    max-width: calc(100% - max(12px, var(--mdc-shape-small, 4px)) * 2);
  }
}
.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
  /* @noflip */ /*rtl:ignore*/
  border-top-left-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-top-right-radius: 4px;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-top-right-radius: var(--mdc-shape-small, 4px);
  /* @noflip */ /*rtl:ignore*/
  border-bottom-right-radius: 4px;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-bottom-right-radius: var(--mdc-shape-small, 4px);
  /* @noflip */ /*rtl:ignore*/
  border-bottom-left-radius: 0;
}

[dir=rtl] .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing, .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  border-top-left-radius: 4px;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-top-left-radius: var(--mdc-shape-small, 4px);
  /* @noflip */ /*rtl:ignore*/
  border-top-right-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-bottom-right-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-bottom-left-radius: 4px;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-bottom-left-radius: var(--mdc-shape-small, 4px);
  /*rtl:end:ignore*/
}

@supports (top: max(0%)) {
  .mdc-text-field--outlined {
    /* @noflip */ /*rtl:ignore*/
    padding-left: max(16px, calc(var(--mdc-shape-small, 4px) + 4px));
  }
}
@supports (top: max(0%)) {
  .mdc-text-field--outlined {
    /* @noflip */ /*rtl:ignore*/
    padding-right: max(16px, var(--mdc-shape-small, 4px));
  }
}
@supports (top: max(0%)) {
  .mdc-text-field--outlined + .mdc-text-field-helper-line {
    /* @noflip */ /*rtl:ignore*/
    padding-left: max(16px, calc(var(--mdc-shape-small, 4px) + 4px));
  }
}
@supports (top: max(0%)) {
  .mdc-text-field--outlined + .mdc-text-field-helper-line {
    /* @noflip */ /*rtl:ignore*/
    padding-right: max(16px, var(--mdc-shape-small, 4px));
  }
}
.mdc-text-field--outlined.mdc-text-field--with-leading-icon {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
}

@supports (top: max(0%)) {
  .mdc-text-field--outlined.mdc-text-field--with-leading-icon {
    /* @noflip */ /*rtl:ignore*/
    padding-right: max(16px, var(--mdc-shape-small, 4px));
  }
}
[dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

@supports (top: max(0%)) {
  [dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
    /* @noflip */ /*rtl:ignore*/
    padding-left: max(16px, var(--mdc-shape-small, 4px));
  }
}
.mdc-text-field--outlined.mdc-text-field--with-trailing-icon {
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}

@supports (top: max(0%)) {
  .mdc-text-field--outlined.mdc-text-field--with-trailing-icon {
    /* @noflip */ /*rtl:ignore*/
    padding-left: max(16px, calc(var(--mdc-shape-small, 4px) + 4px));
  }
}
[dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /*rtl:end:ignore*/
}

@supports (top: max(0%)) {
  [dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
    /* @noflip */ /*rtl:ignore*/
    padding-right: max(16px, calc(var(--mdc-shape-small, 4px) + 4px));
  }
}
.mdc-text-field--outlined.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}

.mdc-text-field--outlined .mdc-notched-outline--notched .mdc-notched-outline__notch {
  padding-top: 1px;
}

.mdc-text-field--outlined .mdc-text-field__ripple::before,
.mdc-text-field--outlined .mdc-text-field__ripple::after {
  background-color: transparent;
  /* @alternate */
  background-color: var(--mdc-ripple-color, transparent);
}

.mdc-text-field--outlined .mdc-floating-label {
  /* @noflip */ /*rtl:ignore*/
  left: 4px;
  /* @noflip */ /*rtl:ignore*/
  right: initial;
}

[dir=rtl] .mdc-text-field--outlined .mdc-floating-label, .mdc-text-field--outlined .mdc-floating-label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  left: initial;
  /* @noflip */ /*rtl:ignore*/
  right: 4px;
  /*rtl:end:ignore*/
}

.mdc-text-field--outlined .mdc-text-field__input {
  display: flex;
  border: none !important;
  background-color: transparent;
}

.mdc-text-field--outlined .mdc-notched-outline {
  z-index: 1;
}

.mdc-text-field--textarea {
  flex-direction: column;
  align-items: center;
  width: auto;
  height: auto;
  padding: 0;
  transition: none;
}

.mdc-text-field--textarea .mdc-floating-label {
  top: 19px;
}

.mdc-text-field--textarea .mdc-floating-label:not(.mdc-floating-label--float-above) {
  transform: none;
}

.mdc-text-field--textarea .mdc-text-field__input {
  flex-grow: 1;
  height: auto;
  min-height: 1.5rem;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  resize: none;
  padding: 0 16px;
  line-height: 1.5rem;
}

.mdc-text-field--textarea.mdc-text-field--filled::before {
  display: none;
}

.mdc-text-field--textarea.mdc-text-field--filled .mdc-floating-label--float-above {
  transform: translateY(-10.25px) scale(0.75);
}

.mdc-text-field--textarea.mdc-text-field--filled .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-textarea-filled 250ms 1;
}

@keyframes mdc-floating-label-shake-float-above-textarea-filled {
  0% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - 0%)) translateY(-10.25px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(4% - 0%)) translateY(-10.25px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(-4% - 0%)) translateY(-10.25px) scale(0.75);
  }
  100% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - 0%)) translateY(-10.25px) scale(0.75);
  }
}
.mdc-text-field--textarea.mdc-text-field--filled .mdc-text-field__input {
  margin-top: 23px;
  margin-bottom: 9px;
}

.mdc-text-field--textarea.mdc-text-field--filled.mdc-text-field--no-label .mdc-text-field__input {
  margin-top: 16px;
  margin-bottom: 16px;
}

.mdc-text-field--textarea.mdc-text-field--outlined .mdc-notched-outline--notched .mdc-notched-outline__notch {
  padding-top: 0;
}

.mdc-text-field--textarea.mdc-text-field--outlined .mdc-floating-label--float-above {
  transform: translateY(-27.25px) scale(1);
}

.mdc-text-field--textarea.mdc-text-field--outlined .mdc-floating-label--float-above {
  font-size: 0.75rem;
}

.mdc-text-field--textarea.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--textarea.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-24.75px) scale(0.75);
}

.mdc-text-field--textarea.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--textarea.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}

.mdc-text-field--textarea.mdc-text-field--outlined .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-textarea-outlined 250ms 1;
}

@keyframes mdc-floating-label-shake-float-above-textarea-outlined {
  0% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - 0%)) translateY(-24.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(4% - 0%)) translateY(-24.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(-4% - 0%)) translateY(-24.75px) scale(0.75);
  }
  100% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - 0%)) translateY(-24.75px) scale(0.75);
  }
}
.mdc-text-field--textarea.mdc-text-field--outlined .mdc-text-field__input {
  margin-top: 16px;
  margin-bottom: 16px;
}

.mdc-text-field--textarea.mdc-text-field--outlined .mdc-floating-label {
  top: 18px;
}

.mdc-text-field--textarea.mdc-text-field--with-internal-counter .mdc-text-field__input {
  margin-bottom: 2px;
}

.mdc-text-field--textarea.mdc-text-field--with-internal-counter .mdc-text-field-character-counter {
  align-self: flex-end;
  padding: 0 16px;
}

.mdc-text-field--textarea.mdc-text-field--with-internal-counter .mdc-text-field-character-counter::after {
  display: inline-block;
  width: 0;
  height: 16px;
  content: "";
  vertical-align: -16px;
}

.mdc-text-field--textarea.mdc-text-field--with-internal-counter .mdc-text-field-character-counter::before {
  display: none;
}

.mdc-text-field__resizer {
  align-self: stretch;
  display: inline-flex;
  flex-direction: column;
  flex-grow: 1;
  max-height: 100%;
  max-width: 100%;
  min-height: 56px;
  min-width: fit-content;
  /* @alternate */
  min-width: -moz-available;
  /* @alternate */
  min-width: -webkit-fill-available;
  overflow: hidden;
  resize: both;
}

.mdc-text-field--filled .mdc-text-field__resizer {
  transform: translateY(-1px);
}

.mdc-text-field--filled .mdc-text-field__resizer .mdc-text-field__input,
.mdc-text-field--filled .mdc-text-field__resizer .mdc-text-field-character-counter {
  transform: translateY(1px);
}

.mdc-text-field--outlined .mdc-text-field__resizer {
  /* @noflip */ /*rtl:ignore*/
  transform: translateX(-1px) translateY(-1px);
}

[dir=rtl] .mdc-text-field--outlined .mdc-text-field__resizer, .mdc-text-field--outlined .mdc-text-field__resizer[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  transform: translateX(1px) translateY(-1px);
  /*rtl:end:ignore*/
}

.mdc-text-field--outlined .mdc-text-field__resizer .mdc-text-field__input,
.mdc-text-field--outlined .mdc-text-field__resizer .mdc-text-field-character-counter {
  /* @noflip */ /*rtl:ignore*/
  transform: translateX(1px) translateY(1px);
}

[dir=rtl] .mdc-text-field--outlined .mdc-text-field__resizer .mdc-text-field__input, [dir=rtl] .mdc-text-field--outlined .mdc-text-field__resizer .mdc-text-field-character-counter, .mdc-text-field--outlined .mdc-text-field__resizer .mdc-text-field__input[dir=rtl], .mdc-text-field--outlined .mdc-text-field__resizer .mdc-text-field-character-counter[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  transform: translateX(-1px) translateY(1px);
  /*rtl:end:ignore*/
}

.mdc-text-field--with-leading-icon {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
}

[dir=rtl] .mdc-text-field--with-leading-icon, .mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-text-field--with-leading-icon.mdc-text-field--filled .mdc-floating-label {
  max-width: calc(100% - 48px);
  /* @noflip */ /*rtl:ignore*/
  left: 48px;
  /* @noflip */ /*rtl:ignore*/
  right: initial;
}

[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--filled .mdc-floating-label, .mdc-text-field--with-leading-icon.mdc-text-field--filled .mdc-floating-label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  left: initial;
  /* @noflip */ /*rtl:ignore*/
  right: 48px;
  /*rtl:end:ignore*/
}

.mdc-text-field--with-leading-icon.mdc-text-field--filled .mdc-floating-label--float-above {
  max-width: calc(100% / 0.75 - 64px / 0.75);
}

.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label {
  /* @noflip */ /*rtl:ignore*/
  left: 36px;
  /* @noflip */ /*rtl:ignore*/
  right: initial;
}

[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label, .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  left: initial;
  /* @noflip */ /*rtl:ignore*/
  right: 36px;
  /*rtl:end:ignore*/
}

.mdc-text-field--with-leading-icon.mdc-text-field--outlined :not(.mdc-notched-outline--notched) .mdc-notched-outline__notch {
  max-width: calc(100% - 60px);
}

.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above {
  /* @noflip */ /*rtl:ignore*/
  transform: translateY(-37.25px) translateX(-32px) scale(1);
}

[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above, .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  transform: translateY(-37.25px) translateX(32px) scale(1);
  /*rtl:end:ignore*/
}

.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above {
  font-size: 0.75rem;
}

.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  /* @noflip */ /*rtl:ignore*/
  transform: translateY(-34.75px) translateX(-32px) scale(0.75);
}

[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above, [dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above, .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above[dir=rtl], .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  transform: translateY(-34.75px) translateX(32px) scale(0.75);
  /*rtl:end:ignore*/
}

.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}

.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon 250ms 1;
}

@keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon {
  0% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - 32px)) translateY(-34.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(4% - 32px)) translateY(-34.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(-4% - 32px)) translateY(-34.75px) scale(0.75);
  }
  100% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - 32px)) translateY(-34.75px) scale(0.75);
  }
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--shake, .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon 250ms 1;
}

@keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-rtl {
  0% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - -32px)) translateY(-34.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(4% - -32px)) translateY(-34.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(-4% - -32px)) translateY(-34.75px) scale(0.75);
  }
  100% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - -32px)) translateY(-34.75px) scale(0.75);
  }
}
.mdc-text-field--with-trailing-icon {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}

[dir=rtl] .mdc-text-field--with-trailing-icon, .mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-text-field--with-trailing-icon.mdc-text-field--filled .mdc-floating-label {
  max-width: calc(100% - 64px);
}

.mdc-text-field--with-trailing-icon.mdc-text-field--filled .mdc-floating-label--float-above {
  max-width: calc(100% / 0.75 - 64px / 0.75);
}

.mdc-text-field--with-trailing-icon.mdc-text-field--outlined :not(.mdc-notched-outline--notched) .mdc-notched-outline__notch {
  max-width: calc(100% - 60px);
}

.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}

.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--filled .mdc-floating-label {
  max-width: calc(100% - 96px);
}

.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--filled .mdc-floating-label--float-above {
  max-width: calc(100% / 0.75 - 96px / 0.75);
}

.mdc-text-field-helper-line {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}

.mdc-text-field + .mdc-text-field-helper-line {
  padding-right: 16px;
  padding-left: 16px;
}

.mdc-form-field > .mdc-text-field + label {
  align-self: flex-start;
}

.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(98, 0, 238, 0.87);
}

.mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--focused .mdc-notched-outline__trailing {
  border-width: 2px;
}

.mdc-text-field--focused + .mdc-text-field-helper-line .mdc-text-field-helper-text:not(.mdc-text-field-helper-text--validation-msg) {
  opacity: 1;
}

.mdc-text-field--focused.mdc-text-field--outlined .mdc-notched-outline--notched .mdc-notched-outline__notch {
  padding-top: 2px;
}

.mdc-text-field--focused.mdc-text-field--outlined.mdc-text-field--textarea .mdc-notched-outline--notched .mdc-notched-outline__notch {
  padding-top: 0;
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: #b00020;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #b00020);
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: #b00020;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #b00020);
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: #b00020;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020);
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: #b00020;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020);
}

.mdc-text-field--invalid .mdc-text-field__input {
  caret-color: #b00020;
  /* @alternate */
  caret-color: var(--mdc-theme-error, #b00020);
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: #b00020;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020);
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: #b00020;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #b00020);
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020);
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020);
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020);
}

.mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  opacity: 1;
}

.mdc-text-field--disabled {
  pointer-events: none;
}

.mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.38);
}

@media all {
  .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
@media all {
  .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
.mdc-text-field--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.38);
}

.mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.38);
}

.mdc-text-field--disabled .mdc-text-field-character-counter,
.mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.38);
}

.mdc-text-field--disabled .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.3);
}

.mdc-text-field--disabled .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.3);
}

.mdc-text-field--disabled .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.38);
}

.mdc-text-field--disabled .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.38);
}

.mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.06);
}

.mdc-text-field--disabled .mdc-notched-outline__leading,
.mdc-text-field--disabled .mdc-notched-outline__notch,
.mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.06);
}

@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-floating-label {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field-character-counter,
  .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__icon--leading {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__icon--trailing {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__affix--prefix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__affix--suffix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-notched-outline__leading,
  .mdc-text-field--disabled .mdc-notched-outline__notch,
  .mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
}
@media screen and (forced-colors: active) {
  .mdc-text-field--disabled .mdc-text-field__input {
    background-color: Window;
  }
  .mdc-text-field--disabled .mdc-floating-label {
    z-index: 1;
  }
}
.mdc-text-field--disabled .mdc-floating-label {
  cursor: default;
}

.mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #fafafa;
}

.mdc-text-field--disabled.mdc-text-field--filled .mdc-text-field__ripple {
  display: none;
}

.mdc-text-field--disabled .mdc-text-field__input {
  pointer-events: auto;
}

.mdc-text-field--end-aligned .mdc-text-field__input {
  /* @noflip */ /*rtl:ignore*/
  text-align: right;
}

[dir=rtl] .mdc-text-field--end-aligned .mdc-text-field__input, .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  text-align: left;
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-text-field--ltr-text, .mdc-text-field--ltr-text[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-text-field--ltr-text .mdc-text-field__input,
[dir=rtl] .mdc-text-field--ltr-text .mdc-text-field__affix, .mdc-text-field--ltr-text[dir=rtl] .mdc-text-field__input,
.mdc-text-field--ltr-text[dir=rtl] .mdc-text-field__affix {
  /* @noflip */ /*rtl:ignore*/
  direction: ltr;
}

[dir=rtl] .mdc-text-field--ltr-text .mdc-text-field__affix--prefix, .mdc-text-field--ltr-text[dir=rtl] .mdc-text-field__affix--prefix {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 2px;
}

[dir=rtl] .mdc-text-field--ltr-text .mdc-text-field__affix--suffix, .mdc-text-field--ltr-text[dir=rtl] .mdc-text-field__affix--suffix {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}

[dir=rtl] .mdc-text-field--ltr-text .mdc-text-field__icon--leading, .mdc-text-field--ltr-text[dir=rtl] .mdc-text-field__icon--leading {
  order: 1;
}

[dir=rtl] .mdc-text-field--ltr-text .mdc-text-field__affix--suffix, .mdc-text-field--ltr-text[dir=rtl] .mdc-text-field__affix--suffix {
  order: 2;
}

[dir=rtl] .mdc-text-field--ltr-text .mdc-text-field__input, .mdc-text-field--ltr-text[dir=rtl] .mdc-text-field__input {
  order: 3;
}

[dir=rtl] .mdc-text-field--ltr-text .mdc-text-field__affix--prefix, .mdc-text-field--ltr-text[dir=rtl] .mdc-text-field__affix--prefix {
  order: 4;
}

[dir=rtl] .mdc-text-field--ltr-text .mdc-text-field__icon--trailing, .mdc-text-field--ltr-text[dir=rtl] .mdc-text-field__icon--trailing {
  order: 5;
}

[dir=rtl] .mdc-text-field--ltr-text.mdc-text-field--end-aligned, .mdc-text-field--ltr-text.mdc-text-field--end-aligned[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-text-field--ltr-text.mdc-text-field--end-aligned .mdc-text-field__input, .mdc-text-field--ltr-text.mdc-text-field--end-aligned[dir=rtl] .mdc-text-field__input {
  /* @noflip */ /*rtl:ignore*/
  text-align: right;
}

[dir=rtl] .mdc-text-field--ltr-text.mdc-text-field--end-aligned .mdc-text-field__affix--prefix, .mdc-text-field--ltr-text.mdc-text-field--end-aligned[dir=rtl] .mdc-text-field__affix--prefix {
  /* @noflip */ /*rtl:ignore*/
  padding-right: 12px;
}

[dir=rtl] .mdc-text-field--ltr-text.mdc-text-field--end-aligned .mdc-text-field__affix--suffix, .mdc-text-field--ltr-text.mdc-text-field--end-aligned[dir=rtl] .mdc-text-field__affix--suffix {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 2px;
}

.mdc-text-field-helper-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 0.75rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: 0.0333333333em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin: 0;
  opacity: 0;
  will-change: opacity;
  transition: opacity 150ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-text-field-helper-text::before {
  display: inline-block;
  width: 0;
  height: 16px;
  content: "";
  vertical-align: 0;
}

.mdc-text-field-helper-text--persistent {
  transition: none;
  opacity: 1;
  will-change: initial;
}

.mdc-text-field-character-counter {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 0.75rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: 0.0333333333em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  /* @noflip */ /*rtl:ignore*/
  margin-left: auto;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  white-space: nowrap;
}

.mdc-text-field-character-counter::before {
  display: inline-block;
  width: 0;
  height: 16px;
  content: "";
  vertical-align: 0;
}

[dir=rtl] .mdc-text-field-character-counter, .mdc-text-field-character-counter[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: auto;
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-text-field-character-counter, .mdc-text-field-character-counter[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-text-field__icon {
  align-self: center;
  cursor: pointer;
}

.mdc-text-field__icon:not([tabindex]), .mdc-text-field__icon[tabindex="-1"] {
  cursor: default;
  pointer-events: none;
}

.mdc-text-field__icon svg {
  display: block;
}

.mdc-text-field__icon--leading {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 8px;
}

[dir=rtl] .mdc-text-field__icon--leading, .mdc-text-field__icon--leading[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-text-field__icon--trailing {
  padding: 12px;
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0px;
}

[dir=rtl] .mdc-text-field__icon--trailing, .mdc-text-field__icon--trailing[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0px;
  /*rtl:end:ignore*/
}

:root {
  --mdc-theme-primary:#6200ee;
  --mdc-theme-secondary:#018786;
  --mdc-theme-background:#fff;
  --mdc-theme-surface:#fff;
  --mdc-theme-error:#b00020;
  --mdc-theme-on-primary:#fff;
  --mdc-theme-on-secondary:#fff;
  --mdc-theme-on-surface:#000;
  --mdc-theme-on-error:#fff;
  --mdc-theme-text-primary-on-background:rgba(0, 0, 0, 0.87);
  --mdc-theme-text-secondary-on-background:rgba(0, 0, 0, 0.54);
  --mdc-theme-text-hint-on-background:rgba(0, 0, 0, 0.38);
  --mdc-theme-text-disabled-on-background:rgba(0, 0, 0, 0.38);
  --mdc-theme-text-icon-on-background:rgba(0, 0, 0, 0.38);
  --mdc-theme-text-primary-on-light:rgba(0, 0, 0, 0.87);
  --mdc-theme-text-secondary-on-light:rgba(0, 0, 0, 0.54);
  --mdc-theme-text-hint-on-light:rgba(0, 0, 0, 0.38);
  --mdc-theme-text-disabled-on-light:rgba(0, 0, 0, 0.38);
  --mdc-theme-text-icon-on-light:rgba(0, 0, 0, 0.38);
  --mdc-theme-text-primary-on-dark:white;
  --mdc-theme-text-secondary-on-dark:rgba(255, 255, 255, 0.7);
  --mdc-theme-text-hint-on-dark:rgba(255, 255, 255, 0.5);
  --mdc-theme-text-disabled-on-dark:rgba(255, 255, 255, 0.5);
  --mdc-theme-text-icon-on-dark:rgba(255, 255, 255, 0.5);
}

.mdc-theme--primary {
  color: #6200ee  !important;
  /* @alternate */
  color: var(--mdc-theme-primary, #6200ee) !important;
}

.mdc-theme--secondary {
  color: #018786  !important;
  /* @alternate */
  color: var(--mdc-theme-secondary, #018786) !important;
}

.mdc-theme--background {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-background, #fff);
}

.mdc-theme--surface {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
}

.mdc-theme--error {
  color: #b00020  !important;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020) !important;
}

.mdc-theme--on-primary {
  color: #fff  !important;
  /* @alternate */
  color: var(--mdc-theme-on-primary, #fff) !important;
}

.mdc-theme--on-secondary {
  color: #fff  !important;
  /* @alternate */
  color: var(--mdc-theme-on-secondary, #fff) !important;
}

.mdc-theme--on-surface {
  color: #000  !important;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000) !important;
}

.mdc-theme--on-error {
  color: #fff  !important;
  /* @alternate */
  color: var(--mdc-theme-on-error, #fff) !important;
}

.mdc-theme--text-primary-on-background {
  color: rgba(0, 0, 0, 0.87)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87)) !important;
}

.mdc-theme--text-secondary-on-background {
  color: rgba(0, 0, 0, 0.54)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54)) !important;
}

.mdc-theme--text-hint-on-background {
  color: rgba(0, 0, 0, 0.38)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38)) !important;
}

.mdc-theme--text-disabled-on-background {
  color: rgba(0, 0, 0, 0.38)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-disabled-on-background, rgba(0, 0, 0, 0.38)) !important;
}

.mdc-theme--text-icon-on-background {
  color: rgba(0, 0, 0, 0.38)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38)) !important;
}

.mdc-theme--text-primary-on-light {
  color: rgba(0, 0, 0, 0.87)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87)) !important;
}

.mdc-theme--text-secondary-on-light {
  color: rgba(0, 0, 0, 0.54)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-light, rgba(0, 0, 0, 0.54)) !important;
}

.mdc-theme--text-hint-on-light {
  color: rgba(0, 0, 0, 0.38)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-light, rgba(0, 0, 0, 0.38)) !important;
}

.mdc-theme--text-disabled-on-light {
  color: rgba(0, 0, 0, 0.38)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-disabled-on-light, rgba(0, 0, 0, 0.38)) !important;
}

.mdc-theme--text-icon-on-light {
  color: rgba(0, 0, 0, 0.38)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-light, rgba(0, 0, 0, 0.38)) !important;
}

.mdc-theme--text-primary-on-dark {
  color: white  !important;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, white) !important;
}

.mdc-theme--text-secondary-on-dark {
  color: rgba(255, 255, 255, 0.7)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-dark, rgba(255, 255, 255, 0.7)) !important;
}

.mdc-theme--text-hint-on-dark {
  color: rgba(255, 255, 255, 0.5)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-dark, rgba(255, 255, 255, 0.5)) !important;
}

.mdc-theme--text-disabled-on-dark {
  color: rgba(255, 255, 255, 0.5)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-disabled-on-dark, rgba(255, 255, 255, 0.5)) !important;
}

.mdc-theme--text-icon-on-dark {
  color: rgba(255, 255, 255, 0.5)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-dark, rgba(255, 255, 255, 0.5)) !important;
}

.mdc-theme--primary-bg {
  background-color: #6200ee  !important;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #6200ee) !important;
}

.mdc-theme--secondary-bg {
  background-color: #018786  !important;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #018786) !important;
}

.mdc-tooltip__surface {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-small, 4px);
}

.mdc-tooltip__caret-surface-top,
.mdc-tooltip__caret-surface-bottom {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-small, 4px);
}

.mdc-tooltip__surface {
  color: white;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, white);
}

.mdc-tooltip__surface {
  background-color: rgba(0, 0, 0, 0.6);
}

.mdc-tooltip__surface {
  word-break: break-all;
  /* @alternate */
  word-break: var(--mdc-tooltip-word-break, normal);
  overflow-wrap: anywhere;
}

.mdc-tooltip {
  z-index: 9;
}

.mdc-tooltip--showing-transition .mdc-tooltip__surface-animation {
  transition: opacity 150ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 150ms 0ms cubic-bezier(0, 0, 0.2, 1);
}

.mdc-tooltip--hide-transition .mdc-tooltip__surface-animation {
  transition: opacity 75ms 0ms cubic-bezier(0.4, 0, 1, 1);
}

.mdc-tooltip__title {
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87));
}

.mdc-tooltip__content {
  color: rgba(0, 0, 0, 0.6);
}

.mdc-tooltip__content-link {
  color: #6200ee;
  /* @alternate */
  color: var(--mdc-theme-primary, #6200ee);
}

.mdc-tooltip {
  position: fixed;
  display: none;
}

.mdc-tooltip.mdc-tooltip--rich .mdc-tooltip__surface {
  background-color: #fff;
}

.mdc-tooltip.mdc-tooltip--rich .mdc-tooltip__caret-surface-top,
.mdc-tooltip.mdc-tooltip--rich .mdc-tooltip__caret-surface-bottom {
  background-color: #fff;
}

.mdc-tooltip-wrapper--rich {
  position: relative;
}

.mdc-tooltip--shown,
.mdc-tooltip--showing,
.mdc-tooltip--hide {
  display: inline-flex;
}

.mdc-tooltip--shown.mdc-tooltip--rich,
.mdc-tooltip--showing.mdc-tooltip--rich,
.mdc-tooltip--hide.mdc-tooltip--rich {
  display: inline-block;
  /* @noflip */ /*rtl:ignore*/
  left: -320px;
  position: absolute;
}

.mdc-tooltip__surface {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 0.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: 0.0333333333em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  line-height: 16px;
  padding: 4px 8px;
  min-width: 40px;
  max-width: 200px;
  min-height: 24px;
  max-height: 40vh;
  box-sizing: border-box;
  overflow: hidden;
  text-align: center;
}

.mdc-tooltip__surface::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 1px solid transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}

@media screen and (forced-colors: active) {
  .mdc-tooltip__surface::before {
    border-color: CanvasText;
  }
}
.mdc-tooltip--rich .mdc-tooltip__surface {
  /* @alternate */
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  align-items: flex-start;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  line-height: 20px;
  min-height: 24px;
  min-width: 40px;
  max-width: 320px;
  position: relative;
}

.mdc-tooltip--rich .mdc-tooltip__surface .mdc-elevation-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
}

.mdc-tooltip--multiline .mdc-tooltip__surface {
  /* @noflip */ /*rtl:ignore*/
  text-align: left;
}

[dir=rtl] .mdc-tooltip--multiline .mdc-tooltip__surface, .mdc-tooltip--multiline .mdc-tooltip__surface[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  text-align: right;
  /*rtl:end:ignore*/
}

.mdc-tooltip__surface .mdc-tooltip__title {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: 20px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle2-font-size, 0.875rem);
  line-height: 1.375rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle2-line-height, 1.375rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle2-font-weight, 500);
  letter-spacing: 0.0071428571em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle2-letter-spacing, 0.0071428571em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle2-text-transform, inherit);
  margin: 0 8px;
}

.mdc-tooltip__surface .mdc-tooltip__title::before {
  display: inline-block;
  width: 0;
  height: 24px;
  content: "";
  vertical-align: 0;
}

.mdc-tooltip__surface .mdc-tooltip__content {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  max-width: calc(200px - (2 * 8px));
  margin: 8px;
  /* @noflip */ /*rtl:ignore*/
  text-align: left;
}

[dir=rtl] .mdc-tooltip__surface .mdc-tooltip__content, .mdc-tooltip__surface .mdc-tooltip__content[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  text-align: right;
  /*rtl:end:ignore*/
}

.mdc-tooltip--rich .mdc-tooltip__surface .mdc-tooltip__content {
  max-width: calc(320px - (2 * 8px));
  align-self: stretch;
}

.mdc-tooltip__surface .mdc-tooltip__content-link {
  text-decoration: none;
}

.mdc-tooltip--rich-actions,
.mdc-tooltip__content,
.mdc-tooltip__title {
  z-index: 1;
}

.mdc-tooltip__surface-animation {
  opacity: 0;
  transform: scale(0.8);
  will-change: transform, opacity;
}

.mdc-tooltip--shown .mdc-tooltip__surface-animation {
  transform: scale(1);
  opacity: 1;
}

.mdc-tooltip--hide .mdc-tooltip__surface-animation {
  transform: scale(1);
}

.mdc-tooltip__caret-surface-top,
.mdc-tooltip__caret-surface-bottom {
  position: absolute;
  height: 24px;
  width: 24px;
  transform: rotate(35deg) skewY(20deg) scaleX(0.9396926208);
}

.mdc-tooltip__caret-surface-top .mdc-elevation-overlay,
.mdc-tooltip__caret-surface-bottom .mdc-elevation-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
}

.mdc-tooltip__caret-surface-bottom {
  /* @alternate */
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  outline: 1px solid transparent;
  z-index: -1;
}

@media screen and (forced-colors: active) {
  .mdc-tooltip__caret-surface-bottom {
    outline-color: CanvasText;
  }
}
.mdc-top-app-bar {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #6200ee);
  color: white;
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  z-index: 4;
}

.mdc-top-app-bar .mdc-top-app-bar__action-item,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon {
  color: #fff;
  /* @alternate */
  color: var(--mdc-theme-on-primary, #fff);
}

.mdc-top-app-bar .mdc-top-app-bar__action-item::before, .mdc-top-app-bar .mdc-top-app-bar__action-item::after,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon::before,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon::after {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-on-primary, #fff));
}

.mdc-top-app-bar .mdc-top-app-bar__action-item:hover::before, .mdc-top-app-bar .mdc-top-app-bar__action-item.mdc-ripple-surface--hover::before,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon:hover::before,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon.mdc-ripple-surface--hover::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.08);
}

.mdc-top-app-bar .mdc-top-app-bar__action-item.mdc-ripple-upgraded--background-focused::before, .mdc-top-app-bar .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded):focus::before,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded--background-focused::before,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}

.mdc-top-app-bar .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded)::after,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}

.mdc-top-app-bar .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded):active::after,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}

.mdc-top-app-bar .mdc-top-app-bar__action-item.mdc-ripple-upgraded,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.24);
}

.mdc-top-app-bar__row {
  display: flex;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 64px;
}

.mdc-top-app-bar__section {
  display: inline-flex;
  flex: 1 1 auto;
  align-items: center;
  min-width: 0;
  padding: 8px 12px;
  z-index: 1;
}

.mdc-top-app-bar__section--align-start {
  justify-content: flex-start;
  order: -1;
}

.mdc-top-app-bar__section--align-end {
  justify-content: flex-end;
  order: 1;
}

.mdc-top-app-bar__title {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1.25rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline6-font-size, 1.25rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline6-line-height, 2rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-headline6-font-weight, 500);
  letter-spacing: 0.0125em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline6-letter-spacing, 0.0125em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline6-text-transform, inherit);
  /* @noflip */ /*rtl:ignore*/
  padding-left: 20px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  z-index: 1;
}

[dir=rtl] .mdc-top-app-bar__title, .mdc-top-app-bar__title[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 20px;
  /*rtl:end:ignore*/
}

.mdc-top-app-bar--short-collapsed {
  /* @noflip */ /*rtl:ignore*/
  border-top-left-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-top-right-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-bottom-right-radius: 24px;
  /* @noflip */ /*rtl:ignore*/
  border-bottom-left-radius: 0;
}

[dir=rtl] .mdc-top-app-bar--short-collapsed, .mdc-top-app-bar--short-collapsed[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  border-top-left-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-top-right-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-bottom-right-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-bottom-left-radius: 24px;
  /*rtl:end:ignore*/
}

.mdc-top-app-bar--short {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  right: auto;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  width: 100%;
  transition: width 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

[dir=rtl] .mdc-top-app-bar--short, .mdc-top-app-bar--short[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  right: 0;
  /* @noflip */ /*rtl:ignore*/
  left: auto;
  /*rtl:end:ignore*/
}

.mdc-top-app-bar--short .mdc-top-app-bar__row {
  height: 56px;
}

.mdc-top-app-bar--short .mdc-top-app-bar__section {
  padding: 4px;
}

.mdc-top-app-bar--short .mdc-top-app-bar__title {
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 1;
}

.mdc-top-app-bar--short-collapsed {
  /* @alternate */
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  width: 56px;
  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-top-app-bar--short-collapsed .mdc-top-app-bar__title {
  display: none;
}

.mdc-top-app-bar--short-collapsed .mdc-top-app-bar__action-item {
  transition: padding 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item {
  width: 112px;
}

.mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item .mdc-top-app-bar__section--align-end {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 12px;
}

[dir=rtl] .mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item .mdc-top-app-bar__section--align-end, .mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item .mdc-top-app-bar__section--align-end[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-top-app-bar--dense .mdc-top-app-bar__row {
  height: 48px;
}

.mdc-top-app-bar--dense .mdc-top-app-bar__section {
  padding: 0 4px;
}

.mdc-top-app-bar--dense .mdc-top-app-bar__title {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}

[dir=rtl] .mdc-top-app-bar--dense .mdc-top-app-bar__title, .mdc-top-app-bar--dense .mdc-top-app-bar__title[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 12px;
  /*rtl:end:ignore*/
}

.mdc-top-app-bar--prominent .mdc-top-app-bar__row {
  height: 128px;
}

.mdc-top-app-bar--prominent .mdc-top-app-bar__title {
  align-self: flex-end;
  padding-bottom: 2px;
}

.mdc-top-app-bar--prominent .mdc-top-app-bar__action-item,
.mdc-top-app-bar--prominent .mdc-top-app-bar__navigation-icon {
  align-self: flex-start;
}

.mdc-top-app-bar--fixed {
  transition: box-shadow 200ms linear;
}

.mdc-top-app-bar--fixed-scrolled {
  /* @alternate */
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  transition: box-shadow 200ms linear;
}

.mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__row {
  height: 96px;
}

.mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__section {
  padding: 0 12px;
}

.mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__title {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 20px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  padding-bottom: 9px;
}

[dir=rtl] .mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__title, .mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__title[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 20px;
  /*rtl:end:ignore*/
}

.mdc-top-app-bar--fixed-adjust {
  padding-top: 64px;
}

.mdc-top-app-bar--dense-fixed-adjust {
  padding-top: 48px;
}

.mdc-top-app-bar--short-fixed-adjust {
  padding-top: 56px;
}

.mdc-top-app-bar--prominent-fixed-adjust {
  padding-top: 128px;
}

.mdc-top-app-bar--dense-prominent-fixed-adjust {
  padding-top: 96px;
}

@media (max-width: 599px) {
  .mdc-top-app-bar__row {
    height: 56px;
  }
  .mdc-top-app-bar__section {
    padding: 4px;
  }
  .mdc-top-app-bar--short {
    transition: width 200ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .mdc-top-app-bar--short-collapsed {
    transition: width 250ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .mdc-top-app-bar--short-collapsed .mdc-top-app-bar__section--align-end {
    /* @noflip */ /*rtl:ignore*/
    padding-left: 0;
    /* @noflip */ /*rtl:ignore*/
    padding-right: 12px;
  }
  [dir=rtl] .mdc-top-app-bar--short-collapsed .mdc-top-app-bar__section--align-end, .mdc-top-app-bar--short-collapsed .mdc-top-app-bar__section--align-end[dir=rtl] {
    /*rtl:begin:ignore*/
    /* @noflip */ /*rtl:ignore*/
    padding-left: 12px;
    /* @noflip */ /*rtl:ignore*/
    padding-right: 0;
    /*rtl:end:ignore*/
  }
  .mdc-top-app-bar--prominent .mdc-top-app-bar__title {
    padding-bottom: 6px;
  }
  .mdc-top-app-bar--fixed-adjust {
    padding-top: 56px;
  }
}
.mdc-typography {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-font-family, Roboto, sans-serif);
}

.mdc-typography--headline1 {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 6rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline1-font-size, 6rem);
  line-height: 6rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline1-line-height, 6rem);
  font-weight: 300;
  /* @alternate */
  font-weight: var(--mdc-typography-headline1-font-weight, 300);
  letter-spacing: -0.015625em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline1-letter-spacing, -0.015625em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline1-text-transform, inherit);
}

.mdc-typography--headline2 {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 3.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline2-font-size, 3.75rem);
  line-height: 3.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline2-line-height, 3.75rem);
  font-weight: 300;
  /* @alternate */
  font-weight: var(--mdc-typography-headline2-font-weight, 300);
  letter-spacing: -0.0083333333em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline2-letter-spacing, -0.0083333333em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline2-text-transform, inherit);
}

.mdc-typography--headline3 {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline3-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 3rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline3-font-size, 3rem);
  line-height: 3.125rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline3-line-height, 3.125rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-headline3-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline3-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline3-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline3-text-transform, inherit);
}

.mdc-typography--headline4 {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline4-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 2.125rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline4-font-size, 2.125rem);
  line-height: 2.5rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline4-line-height, 2.5rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-headline4-font-weight, 400);
  letter-spacing: 0.0073529412em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline4-letter-spacing, 0.0073529412em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline4-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline4-text-transform, inherit);
}

.mdc-typography--headline5 {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline5-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1.5rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline5-font-size, 1.5rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline5-line-height, 2rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-headline5-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline5-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline5-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline5-text-transform, inherit);
}

.mdc-typography--headline6 {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1.25rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline6-font-size, 1.25rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline6-line-height, 2rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-headline6-font-weight, 500);
  letter-spacing: 0.0125em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline6-letter-spacing, 0.0125em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline6-text-transform, inherit);
}

.mdc-typography--subtitle1 {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
}

.mdc-typography--subtitle2 {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle2-font-size, 0.875rem);
  line-height: 1.375rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle2-line-height, 1.375rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle2-font-weight, 500);
  letter-spacing: 0.0071428571em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle2-letter-spacing, 0.0071428571em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle2-text-transform, inherit);
}

.mdc-typography--body1 {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-body1-font-size, 1rem);
  line-height: 1.5rem;
  /* @alternate */
  line-height: var(--mdc-typography-body1-line-height, 1.5rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: 0.03125em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body1-letter-spacing, 0.03125em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body1-text-transform, inherit);
}

.mdc-typography--body2 {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
}

.mdc-typography--caption {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 0.75rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: 0.0333333333em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
}

.mdc-typography--button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-button-font-size, 0.875rem);
  line-height: 2.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-button-line-height, 2.25rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: 0.0892857143em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-button-letter-spacing, 0.0892857143em);
  text-decoration: none;
  /* @alternate */
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: uppercase;
  /* @alternate */
  text-transform: var(--mdc-typography-button-text-transform, uppercase);
}

.mdc-typography--overline {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-overline-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-overline-font-size, 0.75rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-overline-line-height, 2rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-overline-font-weight, 500);
  letter-spacing: 0.1666666667em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-overline-letter-spacing, 0.1666666667em);
  text-decoration: none;
  /* @alternate */
  text-decoration: var(--mdc-typography-overline-text-decoration, none);
  text-transform: uppercase;
  /* @alternate */
  text-transform: var(--mdc-typography-overline-text-transform, uppercase);
}

.notifier__container * {
  box-sizing: border-box;
}
.notifier__container-list {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
}
.notifier__notification {
  display: flex;
  align-items: center;
  position: fixed;
  visibility: hidden;
  z-index: 10000;
  will-change: transform;
  backface-visibility: hidden;
}

.notifier__notification--material {
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  cursor: default;
  padding-top: 11px;
  padding-right: 26px;
  padding-bottom: 10px;
  padding-left: 26px;
}
.notifier__notification--material .notifier__notification-message {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0;
  vertical-align: top;
  line-height: 32px;
  font-size: 15px;
}
.notifier__notification--material .notifier__notification-button {
  display: inline-block;
  transition: opacity 0.2s ease;
  opacity: 0.5;
  margin-right: -10px;
  margin-left: 10px;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  padding: 6px;
  width: 32px;
  height: 32px;
  vertical-align: top;
}
.notifier__notification--material .notifier__notification-button:hover, .notifier__notification--material .notifier__notification-button:focus {
  opacity: 1;
}
.notifier__notification--material .notifier__notification-button:active {
  transform: scale(0.82);
  opacity: 1;
}

.notifier__notification--default {
  background-color: #444;
  color: #fff;
}
.notifier__notification--default .notifier__notification-button-icon {
  fill: #fff;
}

.notifier__notification--error {
  background-color: #d9534f;
  color: #fff;
}
.notifier__notification--error .notifier__notification-button-icon {
  fill: #fff;
}

.notifier__notification--info {
  background-color: #5bc0de;
  color: #fff;
}
.notifier__notification--info .notifier__notification-button-icon {
  fill: #fff;
}

.notifier__notification--success {
  background-color: #5cb85c;
  color: #fff;
}
.notifier__notification--success .notifier__notification-button-icon {
  fill: #fff;
}

.notifier__notification--warning {
  background-color: #f0ad4e;
  color: #fff;
}
.notifier__notification--warning .notifier__notification-button-icon {
  fill: #fff;
}

.text-danger {
  color: #d9534f;
}

.text-info {
  color: #5bc0de;
}

.text-warning {
  color: #e29c3b;
}

.text-success {
  color: #52b152;
}

.accent-text-color {
  color: #aa7a56;
}

.gradient-dark-normal {
  background: linear-gradient(#f3f3f3, #ffffff);
}

.header-gradient {
  background: linear-gradient(#f3f3f3, #ffffff);
}

/* For use in src/lib/core/theming/_palette.scss */
.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1,
.mat-headline,
.mat-typography .mat-h1,
.mat-typography .mat-headline,
.mat-typography h1 {
  font: 400 24px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-title,
.mat-typography .mat-h2,
.mat-typography .mat-title,
.mat-typography h2 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subheading-2,
.mat-typography .mat-h3,
.mat-typography .mat-subheading-2,
.mat-typography h3 {
  font: 400 16px / 28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-subheading-1,
.mat-typography .mat-h4,
.mat-typography .mat-subheading-1,
.mat-typography h4 {
  font: 400 15px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-body-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-body-2 {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-body,
.mat-body-1,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body p,
.mat-body-1 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-1 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-display-4,
.mat-typography .mat-display-4 {
  font: 300 112px / 112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3,
.mat-typography .mat-display-3 {
  font: 400 56px / 56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2,
.mat-typography .mat-display-2 {
  font: 400 45px / 48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1,
.mat-typography .mat-display-1 {
  font: 400 34px / 40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}

.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #cd702b;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ffab40;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff8253;
}

.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #cd702b;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #ffab40;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #ff8253;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}

.mat-badge {
  position: relative;
}

.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-badge-content {
  color: white;
  background: #cd702b;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: #ffab40;
  color: rgba(0, 0, 0, 0.87);
}

.mat-badge-warn .mat-badge-content {
  color: black;
  background: #ff8253;
}

.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #cd702b;
}
.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: #ffab40;
}
.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: #ff8253;
}
.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #cd702b;
}
.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #ffab40;
}
.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #ff8253;
}
.mat-button.mat-button-disabled .mat-button-focus-overlay, .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}

.mat-button-focus-overlay {
  background: black;
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: white;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: black;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #cd702b;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #ffab40;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: #ff8253;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-checkmark {
  fill: #fafafa;
}

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}

.mat-checkbox-mixedmark {
  background-color: #fafafa;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #cd702b;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #ffab40;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #ff8253;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-checkbox .mat-ripple-element {
  background-color: black;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #cd702b;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #ffab40;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #ff8253;
}

.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
  background: black;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #cd702b;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #ff8253;
  color: black;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: black;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #ffab40;
  color: rgba(0, 0, 0, 0.87);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-table {
  background: white;
}

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}

.mat-cell, .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-table-header,
.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-in-range::before {
  background: rgba(205, 112, 43, 0.2);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(205, 112, 43, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(205, 112, 43, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: #cd702b;
  color: white;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(205, 112, 43, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(205, 112, 43, 0.3);
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(205, 112, 43, 0.3);
  }
}
.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(255, 171, 64, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 171, 64, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 171, 64, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #ffab40;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 171, 64, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 171, 64, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 171, 64, 0.3);
  }
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(255, 130, 83, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 130, 83, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 130, 83, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #ff8253;
  color: black;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 130, 83, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px black;
}
.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 130, 83, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 130, 83, 0.3);
  }
}

.mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #cd702b;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #ffab40;
}
.mat-datepicker-toggle-active.mat-warn {
  color: #ff8253;
}

.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-hint {
  color: rgba(0, 0, 0, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #cd702b;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #ffab40;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #ff8253;
}

.mat-focused .mat-form-field-required-marker {
  color: #ffab40;
}

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #cd702b;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #ffab40;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #ff8253;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #cd702b;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #ffab40;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #ff8253;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #ff8253;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #ff8253;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #ff8253;
}

.mat-error {
  color: #ff8253;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #cd702b;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #ffab40;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #ff8253;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #ff8253;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}

.mat-icon.mat-primary {
  color: #cd702b;
}
.mat-icon.mat-accent {
  color: #ffab40;
}
.mat-icon.mat-warn {
  color: #ff8253;
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-input-element {
  caret-color: #cd702b;
}
.mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-form-field.mat-accent .mat-input-element {
  caret-color: #ffab40;
}

.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #ff8253;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #ff8253;
}

.mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}

.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}

.mat-menu-panel {
  background: white;
}
.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.mat-menu-item[disabled],
.mat-menu-item[disabled] .mat-menu-submenu-icon,
.mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-paginator {
  background: white;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-paginator-container {
  min-height: 56px;
}

.mat-progress-bar-background {
  fill: #efd8c6;
}

.mat-progress-bar-buffer {
  background-color: #efd8c6;
}

.mat-progress-bar-fill::after {
  background-color: #cd702b;
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #fbe6cc;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #fbe6cc;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #ffab40;
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #fbdcd0;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #fbdcd0;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #ff8253;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #cd702b;
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #ffab40;
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #ff8253;
}

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #cd702b;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #cd702b;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ffab40;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #ffab40;
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff8253;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #ff8253;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button .mat-ripple-element {
  background-color: black;
}

.mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #cd702b;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #ffab40;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #ff8253;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #ff8253;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ffab40;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 171, 64, 0.54);
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #ffab40;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #cd702b;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(205, 112, 43, 0.54);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #cd702b;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff8253;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 130, 83, 0.54);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #ff8253;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-primary .mat-slider-track-fill,
.mat-slider.mat-primary .mat-slider-thumb,
.mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #cd702b;
}
.mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(205, 112, 43, 0.2);
}
.mat-slider.mat-accent .mat-slider-track-fill,
.mat-slider.mat-accent .mat-slider-thumb,
.mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #ffab40;
}
.mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(255, 171, 64, 0.2);
}
.mat-slider.mat-warn .mat-slider-track-fill,
.mat-slider.mat-warn .mat-slider-thumb,
.mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #ff8253;
}
.mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: black;
}
.mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(255, 130, 83, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider.mat-slider-disabled .mat-slider-track-background,
.mat-slider.mat-slider-disabled .mat-slider-track-fill,
.mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover:not([aria-disabled]), .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #cd702b;
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #ffab40;
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header.mat-warn .mat-step-icon {
  color: black;
}
.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #ff8253;
  color: black;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #ff8253;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: #ff8253;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}

.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-tab-group[class*=mat-background-] > .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(240, 212, 191, 0.3);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #cd702b;
}
.mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 209, 128, 0.3);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #ffab40;
}
.mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 218, 203, 0.3);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #ff8253;
}
.mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: black;
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(240, 212, 191, 0.3);
}
.mat-tab-group.mat-background-primary > .mat-tab-header, .mat-tab-group.mat-background-primary > .mat-tab-link-container, .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #cd702b;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 209, 128, 0.3);
}
.mat-tab-group.mat-background-accent > .mat-tab-header, .mat-tab-group.mat-background-accent > .mat-tab-link-container, .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #ffab40;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 218, 203, 0.3);
}
.mat-tab-group.mat-background-warn > .mat-tab-header, .mat-tab-group.mat-background-warn > .mat-tab-link-container, .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #ff8253;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: black;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: black;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}

.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-primary {
  background: #cd702b;
  color: white;
}
.mat-toolbar.mat-accent {
  background: #ffab40;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-warn {
  background: #ff8253;
  color: black;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }
  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar-action {
  color: #ffab40;
}

body {
  background-color: #ffffff;
}

.text-danger {
  color: #d9534f;
}

.text-info {
  color: #5bc0de;
}

.text-warning {
  color: #e29c3b;
}

.text-success {
  color: #52b152;
}

.accent-text-color {
  color: #aa7a56;
}

.gradient-dark-normal {
  background: linear-gradient(#f3f3f3, #ffffff);
}

.header-gradient {
  background: linear-gradient(#f3f3f3, #ffffff);
}

:root {
  --summa-color-success: #3c763d;
  --summa-color-danger: #a94442;
}

html {
  width: 100%;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  box-sizing: border-box;
  font-size: 14px;
  font-family: "Lato", "Helvetica Neue", sans-serif;
  color: #1d1d1b;
}

::-webkit-scrollbar {
  width: 10px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.8);
}

* {
  box-sizing: inherit;
}

/* APP */
.app {
  display: block;
  padding: 30px;
}

/* GRID */
.grid {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
}

/* HEADINGS */
h1 {
  font-size: 1.5rem;
  font-weight: 400;
  padding-bottom: 0.5rem;
}

h2 {
  font-size: 1.125rem;
  font-weight: 400;
  padding-bottom: 0.5rem;
}

a {
  text-decoration: none !important;
}

.print {
  display: none !important;
}

@media print {
  .print-hide {
    display: none !important;
  }
  .print {
    display: block !important;
  }
}
.text-danger {
  color: #d9534f;
}

.text-info {
  color: #5bc0de;
}

.text-warning {
  color: #e29c3b;
}

.text-success {
  color: #52b152;
}

.accent-text-color {
  color: #aa7a56;
}

.gradient-dark-normal {
  background: linear-gradient(#f3f3f3, #ffffff);
}

.header-gradient {
  background: linear-gradient(#f3f3f3, #ffffff);
}

/* For use in src/lib/core/theming/_palette.scss */
::ng-deep {
  /* Change label color */
  /* underline border color */
  /* Change label color on focused */
  /* underline border color on focused */
}
::ng-deep .mat-form-field {
  margin-bottom: 0.5rem;
}
::ng-deep .mat-form-field .mat-form-field-label {
  color: #889da7;
}
::ng-deep .mat-form-field .mat-form-field-underline:hover {
  background-color: #aa7a56;
}
::ng-deep .mat-form-field .mat-form-field-underline {
  background-color: #889da7;
}
::ng-deep .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: #aa7a56;
}
::ng-deep .mat-form-field.mat-focused .mat-form-field-label {
  color: #aa7a56;
}
::ng-deep .mat-focused .mat-form-field-underline .mat-form-field-ripple {
  background-color: #aa7a56;
}
::ng-deep .mat-form-field-appearance-legacy .mat-hint {
  color: #889da7;
}

/* Change height list item */
.mat-list-base .mat-list-item.mat-list-option {
  height: 2rem !important;
}

.mat-table {
  background: transparent !important;
}
.mat-table .mat-header-cell {
  text-transform: uppercase;
  color: #1d1d1b;
}
.mat-table .mat-cell {
  color: rgba(0, 0, 0, 0.87);
}
.mat-table .mat-row:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-input-underline,
.mat-input-underline:hover,
.mat-focused .mat-form-field-label {
  color: #aa7a56;
}

.mat-input-element {
  color: #1d1d1b;
}

.mat-select-arrow,
.mat-select-value {
  color: #889da7;
}

.mat-checkbox-inner-container {
  border: 1px solid #889da7;
}

.mat-checkbox-background {
  border-radius: 0 !important;
}

.mat-checkbox-disabled .mat-checkbox-background {
  background: #889da7;
}

.mat-dialog-content {
  display: flex !important;
  flex-direction: column;
  margin: 0 -24px;
  min-width: 600px;
}

.mat-dialog-actions {
  padding: 1rem 0 !important;
  flex-direction: row;
  justify-content: flex-end;
}

#agreement-dialog.mat-dialog-container {
  color: white;
  background: #ffffff;
}
#agreement-dialog.mat-dialog-container .mat-step-header .mat-step-label,
#agreement-dialog.mat-dialog-container .mat-step-header .mat-step-optional {
  color: white;
}
#agreement-dialog.mat-dialog-container .mat-step-header .mat-step-label.mat-step-label-selected,
#agreement-dialog.mat-dialog-container .mat-step-header .mat-step-optional.mat-step-label-selected {
  color: #aa7a56;
}

::ng-deep.mat-tab-body-wrapper {
  height: 100%;
}

.w-0 {
  width: 0 !important;
}

.w-1 {
  width: 0.5rem !important;
}

.w-2 {
  width: 1rem !important;
}

.w-3 {
  width: 1.5rem !important;
}

.w-4 {
  width: 2rem !important;
}

.w-5 {
  width: 3rem !important;
}

.w-6 {
  width: 4rem !important;
}

.w-7 {
  width: 5rem !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-0 {
  height: 0 !important;
}

.h-1 {
  height: 0.5rem !important;
}

.h-2 {
  height: 1rem !important;
}

.h-3 {
  height: 1.5rem !important;
}

.h-4 {
  height: 2rem !important;
}

.h-5 {
  height: 3rem !important;
}

.h-6 {
  height: 4rem !important;
}

.h-7 {
  height: 5rem !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.max-w-0 {
  max-width: 0 !important;
}

.max-w-1 {
  max-width: 0.5rem !important;
}

.max-w-2 {
  max-width: 1rem !important;
}

.max-w-3 {
  max-width: 1.5rem !important;
}

.max-w-4 {
  max-width: 2rem !important;
}

.max-w-5 {
  max-width: 3rem !important;
}

.max-w-6 {
  max-width: 4rem !important;
}

.max-w-7 {
  max-width: 5rem !important;
}

.max-w-25 {
  max-width: 25% !important;
}

.max-w-50 {
  max-width: 50% !important;
}

.max-w-75 {
  max-width: 75% !important;
}

.max-w-100 {
  max-width: 100% !important;
}

.max-w-auto {
  max-width: auto !important;
}

.min-w-0 {
  min-width: 0 !important;
}

.min-w-1 {
  min-width: 0.5rem !important;
}

.min-w-2 {
  min-width: 1rem !important;
}

.min-w-3 {
  min-width: 1.5rem !important;
}

.min-w-4 {
  min-width: 2rem !important;
}

.min-w-5 {
  min-width: 3rem !important;
}

.min-w-6 {
  min-width: 4rem !important;
}

.min-w-7 {
  min-width: 5rem !important;
}

.min-w-25 {
  min-width: 25% !important;
}

.min-w-50 {
  min-width: 50% !important;
}

.min-w-75 {
  min-width: 75% !important;
}

.min-w-100 {
  min-width: 100% !important;
}

.min-w-auto {
  min-width: auto !important;
}

.h-inherit {
  height: inherit;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.5rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.5rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.5rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.5rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.5rem !important;
}

.m-2 {
  margin: 1rem !important;
}

.mt-2,
.my-2 {
  margin-top: 1rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 1rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 1rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 1rem !important;
}

.m-3 {
  margin: 1.5rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1.5rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1.5rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1.5rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1.5rem !important;
}

.m-4 {
  margin: 2rem !important;
}

.mt-4,
.my-4 {
  margin-top: 2rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 2rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 2rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 2rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.m-6 {
  margin: 4rem !important;
}

.mt-6,
.my-6 {
  margin-top: 4rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 4rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 4rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 4rem !important;
}

.m-7 {
  margin: 5rem !important;
}

.mt-7,
.my-7 {
  margin-top: 5rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 5rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 5rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 5rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.5rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.5rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.5rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.5rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.5rem !important;
}

.p-2 {
  padding: 1rem !important;
}

.pt-2,
.py-2 {
  padding-top: 1rem !important;
}

.pr-2,
.px-2 {
  padding-right: 1rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 1rem !important;
}

.pl-2,
.px-2 {
  padding-left: 1rem !important;
}

.p-3 {
  padding: 1.5rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1.5rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1.5rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1.5rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1.5rem !important;
}

.p-4 {
  padding: 2rem !important;
}

.pt-4,
.py-4 {
  padding-top: 2rem !important;
}

.pr-4,
.px-4 {
  padding-right: 2rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 2rem !important;
}

.pl-4,
.px-4 {
  padding-left: 2rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.p-6 {
  padding: 4rem !important;
}

.pt-6,
.py-6 {
  padding-top: 4rem !important;
}

.pr-6,
.px-6 {
  padding-right: 4rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 4rem !important;
}

.pl-6,
.px-6 {
  padding-left: 4rem !important;
}

.p-7 {
  padding: 5rem !important;
}

.pt-7,
.py-7 {
  padding-top: 5rem !important;
}

.pr-7,
.px-7 {
  padding-right: 5rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 5rem !important;
}

.pl-7,
.px-7 {
  padding-left: 5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.5rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.5rem !important;
  }
  .m-sm-2 {
    margin: 1rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 1rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 1rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 1rem !important;
  }
  .m-sm-3 {
    margin: 1.5rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1.5rem !important;
  }
  .m-sm-4 {
    margin: 2rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 2rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 2rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 2rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 2rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .m-sm-6 {
    margin: 4rem !important;
  }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 4rem !important;
  }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 4rem !important;
  }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 4rem !important;
  }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 4rem !important;
  }
  .m-sm-7 {
    margin: 5rem !important;
  }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 5rem !important;
  }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 5rem !important;
  }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 5rem !important;
  }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 5rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.5rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.5rem !important;
  }
  .p-sm-2 {
    padding: 1rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 1rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 1rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 1rem !important;
  }
  .p-sm-3 {
    padding: 1.5rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1.5rem !important;
  }
  .p-sm-4 {
    padding: 2rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 2rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 2rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 2rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 2rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .p-sm-6 {
    padding: 4rem !important;
  }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 4rem !important;
  }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 4rem !important;
  }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 4rem !important;
  }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 4rem !important;
  }
  .p-sm-7 {
    padding: 5rem !important;
  }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 5rem !important;
  }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 5rem !important;
  }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 5rem !important;
  }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 5rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.5rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.5rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.5rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.5rem !important;
  }
  .m-md-2 {
    margin: 1rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 1rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 1rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 1rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 1rem !important;
  }
  .m-md-3 {
    margin: 1.5rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1.5rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1.5rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1.5rem !important;
  }
  .m-md-4 {
    margin: 2rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 2rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 2rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 2rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 2rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .m-md-6 {
    margin: 4rem !important;
  }
  .mt-md-6,
  .my-md-6 {
    margin-top: 4rem !important;
  }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 4rem !important;
  }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 4rem !important;
  }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 4rem !important;
  }
  .m-md-7 {
    margin: 5rem !important;
  }
  .mt-md-7,
  .my-md-7 {
    margin-top: 5rem !important;
  }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 5rem !important;
  }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 5rem !important;
  }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 5rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.5rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.5rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.5rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.5rem !important;
  }
  .p-md-2 {
    padding: 1rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 1rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 1rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 1rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 1rem !important;
  }
  .p-md-3 {
    padding: 1.5rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1.5rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1.5rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1.5rem !important;
  }
  .p-md-4 {
    padding: 2rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 2rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 2rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 2rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 2rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .p-md-6 {
    padding: 4rem !important;
  }
  .pt-md-6,
  .py-md-6 {
    padding-top: 4rem !important;
  }
  .pr-md-6,
  .px-md-6 {
    padding-right: 4rem !important;
  }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 4rem !important;
  }
  .pl-md-6,
  .px-md-6 {
    padding-left: 4rem !important;
  }
  .p-md-7 {
    padding: 5rem !important;
  }
  .pt-md-7,
  .py-md-7 {
    padding-top: 5rem !important;
  }
  .pr-md-7,
  .px-md-7 {
    padding-right: 5rem !important;
  }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 5rem !important;
  }
  .pl-md-7,
  .px-md-7 {
    padding-left: 5rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.5rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.5rem !important;
  }
  .m-lg-2 {
    margin: 1rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 1rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 1rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 1rem !important;
  }
  .m-lg-3 {
    margin: 1.5rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1.5rem !important;
  }
  .m-lg-4 {
    margin: 2rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 2rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 2rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 2rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 2rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .m-lg-6 {
    margin: 4rem !important;
  }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 4rem !important;
  }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 4rem !important;
  }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 4rem !important;
  }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 4rem !important;
  }
  .m-lg-7 {
    margin: 5rem !important;
  }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 5rem !important;
  }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 5rem !important;
  }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 5rem !important;
  }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 5rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.5rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.5rem !important;
  }
  .p-lg-2 {
    padding: 1rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 1rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 1rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 1rem !important;
  }
  .p-lg-3 {
    padding: 1.5rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1.5rem !important;
  }
  .p-lg-4 {
    padding: 2rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 2rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 2rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 2rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 2rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .p-lg-6 {
    padding: 4rem !important;
  }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 4rem !important;
  }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 4rem !important;
  }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 4rem !important;
  }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 4rem !important;
  }
  .p-lg-7 {
    padding: 5rem !important;
  }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 5rem !important;
  }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 5rem !important;
  }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 5rem !important;
  }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 5rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.5rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.5rem !important;
  }
  .m-xl-2 {
    margin: 1rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 1rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 1rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 1rem !important;
  }
  .m-xl-3 {
    margin: 1.5rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1.5rem !important;
  }
  .m-xl-4 {
    margin: 2rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 2rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 2rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 2rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 2rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .m-xl-6 {
    margin: 4rem !important;
  }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 4rem !important;
  }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 4rem !important;
  }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 4rem !important;
  }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 4rem !important;
  }
  .m-xl-7 {
    margin: 5rem !important;
  }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 5rem !important;
  }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 5rem !important;
  }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 5rem !important;
  }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 5rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.5rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.5rem !important;
  }
  .p-xl-2 {
    padding: 1rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 1rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 1rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 1rem !important;
  }
  .p-xl-3 {
    padding: 1.5rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1.5rem !important;
  }
  .p-xl-4 {
    padding: 2rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 2rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 2rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 2rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 2rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .p-xl-6 {
    padding: 4rem !important;
  }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 4rem !important;
  }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 4rem !important;
  }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 4rem !important;
  }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 4rem !important;
  }
  .p-xl-7 {
    padding: 5rem !important;
  }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 5rem !important;
  }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 5rem !important;
  }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 5rem !important;
  }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 5rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.no-select {
  user-select: none !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-keep {
  flex: 0 0 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-keep {
    flex: 0 0 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-keep {
    flex: 0 0 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-keep {
    flex: 0 0 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-keep {
    flex: 0 0 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.horizontal-break {
  flex-basis: 100%;
  height: 0;
}

.d-block {
  display: block !important;
}

.d-inline-block {
  display: inline-block !important;
}

@media (min-width: 576px) {
  .d-sm-block {
    display: block !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 768px) {
  .d-md-block {
    display: block !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 992px) {
  .d-lg-block {
    display: block !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-block {
    display: block !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
}
.alert {
  padding: 1rem;
  border: 1px solid transparent;
  border-radius: 4px;
}
.alert h4 {
  margin-top: 0;
  color: inherit;
}
.alert .alert-link {
  font-weight: bold;
}
.alert > p,
.alert > ul {
  margin-bottom: 0;
}
.alert > p + p {
  margin-top: 5px;
}

.alert-dismissible {
  padding-right: 21rem;
}
.alert-dismissible .close {
  position: relative;
  top: -2px;
  right: -21px;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b3e5ff;
}
.alert-primary hr {
  border-top-color: #99dcff;
}
.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #f5f5f5;
  border-color: #e8e8e8;
}
.alert-secondary hr {
  border-top-color: gainsboro;
}
.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}
.alert-success hr {
  border-top-color: #c9e2b3;
}
.alert-success .alert-link {
  color: #2b542c;
}

.alert-info {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}
.alert-info hr {
  border-top-color: #a6e1ec;
}
.alert-info .alert-link {
  color: #245269;
}

.alert-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}
.alert-warning hr {
  border-top-color: #f7e1b5;
}
.alert-warning .alert-link {
  color: #66512c;
}

.alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}
.alert-danger hr {
  border-top-color: #e4b9c0;
}
.alert-danger .alert-link {
  color: #843534;
}

.alert-danger-border {
  color: #a94442;
  background-color: transparent;
  border-color: #a94442;
}
.alert-danger-border hr {
  border-top-color: #973d3b;
}
.alert-danger-border .alert-link {
  color: #843534;
}

.text-danger {
  color: #d9534f;
}

.text-info {
  color: #5bc0de;
}

.text-warning {
  color: #e29c3b;
}

.text-success {
  color: #52b152;
}

.accent-text-color {
  color: #aa7a56;
}

.gradient-dark-normal {
  background: linear-gradient(#f3f3f3, #ffffff);
}

.header-gradient {
  background: linear-gradient(#f3f3f3, #ffffff);
}

/* For use in src/lib/core/theming/_palette.scss */
.button {
  display: inline-block;
  padding: 1rem;
  margin-right: 0.25rem;
  background: none;
  border: none;
  cursor: pointer;
  opacity: 1;
  -webkit-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease;
}

.button--primary {
  background-color: #3c4553;
  color: white;
}

.button--primary:hover,
.button--primary:focus,
.button--primary:active {
  background-color: #22262e;
}

.button--secondary {
  background-color: #889da7;
  color: #22262e;
}

.button--secondary:hover,
.button--secondary:focus,
.button--secondary:active {
  background-color: #abc4cf;
}

.text-danger {
  color: #d9534f;
}

.text-info {
  color: #5bc0de;
}

.text-warning {
  color: #e29c3b;
}

.text-success {
  color: #52b152;
}

.accent-text-color {
  color: #aa7a56;
}

.gradient-dark-normal {
  background: linear-gradient(#f3f3f3, #ffffff);
}

.header-gradient {
  background: linear-gradient(#f3f3f3, #ffffff);
}

:root {
  --summa-color-success: #3c763d;
  --summa-color-danger: #a94442;
}

html {
  width: 100%;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  box-sizing: border-box;
  font-size: 14px;
  font-family: "Lato", "Helvetica Neue", sans-serif;
  color: #1d1d1b;
}

::-webkit-scrollbar {
  width: 10px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.8);
}

* {
  box-sizing: inherit;
}

/* APP */
.app {
  display: block;
  padding: 30px;
}

/* GRID */
.grid {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
}

/* HEADINGS */
h1 {
  font-size: 1.5rem;
  font-weight: 400;
  padding-bottom: 0.5rem;
}

h2 {
  font-size: 1.125rem;
  font-weight: 400;
  padding-bottom: 0.5rem;
}

a {
  text-decoration: none !important;
}

.print {
  display: none !important;
}

@media print {
  .print-hide {
    display: none !important;
  }
  .print {
    display: block !important;
  }
}
.text-danger {
  color: #d9534f;
}

.text-info {
  color: #5bc0de;
}

.text-warning {
  color: #e29c3b;
}

.text-success {
  color: #52b152;
}

.accent-text-color {
  color: #aa7a56;
}

.gradient-dark-normal {
  background: linear-gradient(#f3f3f3, #ffffff);
}

.header-gradient {
  background: linear-gradient(#f3f3f3, #ffffff);
}

/* For use in src/lib/core/theming/_palette.scss */
::ng-deep {
  /* Change label color */
  /* underline border color */
  /* Change label color on focused */
  /* underline border color on focused */
}
::ng-deep .mat-form-field {
  margin-bottom: 0.5rem;
}
::ng-deep .mat-form-field .mat-form-field-label {
  color: #889da7;
}
::ng-deep .mat-form-field .mat-form-field-underline:hover {
  background-color: #aa7a56;
}
::ng-deep .mat-form-field .mat-form-field-underline {
  background-color: #889da7;
}
::ng-deep .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: #aa7a56;
}
::ng-deep .mat-form-field.mat-focused .mat-form-field-label {
  color: #aa7a56;
}
::ng-deep .mat-focused .mat-form-field-underline .mat-form-field-ripple {
  background-color: #aa7a56;
}
::ng-deep .mat-form-field-appearance-legacy .mat-hint {
  color: #889da7;
}

/* Change height list item */
.mat-list-base .mat-list-item.mat-list-option {
  height: 2rem !important;
}

.mat-table {
  background: transparent !important;
}
.mat-table .mat-header-cell {
  text-transform: uppercase;
  color: #1d1d1b;
}
.mat-table .mat-cell {
  color: rgba(0, 0, 0, 0.87);
}
.mat-table .mat-row:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-input-underline,
.mat-input-underline:hover,
.mat-focused .mat-form-field-label {
  color: #aa7a56;
}

.mat-input-element {
  color: #1d1d1b;
}

.mat-select-arrow,
.mat-select-value {
  color: #889da7;
}

.mat-checkbox-inner-container {
  border: 1px solid #889da7;
}

.mat-checkbox-background {
  border-radius: 0 !important;
}

.mat-checkbox-disabled .mat-checkbox-background {
  background: #889da7;
}

.mat-dialog-content {
  display: flex !important;
  flex-direction: column;
  margin: 0 -24px;
  min-width: 600px;
}

.mat-dialog-actions {
  padding: 1rem 0 !important;
  flex-direction: row;
  justify-content: flex-end;
}

#agreement-dialog.mat-dialog-container {
  color: white;
  background: #ffffff;
}
#agreement-dialog.mat-dialog-container .mat-step-header .mat-step-label,
#agreement-dialog.mat-dialog-container .mat-step-header .mat-step-optional {
  color: white;
}
#agreement-dialog.mat-dialog-container .mat-step-header .mat-step-label.mat-step-label-selected,
#agreement-dialog.mat-dialog-container .mat-step-header .mat-step-optional.mat-step-label-selected {
  color: #aa7a56;
}

::ng-deep.mat-tab-body-wrapper {
  height: 100%;
}

.w-0 {
  width: 0 !important;
}

.w-1 {
  width: 0.5rem !important;
}

.w-2 {
  width: 1rem !important;
}

.w-3 {
  width: 1.5rem !important;
}

.w-4 {
  width: 2rem !important;
}

.w-5 {
  width: 3rem !important;
}

.w-6 {
  width: 4rem !important;
}

.w-7 {
  width: 5rem !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-0 {
  height: 0 !important;
}

.h-1 {
  height: 0.5rem !important;
}

.h-2 {
  height: 1rem !important;
}

.h-3 {
  height: 1.5rem !important;
}

.h-4 {
  height: 2rem !important;
}

.h-5 {
  height: 3rem !important;
}

.h-6 {
  height: 4rem !important;
}

.h-7 {
  height: 5rem !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.max-w-0 {
  max-width: 0 !important;
}

.max-w-1 {
  max-width: 0.5rem !important;
}

.max-w-2 {
  max-width: 1rem !important;
}

.max-w-3 {
  max-width: 1.5rem !important;
}

.max-w-4 {
  max-width: 2rem !important;
}

.max-w-5 {
  max-width: 3rem !important;
}

.max-w-6 {
  max-width: 4rem !important;
}

.max-w-7 {
  max-width: 5rem !important;
}

.max-w-25 {
  max-width: 25% !important;
}

.max-w-50 {
  max-width: 50% !important;
}

.max-w-75 {
  max-width: 75% !important;
}

.max-w-100 {
  max-width: 100% !important;
}

.max-w-auto {
  max-width: auto !important;
}

.min-w-0 {
  min-width: 0 !important;
}

.min-w-1 {
  min-width: 0.5rem !important;
}

.min-w-2 {
  min-width: 1rem !important;
}

.min-w-3 {
  min-width: 1.5rem !important;
}

.min-w-4 {
  min-width: 2rem !important;
}

.min-w-5 {
  min-width: 3rem !important;
}

.min-w-6 {
  min-width: 4rem !important;
}

.min-w-7 {
  min-width: 5rem !important;
}

.min-w-25 {
  min-width: 25% !important;
}

.min-w-50 {
  min-width: 50% !important;
}

.min-w-75 {
  min-width: 75% !important;
}

.min-w-100 {
  min-width: 100% !important;
}

.min-w-auto {
  min-width: auto !important;
}

.h-inherit {
  height: inherit;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.5rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.5rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.5rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.5rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.5rem !important;
}

.m-2 {
  margin: 1rem !important;
}

.mt-2,
.my-2 {
  margin-top: 1rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 1rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 1rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 1rem !important;
}

.m-3 {
  margin: 1.5rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1.5rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1.5rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1.5rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1.5rem !important;
}

.m-4 {
  margin: 2rem !important;
}

.mt-4,
.my-4 {
  margin-top: 2rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 2rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 2rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 2rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.m-6 {
  margin: 4rem !important;
}

.mt-6,
.my-6 {
  margin-top: 4rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 4rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 4rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 4rem !important;
}

.m-7 {
  margin: 5rem !important;
}

.mt-7,
.my-7 {
  margin-top: 5rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 5rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 5rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 5rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.5rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.5rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.5rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.5rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.5rem !important;
}

.p-2 {
  padding: 1rem !important;
}

.pt-2,
.py-2 {
  padding-top: 1rem !important;
}

.pr-2,
.px-2 {
  padding-right: 1rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 1rem !important;
}

.pl-2,
.px-2 {
  padding-left: 1rem !important;
}

.p-3 {
  padding: 1.5rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1.5rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1.5rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1.5rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1.5rem !important;
}

.p-4 {
  padding: 2rem !important;
}

.pt-4,
.py-4 {
  padding-top: 2rem !important;
}

.pr-4,
.px-4 {
  padding-right: 2rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 2rem !important;
}

.pl-4,
.px-4 {
  padding-left: 2rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.p-6 {
  padding: 4rem !important;
}

.pt-6,
.py-6 {
  padding-top: 4rem !important;
}

.pr-6,
.px-6 {
  padding-right: 4rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 4rem !important;
}

.pl-6,
.px-6 {
  padding-left: 4rem !important;
}

.p-7 {
  padding: 5rem !important;
}

.pt-7,
.py-7 {
  padding-top: 5rem !important;
}

.pr-7,
.px-7 {
  padding-right: 5rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 5rem !important;
}

.pl-7,
.px-7 {
  padding-left: 5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.5rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.5rem !important;
  }
  .m-sm-2 {
    margin: 1rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 1rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 1rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 1rem !important;
  }
  .m-sm-3 {
    margin: 1.5rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1.5rem !important;
  }
  .m-sm-4 {
    margin: 2rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 2rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 2rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 2rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 2rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .m-sm-6 {
    margin: 4rem !important;
  }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 4rem !important;
  }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 4rem !important;
  }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 4rem !important;
  }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 4rem !important;
  }
  .m-sm-7 {
    margin: 5rem !important;
  }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 5rem !important;
  }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 5rem !important;
  }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 5rem !important;
  }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 5rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.5rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.5rem !important;
  }
  .p-sm-2 {
    padding: 1rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 1rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 1rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 1rem !important;
  }
  .p-sm-3 {
    padding: 1.5rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1.5rem !important;
  }
  .p-sm-4 {
    padding: 2rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 2rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 2rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 2rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 2rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .p-sm-6 {
    padding: 4rem !important;
  }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 4rem !important;
  }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 4rem !important;
  }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 4rem !important;
  }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 4rem !important;
  }
  .p-sm-7 {
    padding: 5rem !important;
  }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 5rem !important;
  }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 5rem !important;
  }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 5rem !important;
  }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 5rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.5rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.5rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.5rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.5rem !important;
  }
  .m-md-2 {
    margin: 1rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 1rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 1rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 1rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 1rem !important;
  }
  .m-md-3 {
    margin: 1.5rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1.5rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1.5rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1.5rem !important;
  }
  .m-md-4 {
    margin: 2rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 2rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 2rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 2rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 2rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .m-md-6 {
    margin: 4rem !important;
  }
  .mt-md-6,
  .my-md-6 {
    margin-top: 4rem !important;
  }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 4rem !important;
  }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 4rem !important;
  }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 4rem !important;
  }
  .m-md-7 {
    margin: 5rem !important;
  }
  .mt-md-7,
  .my-md-7 {
    margin-top: 5rem !important;
  }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 5rem !important;
  }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 5rem !important;
  }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 5rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.5rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.5rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.5rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.5rem !important;
  }
  .p-md-2 {
    padding: 1rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 1rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 1rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 1rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 1rem !important;
  }
  .p-md-3 {
    padding: 1.5rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1.5rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1.5rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1.5rem !important;
  }
  .p-md-4 {
    padding: 2rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 2rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 2rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 2rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 2rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .p-md-6 {
    padding: 4rem !important;
  }
  .pt-md-6,
  .py-md-6 {
    padding-top: 4rem !important;
  }
  .pr-md-6,
  .px-md-6 {
    padding-right: 4rem !important;
  }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 4rem !important;
  }
  .pl-md-6,
  .px-md-6 {
    padding-left: 4rem !important;
  }
  .p-md-7 {
    padding: 5rem !important;
  }
  .pt-md-7,
  .py-md-7 {
    padding-top: 5rem !important;
  }
  .pr-md-7,
  .px-md-7 {
    padding-right: 5rem !important;
  }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 5rem !important;
  }
  .pl-md-7,
  .px-md-7 {
    padding-left: 5rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.5rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.5rem !important;
  }
  .m-lg-2 {
    margin: 1rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 1rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 1rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 1rem !important;
  }
  .m-lg-3 {
    margin: 1.5rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1.5rem !important;
  }
  .m-lg-4 {
    margin: 2rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 2rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 2rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 2rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 2rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .m-lg-6 {
    margin: 4rem !important;
  }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 4rem !important;
  }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 4rem !important;
  }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 4rem !important;
  }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 4rem !important;
  }
  .m-lg-7 {
    margin: 5rem !important;
  }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 5rem !important;
  }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 5rem !important;
  }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 5rem !important;
  }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 5rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.5rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.5rem !important;
  }
  .p-lg-2 {
    padding: 1rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 1rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 1rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 1rem !important;
  }
  .p-lg-3 {
    padding: 1.5rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1.5rem !important;
  }
  .p-lg-4 {
    padding: 2rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 2rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 2rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 2rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 2rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .p-lg-6 {
    padding: 4rem !important;
  }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 4rem !important;
  }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 4rem !important;
  }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 4rem !important;
  }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 4rem !important;
  }
  .p-lg-7 {
    padding: 5rem !important;
  }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 5rem !important;
  }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 5rem !important;
  }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 5rem !important;
  }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 5rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.5rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.5rem !important;
  }
  .m-xl-2 {
    margin: 1rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 1rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 1rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 1rem !important;
  }
  .m-xl-3 {
    margin: 1.5rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1.5rem !important;
  }
  .m-xl-4 {
    margin: 2rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 2rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 2rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 2rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 2rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .m-xl-6 {
    margin: 4rem !important;
  }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 4rem !important;
  }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 4rem !important;
  }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 4rem !important;
  }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 4rem !important;
  }
  .m-xl-7 {
    margin: 5rem !important;
  }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 5rem !important;
  }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 5rem !important;
  }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 5rem !important;
  }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 5rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.5rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.5rem !important;
  }
  .p-xl-2 {
    padding: 1rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 1rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 1rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 1rem !important;
  }
  .p-xl-3 {
    padding: 1.5rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1.5rem !important;
  }
  .p-xl-4 {
    padding: 2rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 2rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 2rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 2rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 2rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .p-xl-6 {
    padding: 4rem !important;
  }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 4rem !important;
  }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 4rem !important;
  }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 4rem !important;
  }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 4rem !important;
  }
  .p-xl-7 {
    padding: 5rem !important;
  }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 5rem !important;
  }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 5rem !important;
  }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 5rem !important;
  }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 5rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.no-select {
  user-select: none !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-keep {
  flex: 0 0 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-keep {
    flex: 0 0 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-keep {
    flex: 0 0 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-keep {
    flex: 0 0 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-keep {
    flex: 0 0 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.horizontal-break {
  flex-basis: 100%;
  height: 0;
}

.d-block {
  display: block !important;
}

.d-inline-block {
  display: inline-block !important;
}

@media (min-width: 576px) {
  .d-sm-block {
    display: block !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 768px) {
  .d-md-block {
    display: block !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 992px) {
  .d-lg-block {
    display: block !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-block {
    display: block !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
}
.card {
  margin: 1rem;
  padding: 1rem;
  color: white;
  background-color: #3c4553;
  border: 0.0625rem solid #22262e;
  overflow: hidden;
}
.card:hover {
  box-shadow: -0.5rem 0 0.75rem 0 #22262e;
}
.card .header {
  width: 100%;
  height: 2.5rem;
  text-align: left;
}
.card .header .title {
  margin-top: -0.125rem;
  display: inline-block;
  font-weight: normal;
  font-size: 1.25rem;
  position: relative;
}
.card .header .settings-button {
  float: right;
}
.card .header .settings-button .settings {
  cursor: pointer;
}
.card .header .settings-button .settings .summa-icon .summa-icon__header {
  width: 1.25rem;
  height: 1.25rem;
}
.card .icon {
  text-align: center;
  padding: 1rem;
}
.card .icon .icon-button {
  cursor: pointer;
}
.card .information-block {
  padding: 0.5rem;
  background-color: #455060;
  color: #889da7;
}
.card .information-block .information-block-header {
  margin-bottom: 0.5rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}
.card .information-block .information-block-header .information-block-header-title {
  margin-left: 0.5rem;
}
.card .information-block .information-block-content {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.card .information-block .information-block-content .information-block-content-left {
  margin-top: 0.75rem;
  margin-left: 1rem;
  display: inline-block;
  font-size: 1rem;
}
.card .information-block .information-block-content .information-block-content-right {
  margin-top: 0.75rem;
  margin-right: 1rem;
  display: inline-block;
  font-size: 1rem;
}
.card .information-block .information-block-content .information-block-content-vertical-divider {
  width: 1px;
  height: 2rem;
  border-left: 1px solid #3c4553;
  opacity: 0.25;
  margin-top: 6px;
}

/*
  NEW PORTAL CODE
*/
.summa-card {
  color: #1d1d1b;
  background-color: #f3f3f3;
  overflow: hidden;
  min-height: 115px !important;
}
.summa-card .header {
  padding-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 300;
}
.summa-card .sub-header {
  color: #aa7a56;
  font-size: 1rem;
}

.summa-card-white {
  color: #1d1d1b;
  background-color: #ffffff;
  overflow: hidden;
  min-height: 115px !important;
  border: 1px solid #d6d6d8;
}
.summa-card-white .header {
  padding-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 300;
}
.summa-card-white .sub-header {
  color: #aa7a56;
  font-size: 1rem;
}

.summa-card.clickable {
  cursor: pointer;
}
.summa-card.clickable:hover {
  box-shadow: 0 0 0 2px #aa7a56;
  color: #aa7a56;
}

.dialog {
  width: 24rem;
}
.dialog .dialog-header .dialog-title {
  font-size: 1rem;
  font-weight: bold;
}
.dialog .dialog-content {
  width: 100%;
  min-height: 8rem;
  padding: 1rem 0;
}

.text-danger {
  color: #d9534f;
}

.text-info {
  color: #5bc0de;
}

.text-warning {
  color: #e29c3b;
}

.text-success {
  color: #52b152;
}

.accent-text-color {
  color: #aa7a56;
}

.gradient-dark-normal {
  background: linear-gradient(#f3f3f3, #ffffff);
}

.header-gradient {
  background: linear-gradient(#f3f3f3, #ffffff);
}

/* For use in src/lib/core/theming/_palette.scss */
.mat-snack-bar-container.warning {
  background-color: #e29c3b;
}
.mat-snack-bar-container.info {
  background-color: #5bc0de;
}
.mat-snack-bar-container.success {
  background-color: #52b152;
}
.mat-snack-bar-container.error {
  background-color: #d9534f;
}

.text-danger {
  color: #d9534f;
}

.text-info {
  color: #5bc0de;
}

.text-warning {
  color: #e29c3b;
}

.text-success {
  color: #52b152;
}

.accent-text-color {
  color: #aa7a56;
}

.gradient-dark-normal {
  background: linear-gradient(#f3f3f3, #ffffff);
}

.header-gradient {
  background: linear-gradient(#f3f3f3, #ffffff);
}

/* For use in src/lib/core/theming/_palette.scss */
.mat-toolbar {
  background: inherit;
}
.mat-toolbar .mat-icon {
  cursor: pointer;
  color: #1d1d1b;
  font-size: 1.25rem;
  width: 1.5rem;
  height: 1.5rem;
}
.mat-toolbar .mat-icon:hover {
  color: #aa7a56;
}

.text-danger {
  color: #d9534f;
}

.text-info {
  color: #5bc0de;
}

.text-warning {
  color: #e29c3b;
}

.text-success {
  color: #52b152;
}

.accent-text-color {
  color: #aa7a56;
}

.gradient-dark-normal {
  background: linear-gradient(#f3f3f3, #ffffff);
}

.header-gradient {
  background: linear-gradient(#f3f3f3, #ffffff);
}

/* For use in src/lib/core/theming/_palette.scss */
.mat-header-row {
  min-height: 2.5rem;
  width: 100% !important;
}

.mat-row {
  margin-bottom: 0.25rem;
}

.mat-row:nth-last-child(1) {
  margin-bottom: 0;
}

.mat-header-cell {
  font-weight: normal;
  opacity: 0.5;
}

.mat-cell {
  border: 0;
  background: #ffffff;
}
.mat-cell.highlight {
  color: #aa7a56 !important;
  font-weight: 400;
  text-align: left !important;
}

.header-tools {
  display: inline;
  float: right;
  margin-top: -0.5rem !important;
}
.header-tools .filter {
  display: inline;
  right: 0;
  font-size: 0.75rem;
  margin-right: 1rem;
}
.header-tools .filter .search-input {
  padding-left: 0.5rem;
  padding-bottom: 0.4rem;
}
.header-tools .create {
  display: inline;
  right: 0;
  cursor: pointer;
  margin-top: 0.75rem !important;
}

.pagination {
  margin-top: 1rem;
  background: none !important;
  color: #1d1d1b;
}

.filter-notification {
  padding: 32px 0 16px 24px;
  opacity: 0.5;
  font-style: italic;
}

.text-danger {
  color: #d9534f;
}

.text-info {
  color: #5bc0de;
}

.text-warning {
  color: #e29c3b;
}

.text-success {
  color: #52b152;
}

.accent-text-color {
  color: #aa7a56;
}

.gradient-dark-normal {
  background: linear-gradient(#f3f3f3, #ffffff);
}

.header-gradient {
  background: linear-gradient(#f3f3f3, #ffffff);
}

/* For use in src/lib/core/theming/_palette.scss */
.panel {
  background: #ffffff;
}
.panel__info {
  padding: 1rem 2rem;
  background: #f3f3f3;
}
.panel__body {
  padding: 1rem 2rem !important;
}

.panel-backdrop {
  background: rgba(0, 0, 0, 0.5);
}

.panel-container {
  display: flex;
  justify-content: flex-end;
  color: #1d1d1b;
}

.text-danger {
  color: #d9534f;
}

.text-info {
  color: #5bc0de;
}

.text-warning {
  color: #e29c3b;
}

.text-success {
  color: #52b152;
}

.accent-text-color {
  color: #aa7a56;
}

.gradient-dark-normal {
  background: linear-gradient(#f3f3f3, #ffffff);
}

.header-gradient {
  background: linear-gradient(#f3f3f3, #ffffff);
}

/* For use in src/lib/core/theming/_palette.scss */
/* Add custom styles for the active and inactive buttons */
mat-button-toggle-group {
  border-color: #ffffff !important;
}
mat-button-toggle-group mat-button-toggle {
  border-color: #ffffff !important;
  background-color: transparent !important;
  color: white !important; /* You can customize the text color for inactive buttons */
}
mat-button-toggle-group mat-button-toggle.mat-button-toggle-checked {
  background-color: #ffffff !important;
  color: white !important; /* You can customize the text color for the active button */
}